import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
  advancedFilfer = /explore-carriers|my-carriers/;
  constructor(
    private authenticationService: AuthService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.authenticationService.getToken();
    const isLoggedIn = token != null;
    const isApiUrl = request.url.startsWith('https://utilities');
    const isApiUrlLocal = request.url.startsWith('http://localhost');

    if (isLoggedIn && (isApiUrl || isApiUrlLocal)) {
      request = request.clone({
        setHeaders: {
          Authorization: token,
        },
      });
      return next.handle(request);
    }

    return next.handle(request).pipe(
      tap({
        next: () => {},
        error: (err) => {
         if(err.status == 500){
            return
          }
        },
      })
    );
  }

}
