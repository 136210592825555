import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, first, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OfferAttachmentService {
  constructor(private readonly http: HttpClient) {}

  deleteAttachment(obj: any) {

    return this.http
      .delete<any>(`${environment.API_BACK}/anexo/delete-oferta`, {body: obj})
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  AddAttachment(obj: any) {
    console.log(obj);
    return this.http.put<any>(`${environment.API_BACK}/anexo/add-anexo-oferta`, obj).pipe(
      map((dados) => {
        return dados;
      }),
      catchError((error) => {
        throw new Error(error);
      })
    );
  }

  saveAttachment(file: any) {
    const form: FormData = new FormData();
    form.append('file', file);
    const headers = new HttpHeaders();
    headers.append('responseType', 'text');
    headers.append('Content-Type', 'multipart/form-data');

    return this.http
      .post<any>(`${environment.API_BACK}/anexo/get-link`, form, {
        headers,
        responseType: 'text' as 'json',
      })
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }
}
