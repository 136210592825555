import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TrackingService } from '../../../core/services/shared/tracking.service';
import { first } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

interface OfferSpot {
  nomeEmbarcador: string;
  cnpjEmbarcador: string;
  tipoOferta: string;
  valorOferta: number;
  certificacoes: string[];
  rastreamentoVeiculo: any;
  numeroOferta: any;
  ordemColeta: any;
  origem: {
    cep: string;
    cidade: string;
    uf: string;
    logradouro: string;
    numero: string;
    bairro: string;
    complemento: string;
  };
  destino: {
    cep: string;
    cidade: string;
    uf: string;
    logradouro: string;
    numero: string;
    bairro: string;
    complemento: string;
  };
  dataPrevisaoColeta: string;
  dataPrevistaEntrega: string;
  tipoCarga: string;
  valorTotalCarga: number;
  valorTotalPesoBruto: string;
  volume: string;
  modalidade: string[];
  tipoEmbalagem: string;
  altura: string;
  largura: string;
  comprimento: string;
  cubagem: string;
  numeroPedido: string;
  numeroNota: string;
  nomeTransportadora: string;
  cnpjTransportadora: string;
  telefoneTransportadora: string;
  emailTransportadora: string;
  nomeDestinatario: string;
  documentoDestinatario: string;
  veiculo: any
}

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss'],
})
export class TrackingComponent implements OnInit {
  prefix: string;
  isContract = false;
  loaded = false;

  labels = {
    lbNumerOfertaContrato: 'N° da oferta contrato:',
    lbNumeroPedido: 'N° do pedido:',
    lbNumeroOrdemColeta: 'N° da ordem de coleta:',
    lbOrigem: 'Origem:',
    lbDestino: 'Destino:',
    lbDataEmissaoColeta: 'Data de emissão da coleta:',
    lbDataPrevistaColeta: 'Data prevista para coleta:',
    lbDataPrevistaEntrega: 'Data prevista para entrega:',
    lbTotalCarga: 'Valor total da carga:',
    lbTotalPesoBruto: 'Valor total do peso bruto:',
    lbTotalCubagem: 'Valor total da cubagem:',
    lbNomeMotorista: 'Nome do motorista:',
    lbPlacaVeiculo: 'Placa do veículo:',
    lbDocumento: 'CPF/CNPJ',
    lbRazaoSocial: 'Razão Social',
    lbCep: 'CEP',
    lbEndereco: 'Endereço',
    lbBairro: 'Bairro',
    lbCidade: 'Cidade',
    lbEstado: 'Estado',
    lbEmbarcador: 'Embarcador',
    lbTipoOferta: 'Tipo de oferta',
    lbValorOferta: 'Valor da oferta de frete',
    lbCertificacoes: 'Certificações',
    lbRastreamentoVeiculo: 'Rastreamento do veículo',
    lbNumeroOfertaSpot: 'Número da oferta SPOT',
    lbModalidadeFrete: 'Modalidade de frete',
    lbVolume: 'Quantidade de volumes',
    lbTipoCarga: 'Tipo de carga',
    lbTipoEmbalagem: 'Tipo de embalagem',
    lbAltura: 'Altura',
    lbLargura: 'Largura',
    lbComprimento: 'Comprimento',
    lbCubagem: 'Cubagem (m³)',
    lbNumeroNota: 'Número da nota',
    lbTelefone: 'Telefone',
    lbEmail: 'E-mail',
    lbVolumes: 'Volumes',
    lbVeiculo: 'Veículo',
  }

  constructor(
    private trackingService: TrackingService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
  ) { }

  detailsOfOffer: any;
  detailsOfOfferSpot: OfferSpot;
  endereco: any;

  ngOnInit(): void {
    this.prefix = this.route.snapshot.paramMap.get('prefix');
    if (this.prefix == 'oferta-contrato') {
      this.isContract = true;
      this.getTracking();
    } else {
      this.isContract = false;
      this.getTrackingSpot()
    }
    this.breakpointObserver.observe([
      Breakpoints.Small,
      Breakpoints.HandsetPortrait,
      Breakpoints.Handset,
    ]).subscribe(() => {
      this.cdr.detectChanges(); // Força a detecção de mudanças
    });
  }

  getTracking() {
    this.loaded = false;
    const parameter = {
      empresaId: this.route.snapshot.paramMap.get('clientId'),
      ofertaId: this.route.snapshot.paramMap.get('ofertaId'),
      hash: this.route.snapshot.paramMap.get('hash'),
    };

    this.trackingService
      .getTrackingOrdemDeColeta(parameter)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          if (data) {
            this.detailsOfOffer = {
              ...data,
              dataEmissaoColeta: this.formatDate(data.dataEmissaoColeta),
              dataPrevistaColeta: this.formatDate(data.dataPrevistaColeta),
              dataPrevistaEntrega: this.formatDate(data.dataPrevistaEntrega),
            };
            this.endereco =
              this.detailsOfOffer.destinoLogradouro +
              ', ' +
              this.detailsOfOffer.destinoNumero;
          }
          this.loaded = true;
        },
        error: (error: any) => {
          console.error(error);
          this.loaded = true;
        }
      })
  }

  getTrackingSpot() {
    this.loaded = false;
    const parameter = {
      empresaId: this.route.snapshot.paramMap.get('clientId'),
      ofertaId: this.route.snapshot.paramMap.get('ofertaId'),
      hash: this.route.snapshot.paramMap.get('hash'),
    };

    this.trackingService
      .getTrackingOrdemDeColetaSpot(parameter)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          if (data) {
            this.detailsOfOfferSpot = {
              ...data,
            }
          }
          this.loaded = true;
        },
        error: (error: any) => {
          console.error(error);
          this.loaded = true;
        }
      })
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    if (!dateString || isNaN(date.getTime())) {
      return ''; // Retorna uma string vazia se a data não for válida
    }
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  print() {
    window.scroll(0, 0);
    setTimeout(() => {
      window.print();
    }, 500);
    clearTimeout(300)
  }
}
