import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DialogConfirmComponent } from 'src/app/shared/components/dialog-confirm/dialog-confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarDefaultService } from 'src/app/core/services/utils/snackbar-default.service';
import { PermissaoService } from 'src/app/core/services/auth/permissao.service';
import { UserService } from 'src/app/core/services/shared/user.service';
import { first } from 'rxjs';
export interface PeriodicElement {
  id: number;
  name: string;
  email: string;
  status: string;
  actions: string;
}

export interface HeaderTable {
  lbName: string;
  lbEmail: string;
  lbStatus: string;
  lbActions: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    id: 13,
    name: 'Empresa X',
    email: 'seniorx@senior.com',
    status: 'Ativo',
    actions: '',
  },
  {
    id: 2,
    name: 'Empresa X',
    email: 'seniorx@senior.com',
    status: 'Ativo',
    actions: '',
  },
  {
    id: 3,
    name: 'Empresa X',
    email: 'seniorx@senior.com',
    status: 'Inativo',
    actions: '',
  },
];

@Component({
  selector: 'app-my-users',
  templateUrl: './my-users.component.html',
  styleUrls: ['./my-users.component.scss'],
})
export class MyUsersComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['name', 'email', 'status', 'actions'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);

  options: any = ['Editar', 'Alterar status'];

  page = 0;
  size = 50;
  totalElements = 0;
  loadingContent = false;

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  headerTable: HeaderTable = {
    lbName: 'Nome',
    lbEmail: 'E-mail',
    lbStatus: 'Status',
    lbActions: '	Ações',
  };

  statusUser: string;
  routePath = '';
  newUser = '';
  editUser = '';
  loadingSpinner = false;
  isAdmin = false;
  userId = Number(JSON.parse(localStorage.getItem('usuario'))?.id);

  constructor(
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly permissaoService: PermissaoService,
    private readonly dialog: MatDialog,
    private readonly _snackBar: SnackbarDefaultService
  ) {}

  ngOnInit(): void {
    this.routePath = window.location.pathname;
    this.newUser = `${this.routePath}/new-user`;
    this.editUser = `${this.routePath}/edit-user`;
    this.isAdmin = this.permissaoService.isAdmin();
    this.getUsers();
  }

  public redirect(action: string, row: any) {
    if (action === 'Editar') {
      this.router.navigate([this.editUser, row.id]).catch((err) => err);
    }
    if (action === 'Alterar status') {
      this.changeStatus(row);
    }
  }

  public getUsers() {
    const paginatorObj = {
      page: this.page,
      size: this.size,
    };
    this.loadingContent = true;

    this.userService
      .getUser(paginatorObj)
      .pipe(first())
      .subscribe({
        next: (result: any) => {
          const usuarios: PeriodicElement[] = [];
          result.content.forEach(
            (element: { id: any; nome: any; email: any; statusUsuario: any }) => {
              const usuario: PeriodicElement = {
                id: element.id,
                name: element.nome.toUpperCase(),
                email: element.email,
                status: this.statusUserAdjustment(element.statusUsuario),
                actions: '',
              };

              usuarios.push(usuario);
            }
          );
          this.totalElements = result.totalElements;
          this.loadingContent = false;
          this.dataSource = new MatTableDataSource(usuarios);
        },
        error: (error) => {
          console.error(error);
          this.loadingContent = false;
        },
      })
  }

  public handlePageEvent(e: PageEvent) {
    if (this.size != e.pageSize) {
      this.page = 0;
    } else {
      this.page = e.pageIndex;
    }
    this.size = e.pageSize;
    this.getUsers();
  }

  public statusUserAdjustment(status: string) {
    if (status === 'ATIVO') {
      return 'Ativo';
    } else {
      return 'Inativo';
    }
  }

  public changeStatus(row: any) {
    const text = row.status == 'Ativo' ? 'INATIVAR' : 'ATIVAR';

    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: 'Confirmação',
        description: `Tem certeza que deseja ${text} esse usuário?`,
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.loadingSpinner = true;
        this.userService
          .changeUserStatus(row.id)
          .pipe(first())
          .subscribe({
            next: (data: any) => {
              if (data) {
                this.loadingSpinner = false;
                this._snackBar.openSnackBarSuccess(
                  `Usuário ${text.toLowerCase()} com sucesso`
                );
                setTimeout(() => {
                  this.getUsers();
                }, 500);
              }
            },
            error: (error) => {
              console.error(error);
              this.loadingSpinner = false;
              this._snackBar.openSnackBarError(
                `Erro ao ${text.toLowerCase()} o usuário`
              );
            }
          })
      }
    });
  }
}
