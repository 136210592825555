<div fxLayout="row" class="container-1 main-bg" fxLayoutAlign="start">
  <div fxLayout="column" class="column-title">
    <div>
      <hr class="icon-main" />
      <h1 class="title">Plataforma de Fretes</h1>
      <p class="description">
        Conte com uma solução de conexões estratégicas que potencializa
        embarcadores e transportadores para uma logística imbatível e negócios
        lucrativos!
      </p>
      <a mat-button color="primary" class="mt-4 register" href="#interest">
        Quero conhecer
      </a>
    </div>
    <div class="subtitle-container">
      <h1 class="subtitle">
        Encontre o melhor <b>caminho</b> para sua <b>solução de logística</b>
      </h1>
    </div>
    <div class="company-container">
      <div id="cards-company" class="mt-4">
        <div class="shipper mx-2">
          <div class="card-types">
            <img
              src="assets/landing/flip_embarcador.png"
              class="company-img"
              alt=""
            />
            <div class="content-shipper">
              <h5 class="solutions-title">Sou <span>embarcador</span></h5>
              <p class="mt-4 solutions-description">
                Quero aumentar minha eficiência logística, reduzindo meus custos
                ao acessar uma plataforma completa que simplifica o
                gerenciamento de fretes
              </p>
              <a
                mat-button
                color="primary"
                class="mt-4 btn-shipper"
                routerLink="/im-a-shipper"
              >
                Quero saber mais
              </a>
            </div>
          </div>
        </div>
        <div class="carrier mx-2">
          <div class="card-types">
            <img
              src="assets/landing/flip_transportador.png"
              style="width: 10rem; height: 10rem"
              alt=""
            />
            <div>
              <h5 class="solutions-title">Sou <span>transportador</span></h5>
              <p class="mt-4 solutions-description">
                Quero aumentar minha rede de contatos e otimizar minha operação
                com uma plataforma completa que oferece valores competitivos e
                insights do mercado
              </p>
              <a
                mat-button
                color="primary"
                class="mt-4 btn-carrier"
                routerLink="/im-a-carrier"
              >
                Quero saber mais
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-2">
  <div id="title-benefits" class="global-title mb-4">
    <div>Conheça algumas <strong> vantagens</strong></div>
  </div>

  <div id="card-benefits" *ngIf="!mobile; else benefitsMobile">
    <mat-card class="card p-2 mx-2">
      <mat-card-content>
        <div class="step text-white text-center mx-1 hexagon">
          <img src="assets/img/svg/ecossistema_emb.svg" alt="" />
        </div>
        <div class="content">
          <p class="title"><strong>Conectividade</strong> na sua operação</p>
          <p class="description">
            Nossa <strong>plataforma de fretes</strong> oferece uma solução
            simples para agilizar os seus processos na contração de um frete,
            proporcionando visibilidade em tempo real, otimizando custos e
            garantindo uma operação eficiente e confiável.
          </p>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="card p-2 mx-2 top-benefits">
      <mat-card-content>
        <div class="step text-white text-center mx-1 hexagon">
          <img src="assets/img/svg/compliance_emb.svg" alt="" />
        </div>
        <div class="content">
          <p class="title"><strong>Comunicação</strong> facilitada</p>
          <p class="description">
            Nosso chat integrado simplifica a comunicação entre
            <strong>embarcadores</strong> e <strong>transportadores</strong>,
            permitindo negociações ágeis e respostas rápidas. Isso agiliza o
            contato com os motoristas e operadores logísticos, promovendo uma
            interação eficiente e um fluxo de trabalho mais fluido.
          </p>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="card p-2 mx-2 top-benefits">
      <mat-card-content>
        <div class="step text-white text-center mx-1 hexagon">
          <img src="assets/img/svg/custos_emb.svg" alt="" />
        </div>
        <div class="content">
          <p class="title">
            Aumento da <strong>eficiência operacional</strong>
          </p>
          <p class="description">
            Esqueça aquelas incansáveis trocas de e-mails, diversas ligações,
            preços acordados em planilhas e etc. Realize todo esse processo de
            forma simples e ágil através da nossa plataforma.
          </p>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div class="container-grey">
  <div id="title-comments" class="global-title mb-4">
    <div class="mb-4 align-center">
      Depoimentos dos nossos
      <strong>clientes</strong>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="center" class="global-subtitle mb-4">
    <div class="mb-4 align-center">
      Veja alguns comentários do nosso evento <strong>Logthink</strong> que
      apresentou a <strong>Plataforma de fretes</strong>
    </div>
  </div>
  <div class="comments-slide">
    <div class="slideshow-container">
      <div class="mySlidesComments fade">
        <div class="comments-container">
          <div class="mb-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M4.58341 17.3211C3.55316 16.2275 3 15 3 13.0104C3 9.51092 5.45651 6.37372 9.03059 4.82324L9.92328 6.20085C6.58804 8.00545 5.93618 10.3461 5.67564 11.8221C6.21263 11.5444 6.91558 11.4467 7.60471 11.5106C9.40908 11.6778 10.8312 13.1591 10.8312 15C10.8312 16.933 9.26416 18.5 7.33116 18.5C6.2581 18.5 5.23196 18.0096 4.58341 17.3211ZM14.5834 17.3211C13.5532 16.2275 13 15 13 13.0104C13 9.51092 15.4565 6.37372 19.0306 4.82324L19.9233 6.20085C16.588 8.00545 15.9362 10.3461 15.6756 11.8221C16.2126 11.5444 16.9156 11.4467 17.6047 11.5106C19.4091 11.6778 20.8312 13.1591 20.8312 15C20.8312 16.933 19.2642 18.5 17.3312 18.5C16.2581 18.5 15.232 18.0096 14.5834 17.3211Z"
                fill="#00c89b"
              />
            </svg>
          </div>
          <p class="comments text-justify">
            A plataforma vai nos proporcionar uma experiência incrível para o
            negócio. Com acesso a uma ampla variedade de ofertas, sugestões de
            valores competitivos e um gerenciamento simplificado de
            embarcadores, vejo a oportunidade de aumentar nossa receita e
            economizar tempo.
          </p>
          <div class="user-comments d-flex flex-row-reverse mt-4 link-comments">
            <span>Logthink</span>
          </div>
        </div>
      </div>
      <div class="mySlidesComments fade">
        <div class="comments-container">
          <div class="mb-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M4.58341 17.3211C3.55316 16.2275 3 15 3 13.0104C3 9.51092 5.45651 6.37372 9.03059 4.82324L9.92328 6.20085C6.58804 8.00545 5.93618 10.3461 5.67564 11.8221C6.21263 11.5444 6.91558 11.4467 7.60471 11.5106C9.40908 11.6778 10.8312 13.1591 10.8312 15C10.8312 16.933 9.26416 18.5 7.33116 18.5C6.2581 18.5 5.23196 18.0096 4.58341 17.3211ZM14.5834 17.3211C13.5532 16.2275 13 15 13 13.0104C13 9.51092 15.4565 6.37372 19.0306 4.82324L19.9233 6.20085C16.588 8.00545 15.9362 10.3461 15.6756 11.8221C16.2126 11.5444 16.9156 11.4467 17.6047 11.5106C19.4091 11.6778 20.8312 13.1591 20.8312 15C20.8312 16.933 19.2642 18.5 17.3312 18.5C16.2581 18.5 15.232 18.0096 14.5834 17.3211Z"
                fill="#00c89b"
              />
            </svg>
          </div>
          <p class="comments text-justify">
            A estrutura de vocês é incrível, fiquei muito satisfeito em conhecer
            a empresa e saber que estamos sendo bem cuidados. A iniciativa da
            Senior em proporcionar proximidade e integração entre os usuários é
            fantástica, me trouxe ainda conhecimento em relação aos negócios,
            tendências do mercado e tecnologia.
          </p>
          <div class="user-comments d-flex flex-row-reverse mt-4 link-comments">
            <span>Logthink</span>
          </div>
        </div>
      </div>

      <div class="mySlidesComments fade">
        <div class="comments-container">
          <div class="mb-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M4.58341 17.3211C3.55316 16.2275 3 15 3 13.0104C3 9.51092 5.45651 6.37372 9.03059 4.82324L9.92328 6.20085C6.58804 8.00545 5.93618 10.3461 5.67564 11.8221C6.21263 11.5444 6.91558 11.4467 7.60471 11.5106C9.40908 11.6778 10.8312 13.1591 10.8312 15C10.8312 16.933 9.26416 18.5 7.33116 18.5C6.2581 18.5 5.23196 18.0096 4.58341 17.3211ZM14.5834 17.3211C13.5532 16.2275 13 15 13 13.0104C13 9.51092 15.4565 6.37372 19.0306 4.82324L19.9233 6.20085C16.588 8.00545 15.9362 10.3461 15.6756 11.8221C16.2126 11.5444 16.9156 11.4467 17.6047 11.5106C19.4091 11.6778 20.8312 13.1591 20.8312 15C20.8312 16.933 19.2642 18.5 17.3312 18.5C16.2581 18.5 15.232 18.0096 14.5834 17.3211Z"
                fill="#00c89b"
              />
            </svg>
          </div>
          <p class="comments text-justify">
            Parabéns pelos resultados do Logthink, esperamos estar em novas
            oportunidades. Ficamos a disposição na continuidade dos trabalhos da
            solução para contratação de transportadoras.
          </p>
          <div class="user-comments d-flex flex-row-reverse mt-4 link-comments">
            <span>Logthink</span>
          </div>
        </div>
      </div>
      <button 
        class="prev" 
        (click)="plusSlides(-1, 'mySlidesComments')" 
        aria-label="Previous slide"
      >
        &#10094;
      </button>
      <button 
        class="next" 
        (click)="plusSlides(1, 'mySlidesComments')" 
        aria-label="Next slide"
      >
        &#10095;
      </button>
    </div>
    <br />

    <div style="text-align: center">
      <button
        class="dot dot-comments"
        (click)="currentSlide(1, 'mySlidesComments')"
        tabindex="0"
        aria-label="Go to slide 1"
      ></button>
      <button
        class="dot dot-comments"
        (click)="currentSlide(2, 'mySlidesComments')"
        tabindex="0"
        aria-label="Go to slide 2"
      ></button>
      <button
        class="dot dot-comments"
        (click)="currentSlide(3, 'mySlidesComments')"
        tabindex="0"
        aria-label="Go to slide 3"
      ></button>
    </div>
  </div>
</div>

<div class="container-companies pt-0">
  <div class="global-title mb-4" id="title-companies">
    <div class="mb-4 align-center">
      <strong>Clientes</strong>
      que já utilizam as soluções Senior
      <div class="align-hr">
        <hr class="icon-customer " />
      </div>
    </div>
  </div>
  <div class="companies-slide">
    <div class="slideshow-container">
      <div class="mySlidesCompanies fade">
        <div class="container-imgcompanies">
          <img src="assets/landing/intelbras.png" class="customer-img" alt="" />
        </div>
      </div>
      <div class="mySlidesCompanies fade">
        <div class="container-imgcompanies">
          <img src="assets/landing/adidas.png" class="customer-img" alt="" />
        </div>
      </div>
      <div class="mySlidesCompanies fade">
        <div class="container-imgcompanies">
          <img src="assets/landing/goodyear.png" class="customer-img" alt="" />
        </div>
      </div>

      <div class="mySlidesCompanies fade">
        <div class="container-imgcompanies">
          <img src="assets/landing/biotrop.png" class="customer-img" alt="" />
        </div>
      </div>
      <div class="mySlidesCompanies fade">
        <div class="container-imgcompanies">
          <img src="assets/landing/jej.png" class="customer-img" alt="" />
        </div>
      </div>
      <div class="mySlidesCompanies fade">
        <div class="container-imgcompanies">
          <img
            src="assets/landing/tres_coracoes.png"
            class="customer-img"
            alt=""
          />
        </div>
      </div>
      <div class="mySlidesCompanies fade">
        <div class="container-imgcompanies">
          <img src="assets/landing/quantiq.png" class="customer-img" alt="" />
        </div>
      </div>
      <div class="mySlidesCompanies fade">
        <div class="container-imgcompanies">
          <img src="assets/landing/renner.png" class="customer-img" alt="" />
        </div>
      </div>
      <div class="mySlidesCompanies fade">
        <div class="container-imgcompanies">
          <img src="assets/landing/roche.png" class="customer-img" alt="" />
        </div>
      </div>
      <div class="mySlidesCompanies fade">
        <div class="container-imgcompanies">
          <img src="assets/landing/syngenta.png" class="customer-img" alt="" />
        </div>
      </div>
      <div class="mySlidesCompanies fade">
        <div class="container-imgcompanies">
          <img src="assets/landing/volks.png" class="customer-img" alt="" />
        </div>
      </div>

      <button 
        class="prev" 
        (click)="plusSlides(-1, 'mySlidesCompanies')" 
        (keydown.enter)="plusSlides(-1, 'mySlidesCompanies')" 
        tabindex="0" 
        aria-label="Previous slide"
      >
        &#10094;
      </button>
      <button
        class="next" 
        (click)="plusSlides(1, 'mySlidesCompanies')" 
        (keydown.enter)="plusSlides(1, 'mySlidesCompanies')" 
        tabindex="0" 
        aria-label="Next slide"
      >
        &#10095;
      </button>
    </div>
    <br />

    <div style="text-align: center">
      <button
        class="dot dot-companies"
        (click)="currentSlide(1, 'mySlidesCompanies')"
        (keydown.enter)="currentSlide(1, 'mySlidesCompanies')"
        tabindex="0"
        aria-label="Go to slide 1"
      ></button>
      <button
        class="dot dot-companies"
        (click)="currentSlide(2, 'mySlidesCompanies')"
        (keydown.enter)="currentSlide(2, 'mySlidesCompanies')"
        tabindex="0"
        aria-label="Go to slide 2"
      ></button>
      <button
        class="dot dot-companies"
        (click)="currentSlide(3, 'mySlidesCompanies')"
        (keydown.enter)="currentSlide(3, 'mySlidesCompanies')"
        tabindex="0"
        aria-label="Go to slide 3"
      ></button>
      <button
        class="dot dot-companies"
        (click)="currentSlide(4, 'mySlidesCompanies')"
        (keydown.enter)="currentSlide(4, 'mySlidesCompanies')"
        tabindex="0"
        aria-label="Go to slide 4"
      ></button>
      <button
        class="dot dot-companies"
        (click)="currentSlide(5, 'mySlidesCompanies')"
        (keydown.enter)="currentSlide(5, 'mySlidesCompanies')"
        tabindex="0"
        aria-label="Go to slide 5"
      ></button>
      <button
        class="dot dot-companies"
        (click)="currentSlide(6, 'mySlidesCompanies')"
        (keydown.enter)="currentSlide(6, 'mySlidesCompanies')"
        tabindex="0"
        aria-label="Go to slide 6"
      ></button>
      <button
        class="dot dot-companies"
        (click)="currentSlide(7, 'mySlidesCompanies')"
        (keydown.enter)="currentSlide(7, 'mySlidesCompanies')"
        tabindex="0"
        aria-label="Go to slide 7"
      ></button>
      <button
        class="dot dot-companies"
        (click)="currentSlide(8, 'mySlidesCompanies')"
        (keydown.enter)="currentSlide(8, 'mySlidesCompanies')"
        tabindex="0"
        aria-label="Go to slide 8"
      ></button>
      <button
        class="dot dot-companies"
        (click)="currentSlide(9, 'mySlidesCompanies')"
        (keydown.enter)="currentSlide(9, 'mySlidesCompanies')"
        tabindex="0"
        aria-label="Go to slide 9"
      ></button>
      <button
        class="dot dot-companies"
        (click)="currentSlide(10, 'mySlidesCompanies')"
        (keydown.enter)="currentSlide(10, 'mySlidesCompanies')"
        tabindex="0"
        aria-label="Go to slide 10"
      ></button>
      <button
        class="dot dot-companies"
        (click)="currentSlide(11, 'mySlidesCompanies')"
        (keydown.enter)="currentSlide(11, 'mySlidesCompanies')"
        tabindex="0"
        aria-label="Go to slide 11"
      ></button>
    </div>
  </div>
</div>

<div fxLayout="row" class="container-grey" fxLayoutAlign="center" id="interest">
  <div fxLayout="column" fxFlex="100">
    <div fxLayout="row" class="global-title mb-4">
      <div class="mb-4 text-center">
        Entre em contato com nossos consultores e<br />
        <strong>conheça a solução</strong>
      </div>
    </div>

    <div class="container-interest">
      <!-- Start of HubSpot Embed Code -->
      <script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="https://js.hs-scripts.com/20786608.js"
      ></script>
      <!-- End of HubSpot Embed Code -->
      <iframe
        id="frm_home"
        src="https://share.hsforms.com/1BjpZoUgTTWqbYnr8hCo4CQcdj1s"
        class="frame"
        title="Form home"
      >
      </iframe>
    </div>
  </div>
</div>

<div fxLayout="row" class="container-senior" fxLayoutAlign="center">
  <mat-card id="about" class="senior-about-card">
    <mat-card-content>
      <div *ngIf="!mobile; else about">
        <div fxLayout="row" fxlayoutAlign="space-between">
          <div fxLayout="column" fxFlex>
            <p class="about-1">Sobre nós</p>
            <p class="about-2">Conheça a <strong>Senior</strong></p>
            <p class="about-3">
              Presente em todo território brasileiro e na América Latina, é
              referência em tecnologia para gestão, a companhia tem um dos mais
              completos portfólios para alta performance, oferecendo soluções em
              Gestão Empresarial, Logística, Gestão de Pessoas, Relacionamento
              com Clientes, Supermercados e Gestão de Acesso e Segurança.
            </p>
          </div>
          <div fxLayout="column" fxFlex>
            <div fxLayout="row" fxLayoutAlign="end">
              <img
                src="assets/landing/logo-senior.svg"
                alt=""
                width="200px"
                class="about-image"
              />
            </div>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<div class="container-green">
  <div fxLayout="row" class="global-title mb-4">
    <div class="mb-4 text-white align-center">
      Conheça nosso portfólio de
      <strong>produtos</strong>
    </div>
  </div>

  <div id="products">
    <div class="products-card">
      <mat-card>
        <mat-card-content>
          <img
            src="assets/img/png/image_26.png"
            alt=""
            width="100%"
            class="products-image"
          />
          <div class="products-container">
            <p class="products-title">WMS</p>
            <p class="products-description">
              Conte com uma solução eficiente e integrada com a inteligência
              artificial para melhorar a produtividade do seu negócio, desde o
              processo de armazenagem até a entrega final.
            </p>
            <a
              mat-flat-button
              color="primary"
              class="products-btn"
              target="_blank"
              href="https://www.senior.com.br/solucoes/sistema-wms-gestao-de-armazenagem"
              >Conheça</a
            >
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="products-card margin-cardproducts">
      <mat-card>
        <mat-card-content>
          <img
            src="assets/img/png/image_24.png"
            alt=""
            width="100%"
            class="products-image"
          />
          <div class="products-container">
            <p class="products-title">Confirma Fácil</p>
            <p class="products-description">
              Acompanhe a gestão de entregas e ocorrências em tempo real com a
              possibilidade de apontamentos offline, além de pesquisa de NPS e
              outros dashboards com informações.
            </p>
            <a
              mat-flat-button
              color="primary"
              class="products-btn"
              target="_blank"
              href="https://conteudo.gkofrete.com.br/confirmafacil"
              >Conheça</a
            >
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="products-card margin-cardproducts">
      <mat-card>
        <mat-card-content>
          <img
            src="assets/img/png/image_25.png"
            alt=""
            width="100%"
            class="products-image"
          />
          <div class="products-container">
            <p class="products-title">GKO Frete</p>
            <p class="products-description">
              O GKO FRETE é o sistema TMS líder na gestão de fretes contratados
              por embarcadores junto a transportadores terceirizados.
            </p>
            <a
              mat-flat-button
              color="primary"
              class="products-btn"
              target="_blank"
              href="https://conteudo.gkofrete.com.br/gkofrete"
              >Conheça</a
            >
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <ng-template #benefitsMobile>
    <div class="benefits-slide">
    <div class="slideshow-container">
      <div class="mySlidesBenefits fade">
        <mat-card class="card p-2 mx-2 top-benefits">
          <mat-card-content>
            <div class="step text-white text-center mx-1 hexagon">
              <img src="assets/img/svg/ecossistema_emb.svg" alt="" />
            </div>
            <div class="content">
              <p class="title">
                <strong>Conectividade</strong> na sua operação
              </p>
              <p class="description">
                Nossa <strong>plataforma de fretes</strong> oferece uma solução
                simples para agilizar os seus processos na contração de um
                frete, proporcionando visibilidade em tempo real, otimizando
                custos e garantindo uma operação eficiente e confiável.
              </p>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="mySlidesBenefits fade">
        <mat-card class="card p-2 mx-2 top-benefits">
          <mat-card-content>
            <div class="step text-white text-center mx-1 hexagon">
              <img src="assets/img/svg/compliance_emb.svg" alt="" />
            </div>
            <div class="content">
              <p class="title"><strong>Comunicação</strong> facilitada</p>
              <p class="description">
                Nosso chat integrado simplifica a comunicação entre
                <strong>embarcadores</strong> e
                <strong>transportadores</strong>, permitindo negociações ágeis e
                respostas rápidas. Isso agiliza o contato com os motoristas e
                operadores logísticos, promovendo uma interação eficiente e um
                fluxo de trabalho mais fluido.
              </p>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="mySlidesBenefits fade">
        <mat-card class="card p-2 mx-2 top-benefits">
          <mat-card-content>
            <div class="step text-white text-center mx-1 hexagon">
              <img src="assets/img/svg/custos_emb.svg" alt="" />
            </div>
            <div class="content">
              <p class="title">
                Aumento da <strong>eficiência operacional</strong>
              </p>
              <p class="description">
                Esqueça aquelas incansáveis trocas de e-mails, diversas
                ligações, preços acordados em planilhas e etc. Realize todo esse
                processo de forma simples e ágil através da nossa plataforma.
              </p>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <button
        class="prev" 
        (click)="plusSlides(-1, 'mySlidesBenefits')" 
        (keydown.enter)="plusSlides(-1, 'mySlidesBenefits')" 
        tabindex="0" 
        aria-label="Previous slide"
      >
        &#10094;
      </button>
      <button 
        class="next" 
        (click)="plusSlides(1, 'mySlidesBenefits')" 
        (keydown.enter)="plusSlides(1, 'mySlidesBenefits')" 
        tabindex="0" 
        aria-label="Next slide"
      >
        &#10095;
      </button>
    </div>
    <br />

    <div style="text-align: center">
      <button
        class="dot dot-benefits"
        (click)="currentSlide(1, 'mySlidesBenefits')"
        (keydown.enter)="currentSlide(1, 'mySlidesBenefits')"
        tabindex="0"
        aria-label="Go to slide 1"
      ></button>
      <button
        class="dot dot-benefits"
        (click)="currentSlide(2, 'mySlidesBenefits')"
        (keydown.enter)="currentSlide(2, 'mySlidesBenefits')"
        tabindex="0"
        aria-label="Go to slide 2"
      ></button>
      <button
        class="dot dot-benefits"
        (click)="currentSlide(3, 'mySlidesBenefits')"
        (keydown.enter)="currentSlide(3, 'mySlidesBenefits')"
        tabindex="0"
        aria-label="Go to slide 3"
      ></button>
    </div>
  </div>
  </ng-template>

  <ng-template #about>
    <div fxLayout="row" fxLayoutAlign="start">
      <p class="about-1">Sobre nós</p>
    </div>
    <div fxLayout="row" fxLayoutAlign="start">
      <p class="about-2">Conheça a <strong>Senior</strong></p>
    </div>
    <div fxLayout="row" fxLayoutAlign="start">
      <p class="about-3">
        Presente em todo território brasileiro e na América Latina, é referência
        em tecnologia para gestão, a companhia tem um dos mais completos
        portfólios para alta performance, oferecendo soluções em Gestão
        Empresarial, Logística, Gestão de Pessoas, Relacionamento com Clientes,
        Supermercados e Gestão de Acesso e Segurança.
      </p>
    </div>
  </ng-template>
</div>
