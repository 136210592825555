import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SnackbarDefaultService } from 'src/app/core/services/utils/snackbar-default.service';
import { Router } from '@angular/router';
import { DialogConfirmComponent } from 'src/app/shared/components/dialog-confirm/dialog-confirm.component';
import { DialogAttachmentComponent } from 'src/app/shared/components/dialog-attachment/dialog-attachment.component';
import { environment } from 'src/environments/environment';
import { first } from 'rxjs';
import { RecipientService } from 'src/app/core/services/shared/recipient.service';

interface Recipient {
  name: string;
  id: number;
  document: string;
  address: Address;
}

interface Address {
  zipcode: string;
  province: string;
  city: string;
  street: string;
  number: string;
  neighborhood: string;
  complement: string;
}

@Component({
  selector: 'app-my-recipient',
  templateUrl: './my-recipient.component.html',
  styleUrls: ['./my-recipient.component.scss'],
})
export class MyRecipientComponent implements OnInit, AfterViewInit {
  options: any = ['Detalhar', 'Editar', 'Excluir'];

  displayedColumns: string[] = ['name', 'document', 'uf', 'actions'];

  loaded = true;

  headerTable = {
    lbName: 'Nome',
    lbDocument: 'Documento',
    lbUf: 'UF',
    lbActions: 'Ações',
  };

  page = 0;
  size = 50;
  totalElements = 0;
  loadingContent = false;

  routePath = '';
  newRecipient = '';
  editRecipient = '';
  detailsRecipient = '';

  optionsRecipient: any[] = ['Baixar modelo CSV', 'Importar CSV'];

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  public searchForm: FormGroup = this.formBuilder.group({
    search: [null],
  });

  listRecipients: Recipient[] = [];

  dataSource = new MatTableDataSource(this.listRecipients);

  constructor(
    private readonly _snackBar: SnackbarDefaultService,
    private readonly dialog: MatDialog,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly recipientService: RecipientService,
  ) {}

  ngOnInit(): void {
    this.settingsRoutes();
    this.getRecipients();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  redirect(action: string, row: any) {
    if (action === 'Detalhar') {
      this.router.navigate([this.detailsRecipient, row.id]).catch((err) => err);
    } else if (action === 'Editar') {
      this.router.navigate([this.editRecipient, row.id]).catch((err) => err);
    } else {
      const dialogRef = this.dialog.open(DialogConfirmComponent, {
        data: {
          title: 'Confirmação',
          description: 'Tem certeza que deseja excluir este destinatário?',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.deteleteRecipient(row.id);
        }
      });
    }
  }

  public handlePageEvent(e: PageEvent) {
    if (this.size != e.pageSize) {
      this.page = 0;
    } else {
      this.page = e.pageIndex;
    }
    this.size = e.pageSize;
    this.getRecipients();
  }

  settingsRoutes() {
    this.routePath = window.location.pathname;
    this.newRecipient = `${this.routePath}/new-recipient`;
    this.editRecipient = `${this.routePath}/edit-recipient`;
    this.detailsRecipient = `${this.routePath}/details`;
  }

  submitForm() {
    const document = this.searchForm.get('search')?.value;
    let temp;

    if(document == '' || this.isNumber(document)){
      this.getRecipients(document);
    } else{
      temp = document?.replace(/\D/g, '')
      this.searchForm.get('search')?.setValue(temp);
    }
  }

  isNumber(value: any): boolean {
    return /^\d+$/.test(value);
  } 

  deteleteRecipient(id: number) {
    this.recipientService
      .deteleteRecipient(id)
      .pipe(first())
      .subscribe({
        next: () => {
          this._snackBar.openSnackBarSuccess(
            'Destinatário excluído com sucesso.'
          );
          this.getRecipients();
        },
        error: () => {
          this._snackBar.openSnackBarError('Erro ao excluir destinatário.');
        },
      });
  }

  getRecipients(document?: string) {
    this.loadingContent = true;
    const size = this.size;
    const page = this.page;
    const paginatorObj = { size };

    if (document) {
      Object.assign(paginatorObj, { page: 0 });
      Object.assign(paginatorObj, { document: document });
    } else {
      Object.assign(paginatorObj, { page: page });
    }

    this.recipientService
      .getRecipients(paginatorObj)
      .pipe(first())
      .subscribe({
        next: (recipients: any) => {
          this.listRecipients = recipients.content?.map((recipient: any) => ({
            id: recipient.id,
            name: recipient.nome,
            document: recipient.documento,
            address: {
              zipcode: recipient.endereco.cep,
              province: recipient.endereco.uf,
              city: recipient.endereco.cidade,
              street: recipient.endereco.logradouro,
              number: recipient.endereco.numero,
              neighborhood: recipient.endereco.bairro,
              complement: recipient.endereco.complemento || '',
            },
          }));
          this.totalElements = recipients.totalElements;
          this.dataSource = new MatTableDataSource(this.listRecipients);
          this.loadingContent = false;
        },
        error: (error) => {
          console.error(error);
          this.loadingContent = false;
        },
      })
  }

  redirectActionsBtnImport(action: string) {
    if (action === 'Baixar modelo CSV') {
      this.csvDownload();
    } else {
      this.openDialogAttachment();
    }
  }

  public csvDownload() {
    const element = document.createElement('a');
    element.setAttribute(
      'href',
      `https://plataformadefretes-csv.s3.amazonaws.com/${environment.AMBIENTE}/csv/exemplo/destinatario.csv`
    );
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  openDialogAttachment() {
    const dialog = this.dialog.open(DialogAttachmentComponent, {
      data: {
        title: 'Importar destinatários',
        type: 'recipientCSV',
        description:
          'Arquivos suportados: .csv ou .xlsx. Se .csv, o separador das células deve ser vírgula(,).',
      },
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        setTimeout(() => {
          this.getRecipients();
        }, 2000);
      }
    });
  }

  redirectDetails(element: any){
    this.router.navigate([this.detailsRecipient, element.id]);
  }

}
