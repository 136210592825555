import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dialog-company-created',
  templateUrl: './dialog-company-created.component.html',
  styleUrls: ['./dialog-company-created.component.scss'],
})
export class DialogCompanyCreatedComponent {
  companyName = '';
  newCompany = '';
  editCompany = '';
  idCompany: number;
  home = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly router: Router,
    private readonly dialogRef: MatDialogRef<DialogCompanyCreatedComponent>
  ) {
    this.idCompany = this.data.idCompany;
    this.companyName = this.data.companyName;
  }

  redirect(action: string) {
    let path = '';
    if (action == 'close') {
      path = 'close';
    } else if (action == 'home') {
      path = 'carriers';
    } else if (action == 'new company') {
      path = 'configurations-carrier/companies/new-company';
    } else if (action == 'edit company') {
      path = 'configurations-carrier/companies/edit-company/' + this.idCompany;
    }
    this.dialogRef.close(path);
  }
}
