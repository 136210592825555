<mat-card class="container-principal card-container" *ngIf="loaded; else isLoading">
  <mat-card-content>
    <div class="">
      <h6>Regras de ambiente</h6>
    </div>
    <mat-card class="mt-3 card-details">
      <mat-card-content>
        <form [formGroup]="rulesForm">
          <div fxLayout="row" class="mb-3">
            <div fxLayout="column" fxFlex="25">
              <div class="form-group">
                <label class="label-input form-label right" for="offerApproval" id="label-environment-rules-approve">
                  Aprovar oferta a qualquer momento
                </label>
                <br>
                <div class="form-check form-check-inline">
                  <input
                    id="offerApprovalYes"
                    class="form-check-input"
                    name="offerApproval"
                    formControlName="offerApproval"
                    type="radio"
                    value="true"
                  />
                  <label class="form-check-label" for="offerApprovalYes" id="label-environment-rules-offer-approve-yes">
                    Sim
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    id="offerApprovalNo"
                    class="form-check-input radio"
                    name="offerApproval"
                    formControlName="offerApproval"
                    type="radio"
                    value="false"
                  />
                  <label class="form-check-label" for="offerApprovalNo" id="label-environment-rules-offer-approve-no">
                    Não
                  </label>
                </div>
              </div>
            </div>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </mat-card-content>
</mat-card>

<ng-template #isLoading>
  <mat-progress-bar mode="indeterminate" color="primary" style="margin-top: 100px;"></mat-progress-bar>
</ng-template>