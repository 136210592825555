import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, first, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(private readonly http: HttpClient) {}

  createOrder(obj: any) {
    return this.http.post<any>(`${environment.API_BACK}/pedido`, obj).pipe(
      map((dados) => {
        return dados;
      }),
      catchError((error) => {
        return of(error);
      })
    );
  }

  getOrder(id: any) {
    let params = new HttpParams();
    params = params.append('id', id);
    const httpOptions = {
      params: params,
    };

    return this.http.get<any>(`${environment.API_FILTER}/pedido`, httpOptions).pipe(
      map((dados) => {
        return dados;
      }),
      catchError((error) => {
        return of(error);
      })
    );
  }

  getOrderById(id: any){
    const httpOptions = {
      headers: new HttpHeaders({
      }),
    };
    return this.http
      .get<any>(
        `${environment.API_FILTER}/pedido?id=`+ id,
        httpOptions
      )
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  
  }

  getPedidoByNumero(numero: string) {
    let params = new HttpParams();
    params = params.append('numeroPedido', numero);
    params = params.append('possuiOferta', 'SEM_OFERTA');
    params = params.append('returnDTO', String(true));

    const httpOptions = {
      params: params,
    };
    return this.http
      .get<any>(`${environment.API_FILTER}/pedido`, httpOptions)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  
  getOrders(paramsObject: any) {
    let params = new HttpParams();
    params = params.append('size', String(paramsObject.size));
    params = params.append('page', String(paramsObject.page));
    params = params.append('returnDTO', String(true));
    

    if(paramsObject.filter){
      const filter = paramsObject.filter;
      for (const key of Object.keys(filter)) {
        if (
          filter[key] != undefined &&
          filter[key] != null &&
          filter[key] != ''
        ) {
          params = params.append(key, filter[key]);
        }
      }
  
    }else {
      params = params.append('possuiOferta', String(false));
    }

    if(paramsObject.ordering){
      const ordering = paramsObject.ordering;

      params = params.append('sort', ordering);

    }

    const httpOptions = {
      params,
    };

    return this.http
      .get<any>(`${environment.API_FILTER}/pedido`, httpOptions)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  getPedidos(id:any) {
    let params = new HttpParams();
    params = params.append('ids', String(id));

    const httpOptions = {
      params: params,
    };

    return this.http
      .get<any>(
        `${environment.API_FILTER}/pedido/all/ids`,
        httpOptions
      )
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  updateOrder(obj: any) {
    return this.http.put<any>(`${environment.API_BACK}/pedido`, obj).pipe(
      map((dados) => {
        return dados;
      }),
      catchError((error) => {
        return of(error);
      })
    );
  }

  deleteOrder(idOrder: number) {
    console.log(idOrder)

    let params = new HttpParams();
    params = params.append('id', idOrder);

    const httpOptions = {
      params: params,
    };

    return this.http
      .delete<any>(`${environment.API_BACK}/pedido`, httpOptions)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        }),
        catchError((error) => {
          throw new Error(error);
        })
      );
  }

  importOrderCsv(file: any) {
    const form: FormData = new FormData();
    form.append('file', file);
    const headers = new HttpHeaders();
    return this.http
      .post<any>(`${environment.API_BACK}/pedido/import`, form, { headers })
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }
}
