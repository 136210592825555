import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CompaniesService {
  constructor(private readonly http: HttpClient) {}

  getCompany(id: any) {
    return this.http
      .get<any>(`${environment.API_FILTER}/empresa?id=${id}`)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  editCompany(company: any) {
    return this.http.put<any>(`${environment.API_BACK}/empresa`, company).pipe(
      first(),
      map((dados) => {
        return dados;
      })
    );
  }

  createCompany(company: any) {
    return this.http.post<any>(`${environment.API_BACK}/empresa`, company).pipe(
      first(),
      map((dados) => {
        return dados;
      })
    );
  }

  getCompanies() {
    return this.http
      .get<any>(`${environment.API_FILTER}/empresa/empresas`)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  getMyCompanies(paramsObject: any) {
    let params = new HttpParams();
    params = params.append('size', String(paramsObject.size));
    params = params.append('page', String(paramsObject.page));
    if (paramsObject.status && paramsObject.status !== '') {
      params = params.append('status', paramsObject.status);
    }
    return this.http
      .get<any>(`${environment.API_FILTER}/cliente/minhas-empresas`, {
        params: params,
      })
      .pipe(
        first(),
        map((data: any) => {
          return data;
        })
      );
  }

  updateStatus(obj: any) {
    let params = new HttpParams();
    params = params.append('idEmpresa', String(obj.idEmpresa));
    params = params.append('status', String(obj.status));

    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('idEmpresa', String(obj.idEmpresa))
      .set('status', String(obj.status))
      .set('responseType', 'text');

    return this.http
      .put(
        `${environment.API_BACK}/empresa/altera-status`,
        {},
        { headers, params }
      )
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  getSector() {
    return this.http.get<any>(`${environment.API_FILTER}/setor/all`).pipe(
      first(),
      map((dados) => {
        return dados;
      })
    );
  }

  saveLoad(obj: any) {
    const objComLookupRenomeado = { empresa: obj.empresa, cargaList: obj.loadList };
    return this.http
      .post<any>(
        `${environment.API_BACK}/carga-empresa`,
        objComLookupRenomeado
      )
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  deleteLoad(obj: any) {
    let params = new HttpParams();
    params = params.append('idEmpresa', obj.idEmpresa);
    params = params.append('cargaLookup', obj.loadType);

    const httpOptions = {
      params: params,
    };

    return this.http.delete(
      `${environment.API_BACK}/carga-empresa/remove-carga`,
      httpOptions
    );
  }

  getEmbarcador(obj: any) {
    let params = new HttpParams();
    if (obj.id != undefined) {
      params = params.append('id', obj.id);
    } else {
      params = params.append('size', String(obj.size));
      params = params.append('page', String(obj.page));
    }

    const httpOptions = {
      params: params,
    };
    return this.http
      .get<any>(`${environment.API_BACK}/embarcador`, httpOptions)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  importCompanyCsv(file: any) {
    const form: FormData = new FormData();
    form.append('file', file);
    const headers = new HttpHeaders();
    return this.http
      .post<any>(`${environment.API_BACK}/empresa/import`, form, { headers })
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  saveUF(obj: any) {
    const objComLookupRenomeado = { ...obj, ufList: obj.lookup };
    return this.http
      .post<any>(`${environment.API_BACK}/uf-atendida`, objComLookupRenomeado)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  saveVeiculo(obj: any) {
    for (const v of obj.veiculoList) {
      Object.assign(v, {
        empresa: obj.empresa,
        possuiRastreador: obj.empresa.possuiVeiculosComRastreador,
      });
    }

    return this.http
      .post<any>(`${environment.API_BACK}/veiculo-empresa`, obj)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  saveFreightMode(obj: any) {
    const objComLookupRenomeado = { ...obj, modalidadeList: obj.lookup };
    return this.http
      .post<any>(`${environment.API_BACK}/modalidade-empresa`, objComLookupRenomeado)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  deleteUF(objPadrao: any) {
    let params = new HttpParams();
    params = params.append('idEmpresa', objPadrao.empresa.id);
    params = params.append('ufLookup', String(objPadrao.label));

    const httpOptions = {
      params: params,
    };

    return this.http.delete(
      `${environment.API_BACK}/uf-atendida/remove-uf`,
      httpOptions
    );
  }

  deleteVehicle(objPadrao: any) {
    let params = new HttpParams();
    params = params.append('idEmpresa', objPadrao.empresa.id);
    params = params.append('veiculoLookup', String(objPadrao.label));

    const httpOptions = {
      params: params,
    };

    return this.http.delete(
      `${environment.API_BACK}/veiculo-empresa/remove-veiculo`,
      httpOptions
    );
  }

  deleteFreightMode(objPadrao: any) {
    let params = new HttpParams();
    params = params.append('idEmpresa', objPadrao.empresa.id);
    params = params.append('modalidadeLookup', String(objPadrao.label));

    const httpOptions = {
      params: params,
    };

    return this.http.delete(
      `${environment.API_BACK}/modalidade-empresa/remove-modalidade`,
      httpOptions
    );
  }
}
