import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { first } from 'rxjs';
import { UserService } from 'src/app/core/services/shared/user.service';

export interface PeriodicElement {
  id: number;
  name: string;
  description: string;
  status: string;
  actions: string;
}

export interface HeaderTable {
  lbName: string;
  lbDescription: string
}

@Component({
  selector: 'app-user-type',
  templateUrl: './user-type.component.html',
  styleUrls: ['./user-type.component.scss'],
})
export class UserTypeComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['name', 'description'];
  dataSource = new MatTableDataSource();
  gridData: any = [];
  id: any;
  loaded = true;

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  headerTable: HeaderTable = {
    lbName: 'Nome',
    lbDescription: 'Descrição'
  };


  page = 0;
  size = 50;
  totalElements = 0;

  constructor(
    private readonly userService: UserService,
  ) {}

  ngOnInit(): void {
    this.getTipoUsuarios();
  }

  getTipoUsuarios() {
    this.loaded = false;
    const paginatorObj = {
      id: this.id,
      page: this.page,
      size: this.size,
    };
    this.userService
      .getUserType(paginatorObj)
      .pipe(first())
      .subscribe({
        next: (result: any) => {
          if (result != null) {
            this.gridData = result.content?.map((user: any) => ({
              idTipoUsuario: user.id,
              alcada: user.alcada,
              editavel: user.editavel,
              tipo: this.typeUsers(user.tipo),
              descricao:
                this.typeUsers(user.tipo) === 'ADMIN'
                  ? 'Tipo de usuário com privilégios totais'
                  : 'Tipo de usuário sem acesso a cadastro de usuário e empresas',
              visualizaMelhorias: user.visualizaMelhorias,
            }));
          }
          this.totalElements = result?.totalElements;
          this.dataSource = this.gridData;
          this.loaded = true;
        },
        error: (error: any) => {
          console.error(error);
          this.loaded = true;
        },
      })
  }

  typeUsers(type: string) {
    if (type == 'admin') return 'ADMIN';
    return 'PADRÃO';
  }

  public handlePageEvent(e: PageEvent) {
    if (this.size != e.pageSize) {
      this.page = 0;
    } else {
      this.page = e.pageIndex;
    }
    this.size = e.pageSize;
    this.getTipoUsuarios();
  }
}
