import { Component, ElementRef, ViewChild, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SnackbarDefaultService } from 'src/app/core/services/utils/snackbar-default.service';
import { certificacao_options } from 'src/app/core/models/consts';
import { PageEvent } from '@angular/material/paginator';
import { CertificationService } from '../../../../core/services/shared/certification.service';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs';
import moment from 'moment';
import { environment } from 'src/environments/environment';
import { DialogAttachmentComponent } from 'src/app/shared/components/dialog-attachment/dialog-attachment.component';
import { MatDialog } from '@angular/material/dialog';
import { NgSelectComponent } from '@ng-select/ng-select';
import { CompaniesService } from 'src/app/core/services/shared/companies.service';

export interface HeaderTable {
  lbCertifies: string;
  lbExpirationDate: string;
  lbAttachment: string;
  lbActions: string;
}

export interface PeriodicElement {
  id: string;
  certifies: string;
  expirationDate: string;
  attachment: any;
  actions: any;
}

@Component({
  selector: 'app-certifications',
  templateUrl: './certifications.component.html',
  styleUrls: ['./certifications.component.scss'],
})
export class CertificationsComponent implements OnInit {
  public formCertifications: FormGroup = this.formBuilder.group({
    certifies: ['', Validators.required],
    expirationDate: ['', Validators.required],
  });

  files: File[] = [];
  certifies = certificacao_options;
  page = 0;
  size = 50;
  link: string;

  id: any;
  gridData: any = [];
  loadingSpinner = false;
  options: string[] = ['Remover'];

  headerTable: HeaderTable = {
    lbCertifies: 'Certificação',
    lbExpirationDate: 'Validade',
    lbAttachment: 'Anexo',
    lbActions: 'Ações',
  };

  displayedColumns: string[] = [
    'certifies',
    'expirationDate',
    'attachment',
    'actions',
  ];

  allowedExtensionImage: string[] = [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/gif',
    'image/bmp',
  ];

  isImage = false;
  minDate: any;

  @ViewChild('certificationRef') certificationRef: NgSelectComponent;
  @ViewChild('expirationDateRef') expirationDateRef: ElementRef;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly formBuilder: FormBuilder,
    private readonly certificationService: CertificationService,
    private readonly companiesService: CompaniesService,
    private readonly _snackBar: SnackbarDefaultService,
    private readonly dialog: MatDialog,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.getCompany();
    this.cdr.detectChanges();
  }

  setMinDate() {
    const actualDate = new Date();
    actualDate.setDate(actualDate.getDate() + 1);
    const data = new Date();
    const pad = (num: any) => (num < 10 ? '0' + num : num);
    const year = data.getFullYear();
    const month = pad(data.getMonth() + 1);
    const day = pad(data.getDate());
    const hours = pad(data.getHours());
    const minutes = pad(data.getMinutes());
    this.minDate = new Date(`${year}-${month}-${day}T${hours}:${minutes}`);
  }

  openDialogAttachment() {
    const dialogRef = this.dialog.open(DialogAttachmentComponent, {
      data: { title: 'Envio de anexo' },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.link = environment.AWS_S3_ANEXO + result;
      }
    });
  }

  public handlePageEvent(e: PageEvent) {
    if (this.size != e.pageSize) {
      this.page = 0;
    } else {
      this.page = e.pageIndex;
    }
    this.size = e.pageSize;
    this.getCompany();
  }

  removeItem(label: any) {
    this.certificationService
      .deleteCertifies(this.getIdFromUrl(), label)
      .pipe(first())
      .subscribe({
        next: () => {
          this._snackBar.openSnackBarSuccess('Certificação Removida');
          this.getCompany();
        },
        error: () => {
          this._snackBar.openSnackBarError('Erro ao Remover Certificacao');
        },
      });
  }

  getIdFromUrl() {
    return this.route.snapshot.paramMap.get('id');
  }

  saveCertification() {
    if (this.formCertifications.invalid) {
      this.formCertifications.markAllAsTouched();
      if (this.formCertifications.controls['certifies'].invalid) {
        this.certificationRef.focus();
      } else {
        this.expirationDateRef.nativeElement.focus();
      }
      return;
    }

    const certificatonObj = {
      empresa: {
        id: this.getIdFromUrl(),
      },
      certificacaoList: [this.formCertifications.get('certifies').value],
      validade: moment(
        this.formCertifications.get('expirationDate').value
      ).format('yyyy-MM-DDTHH:mm:ss'),
      linkAnexo: this.link,
    };

    this.certificationService
      .saveCertification(Object.assign(certificatonObj))
      .pipe(first())
      .subscribe({
        next: () => {
          this._snackBar.openSnackBarSuccess('Certificações Adicionadas');
          this.formCertifications.reset();

          Object.keys(this.formCertifications.controls).forEach((key) => {
            this.formCertifications.controls[key].setErrors(null);
            this.formCertifications.controls[key].markAsPristine();
            this.formCertifications.controls[key].markAsUntouched();
          });

          this.getCompany();
        },
        error: () => {
          this._snackBar.openSnackBarError('Erro ao Adicionar Certificacao');
        },
      });
  }

  getCompany() {
    this.loadingSpinner = true;
    this.companiesService
      .getCompany(this.getIdFromUrl())
      .pipe(first())
      .subscribe({
        next: (data) => {
          this.gridData = data.certificacao?.map((element: any) => ({
            certifiesWithUndescore: element.certificacao,
            certifies: element.certificacao
              ? element.certificacao.replaceAll('_', ' ')
              : '',
            expirationDate: new Intl.DateTimeFormat('pt-BR').format(
              new Date(element.validade)
            ),
            attachment: element.linkAnexo,
          }));
          this.loadingSpinner = false;
        },
        error: (error) => {
          this.loadingSpinner = false;
          console.error(error);
        },
      })
  }
}
