import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';

import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { DialogAttachmentComponent } from './components/dialog-attachment/dialog-attachment.component';
import { DialogConfirmComponent } from './components/dialog-confirm/dialog-confirm.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSliderModule } from '@angular/material/slider';
import { DetailsComponent } from './components/details/details.component';
import { RedefinePasswordDialogComponent } from './components/redefine-password-dialog/redefine-password-dialog.component';
import { TermosPrivacidadeComponent } from './components/termos-privacidade/termos-privacidade.component';
import { RequirementsComponent } from './components/requirements/requirements.component';
import { DialogRequirementsRegisterComponent } from './components/dialog-requirements-register/dialog-requirements-register.component';
import { InviteLinkComponent } from './components/invite-link/invite-link.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AdvancedFilterComponent } from './components/advanced-filter/advanced-filter.component';
import { AdvancedFilterModalComponent } from './components/advanced-filter-modal/advanced-filter-modal.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TutorialsComponent } from './components/tutorials/tutorials.component';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ChatComponent } from './components/chat/chat.component';
import { MapChartComponent } from './components/map-chart/map-chart.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { DialogBlockOffersTrialComponent } from './components/dialog-block-offers-trial/dialog-block-offers-trial.component';
import { ExportCSVComponent } from './components/export-csv/export-csv.component';
import { SatPopoverModule } from '@ncstate/sat-popover';
import { FilterFreightOfferComponent } from './components/filter-freight-offer/filter-freight-offer.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MaskDirectiveModule } from '../core/directives/mask-directive/mask-directive.module';
import {MatRadioModule} from '@angular/material/radio';
import { ExternalMapComponent } from './components/external-map/external-map.component';
import { HelperModalComponent } from './components/helper-modal/helper-modal.component';
import { PipesModule } from '../core/pipes/pipes.module';

@NgModule({
  declarations: [
    FooterComponent,
    NavbarComponent,
    ToolbarComponent,
    SidebarComponent,
    PageTitleComponent,
    SnackbarComponent,
    DialogAttachmentComponent,
    DialogConfirmComponent,
    DetailsComponent,
    RedefinePasswordDialogComponent,
    TermosPrivacidadeComponent,
    InviteLinkComponent,
    AdvancedFilterComponent,
    AdvancedFilterModalComponent,
    RequirementsComponent,
    DialogRequirementsRegisterComponent,
    TutorialsComponent,
    ChatComponent,
    MapChartComponent,
    DialogBlockOffersTrialComponent,
    ExportCSVComponent,
    FilterFreightOfferComponent,
    ExternalMapComponent,
    HelperModalComponent,
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    ToolbarComponent,
    SidebarComponent,
    PageTitleComponent,
    SnackbarComponent,
    DialogAttachmentComponent,
    DialogConfirmComponent,
    DetailsComponent,
    RedefinePasswordDialogComponent,
    TermosPrivacidadeComponent,
    RequirementsComponent,
    DialogRequirementsRegisterComponent,
    InviteLinkComponent,
    AdvancedFilterComponent,
    AdvancedFilterModalComponent,
    ChatComponent,
    MapChartComponent,
    ExportCSVComponent,
    FilterFreightOfferComponent,
    ExternalMapComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSidenavModule,
    MatToolbarModule,
    MatCheckboxModule,
    MatCardModule,
    MatListModule,
    MatMenuModule,
    MatExpansionModule,
    MatTooltipModule,
    FlexLayoutModule,
    MatTableModule,
    MatDialogModule,
    MatSliderModule,
    NgSelectModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgxDropzoneModule,
    ReactiveFormsModule,
    NgbModule,
    MatStepperModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    HighchartsChartModule,
    SatPopoverModule,
    MatDatepickerModule,
    AutocompleteLibModule,
    MaskDirectiveModule,
    MatRadioModule,
    PipesModule,
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [provideNgxMask()],
})
export class SharedModule { }
