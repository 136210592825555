import {
  Component,
  DoCheck,
  ElementRef,
  HostListener,
  ViewChild,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs';
import { SatPopover } from '@ncstate/sat-popover';
import { ChatService } from 'src/app/core/services/shared/chat.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements DoCheck, OnInit {
  listVisbleNotifications = true;
  typeUserMessage = '';
  notifications: any[] = [];
  routePath = '';
  contractRoutePath = '';
  url = '';
  message: string;
  carregou = true;
  countMessages = 0;
  countMessagesGroup = 0;
  offerNumberGroup: number;
  listGroupMessages: any[] = [];
  pathOfferDetailsShipper = 'shipper/freight-offer/offer-resume';
  redDot = false;

  user: string = JSON.parse(localStorage.getItem('usuario')).email;

  company: any = JSON.parse(localStorage.getItem('usuario')).cliente;
  idCliente = JSON.parse(localStorage.getItem('usuario'));

  @ViewChild('popover') popover: SatPopover;

  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    if (
      !this.elementRef.nativeElement.contains(event.target) &&
      this.popover.isOpen()
    ) {
      this.popover.close();
    }
  }

  constructor(
    private readonly router: Router,
    private readonly chatService: ChatService,
    private readonly elementRef: ElementRef
  ) {}

  ngOnInit() {
    if (/shipper/.exec(this.router.url) || /carriers/.exec(this.router.url)) {
      this.startNotificationCycle();
    }
  }

  ngDoCheck(): void {
    if (this.company.ofertaFrete) {
      this.routePath = 'shipper/freight-offer/offer-bid';
      this.contractRoutePath = 'shipper/freight-offer/contract-offer-resume';
      this.typeUserMessage = 'Transportador';
    } else {
      this.routePath = 'carriers/offers/offer-details';
      this.contractRoutePath = 'carriers/offers/contract-offer-details';
      this.typeUserMessage = 'Embarcador';
    }

    this.url = this.router.url.substring(1, this.router.url.lastIndexOf('/'));

    this.redDot =
      this.notifications.length > 0 || this.listGroupMessages.length > 0;
  }

  showNotifications() {
    this.listVisbleNotifications = !this.listVisbleNotifications;
  }

  redirectGroupMessages(element: any, index: any) {
    this.listGroupMessages.splice(index, 1);
    if (element.idColeta) {
      if (this.company.ofertaFrete) {
        sessionStorage.setItem('tab-contract', '3');
      } else {
        sessionStorage.setItem('tab-contract', '2');
      }
      if (/resume/.exec(this.url)) {
        this.showNotifications();
        this.router
          .navigate([this.contractRoutePath, element.idOferta])
          .then(() => {
            location.reload();
          });
      } else {
        this.showNotifications();
        this.router.navigate([this.contractRoutePath, element.idOferta]);
      }
    } else if (!element.idColeta) {
      if (/offer-resume/.exec(this.url)) {
        this.showNotifications();
        this.router
          .navigate([this.pathOfferDetailsShipper, element.idOferta])
          .then(() => {
            location.reload();
          });
      } else {
        this.showNotifications();
        this.router.navigate([this.pathOfferDetailsShipper, element.idOferta]);
      }
    }
    this.popover.close();
  }

  redirect(element: any, index: any) {
    this.notifications.splice(index, 1);
    if (element.idColeta) {
      if (this.company.ofertaFrete) {
        sessionStorage.setItem('tab-contract', '3');
      } else {
        sessionStorage.setItem('tab-contract', '2');
      }

      if (/resume/.exec(this.url)) {
        this.showNotifications();
        this.router
          .navigate([this.contractRoutePath, element.idOferta])
          .then(() => {
            location.reload();
          });
      } else {
        this.showNotifications();
        this.router.navigate([this.contractRoutePath, element.idOferta]);
      }
    } else {
      this.setTab();
      const pathBid = `${this.routePath}/${element.idOferta}/${element.idLance}`;

      if (/offer-bid/.exec(this.url) || /offer-details/.exec(this.url)) {
        this.showNotifications();
        this.router.navigate([pathBid]).then(() => {
          location.reload();
        });
      } else {
        this.showNotifications();
        this.router.navigate([pathBid]);
      }
    }
    this.popover.close();
  }

  groupMessages(notifications: any) {
    let data: any;
    this.listGroupMessages = [];
    for (const n of notifications) {
      if (n.nomeEmpresas) {
        data = {
          idOferta: n.idOferta,
          numeroOferta: n.numeroOferta,
        };
        if (this.listGroupMessages.length == 0) {
          this.listGroupMessages.push(data);
        } else if (this.listGroupMessages.length > 0) {
          if (!this.listGroupMessages.includes(n.idOferta)) {
            this.listGroupMessages.push(n);
          }
        }
      }
    }
    if (this.listGroupMessages.length > 0)
      this.countMessagesGroup = this.listGroupMessages.length;
  }

  getUniqueMessage(notifications: any) {
    this.notifications = [];
    for (const n of notifications) {
      if (!n.nomeEmpresas) {
        if (this.notifications.length == 0) {
          this.notifications.push(n);
        } else if (this.notifications.length > 0) {
          if (!this.notifications.includes(n.idOferta)) {
            this.notifications.push(n);
          }
        }
      }
    }
    if (this.notifications.length > 0)
      this.countMessages = this.notifications.length;
  }

  getNotifications() {
    this.chatService
      .getNotifications()
      .pipe(first())
      .subscribe({
        next: (data) => {
          this.groupMessages(data);
          this.getUniqueMessage(data);
        },
        error: (error) => {
          console.error(error);
        },
      });
  }

  setTab() {
    sessionStorage.setItem('tab-message', '2');
  }

  timeOutNotification(time: any) {
    this.getNotifications();

    if (time === 5000) {
      setTimeout(() => this.timeOutNotification(10000), 5000); // Próxima chamada em 10 segundos
    } else if (time === 10000) {
      setTimeout(() => this.timeOutNotification(30000), 10000); // Próxima chamada em 30 segundos
    } else if (time === 30000) {
      setTimeout(() => this.timeOutNotification(60000), 30000); // Próxima chamada em 1 minuto
    } else if (time === 60000) {
      setTimeout(() => this.timeOutNotification(300000), 60000); // Próxima chamada em 5 minutos
    } else {
      setTimeout(() => this.timeOutNotification(300000), 300000); // Próxima chamada em 5 minutos
    }
  }

  startNotificationCycle() {
    this.timeOutNotification(10000); // Inicia com 10 segundos
  }
}
