import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  constructor(private readonly http: HttpClient) {}

  changeUserStatus(id: number) {
    return this.http
      .put<any>(
        `${environment.API_BACK}/usuario/alterar-status-usuario/${id}`,
        id
      )
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  getUserType(obj: any) {
    let params = new HttpParams();
    if (obj.id != undefined) {
      params = params.append('id', obj.id);
    } else {
      params = params.append('size', String(obj.size));
      params = params.append('page', String(obj.page));
    }

    const httpOptions = {
      params: params,
    };
    return this.http
      .get<any>(`${environment.API_FILTER}/tipo-usuario`, httpOptions)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  getUser(obj: any) {
    let params = new HttpParams();
    if (obj.id != undefined) {
      params = params.append('id', obj.id);
    } else {
      params = params.append('size', String(obj.size));
      params = params.append('page', String(obj.page));
    }

    const httpOptions = {
      params: params,
    };
    return this.http
      .get<any>(`${environment.API_FILTER}/usuario`, httpOptions)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  createUser(obj: any) {
    return this.http.post<any>(`${environment.API_BACK}/usuario`, obj).pipe(
      first(),
      map((dados) => {
        return dados;
      })
    );
  }

  updateUser(obj: any) {
    return this.http
      .put<any>(`${environment.API_BACK}/usuario/atualiza-usuario`, obj)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        }),
        catchError((error) => {
          throw new Error(error);
        })
      );
  }

  createUserManagement(dto: any) {
    return this.http.post<any>(`${environment.API_BACK}/gestao`, dto).pipe(
      first(),
      map((dados) => {
        return dados;
      }),
      catchError((error) => {
        throw new Error(error);
      })
    );
  }

  getUserManagement() {
    let params = new HttpParams();
    params = params.append('returnDTO', 'true');
    const httpOptions = {
      params: params,
    };

    return this.http.get<any>(`${environment.API_FILTER}/gestao-usuario`, httpOptions).pipe(
      first(),
      map((dados) => {
        return dados;
      })
    );
  }

  getUserManagementById(obj: any, id: any) {
    let params = new HttpParams();
    params = params.append('size', String(obj.size));
    params = params.append('page', String(obj.page));
    params = params.append('usuario', id);

    const httpOptions = {
      params: params,
    };
    return this.http
      .get<any>(`${environment.API_FILTER}/gestao-usuario`, httpOptions)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  getuserManagementWithoutParams() {
    return this.http.get<any>(`${environment.API_FILTER}/gestao-usuario`).pipe(
      first(),
      map((dados) => {
        return dados;
      })
    );
  }

  getShipperByUser(obj: any) {
    const usuarioQuery = obj?.usuario ? `&usuario=${obj.usuario}` : '';
    const url = `${environment.API_FILTER}/gestao-usuario?size=999${usuarioQuery}`;
    return this.http.get<any>(url).pipe(
      first(),
      map((dados) => {
        return dados;
      })
    );
  }

  usuarioBTG() {
    return this.http
      .post<any>(`${environment.API_BACK}/usuario/btg`, null)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        }),
        catchError((error) => {
          return of(error);
        })
      );
  }
}

