import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subject, first } from 'rxjs';
import { DialogConfirmComponent } from 'src/app/shared/components/dialog-confirm/dialog-confirm.component';
import { PermissaoService } from 'src/app/core/services/auth/permissao.service';
import { SnackbarDefaultService } from 'src/app/core/services/utils/snackbar-default.service';
import { UserService } from 'src/app/core/services/shared/user.service';

export const dataEmitter = new Subject<any>();
export interface PeriodicElement {
  id: number;
  name: string;
  email: string;
  status: string;
  actions: string;
}

export interface HeaderTable {
  lbName: string;
  lbEmail: string;
  lbStatus: string;
  lbActions: string;
}

@Component({
  selector: 'app-my-users',
  templateUrl: './my-users.component.html',
  styleUrls: ['./my-users.component.scss'],
})
export class MyUsersComponent implements OnInit {
  displayedColumns: string[] = ['name', 'email', 'status', 'actions'];
  dataSource = new MatTableDataSource();
  loaded = true;
  options: any = ['Editar', 'Alterar status'];

  headerTable: HeaderTable = {
    lbName: 'Nome',
    lbEmail: 'E-mail',
    lbStatus: 'Status',
    lbActions: '	Ações',
  };

  routePath = '';
  newUser = '';
  editUser = '';
  gridData: any = [];
  page = 0;
  size = 50;
  totalElements = 0;
  loadingSpinner = false
  isAdmin = false;
  userId = Number(JSON.parse(localStorage.getItem('usuario'))?.id);

  constructor(
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly permissaoService: PermissaoService,
    private readonly dialog: MatDialog,
    private readonly _snackBar: SnackbarDefaultService
  ) {}
  ngOnInit(): void {
    this.routePath = window.location.pathname;
    this.newUser = `${this.routePath}/new-user`;
    this.editUser = `${this.routePath}/edit-user`;
    this.isAdmin = this.permissaoService.isAdmin();
    this.getUsers();
  }

  public redirect(action: string, row: any) {
    if (action === 'Editar') {
      this.router.navigate([this.editUser, row.id]).catch((err) => err);
    }
    if (action == 'Alterar status') {
      this.changeStatus(row);
    }
  }

  public getUsers() {
    const size = this.size;
    const page = this.page;
    this.loaded = false;

    const paginatorObj = {
      page,
      size,
    };

    this.userService
      .getUser(paginatorObj)
      .pipe(first())
      .subscribe({
        next: (users: any) => {
          this.gridData = users.content?.map((user: any) => ({
            id: user.id,
            name: user.nome.toUpperCase(),
            email: user.email,
            status: this.statusUserAdjustment(user),
            actions: '',
          }));
          this.totalElements = users.totalElements;
          this.dataSource = new MatTableDataSource(this.gridData);
          this.loaded = true;
        },
        error: (error) => {
          console.error(error);
          this.loaded = true;
        },
      })
  }

  public handlePageEvent(e: PageEvent) {
    if (this.size != e.pageSize) {
      this.page = 0;
    } else {
      this.page = e.pageIndex;
    }
    this.size = e.pageSize;
    this.getUsers();
  }

  public statusUserAdjustment(user: any) {
    if (user.statusUsuario === 'ATIVO') {
      return 'Ativo';
    } else {
      return 'Inativo';
    }
  }

  public changeStatus(row: any) {
    const text = row.status == 'Ativo' ? 'INATIVAR' : 'ATIVAR';

    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: 'Confirmação',
        description: `Tem certeza que deseja ${text} esse usuário?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loadingSpinner = true;
        this.userService
          .changeUserStatus(row.id)
          .pipe(first())
          .subscribe({
            next: (data: any) => {
              if (data) {
                this.loadingSpinner = false;
                this._snackBar.openSnackBarSuccess(
                  `Usuário ${text.toLowerCase()} com sucesso`
                );
                setTimeout(() => {
                  this.getUsers();
                }, 500);
              }
            },
            error: (error) => {
              this.loadingSpinner = false;
              console.error(error);
            },
          })
      }
    });
  }
}
