import { Component, DoCheck, OnInit } from '@angular/core'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, DoCheck{
  mobile = false
  year: number = new Date().getFullYear();

  ngOnInit(): void {
    this.checkMobile();
  }

  ngDoCheck(): void {
    this.checkMobile();
  }

  checkMobile() {
    if (window.innerWidth <= 700) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }
}
