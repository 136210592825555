<div fxLayout="row" class="container-1" fxLayoutAlign="center">
  <div fxLayout="row" FxlayoutAlign="space-between" class="row-carrier">
    <div fxLayout="column" fxFlex class="column-title column-text">
      <div>
        <p class="typer">Para transportadores</p>
        <h1 class="title">
          Amplie suas conexões, conquiste novos negócios e eleve sua empresa a
          novos horizontes.
        </h1>
        <p class="description">
          A Plataforma de Fretes oferece uma forma revolucionária de conquistar
          novos negócios. Através do maior ecossistema do mercado, você terá
          acesso a oportunidades qualificadas e poderá expandir sua base de
          clientes.
        </p>
        <p class="description">
          Além disso, nosso cálculo de frete otimizado e chat integrado
          facilitam a negociação e garantem uma comunicação eficiente.
        </p>
        <a
          mat-button
          color="primary"
          class="mt-4 register"
          href="/im-a-carrier#interest"
        >
          Quero conhecer
        </a>
      </div>
    </div>
    <div fxLayout="column" fxFlex class="img-truck">
      <div fxLayout="row" fxLayoutAlign="space-between">
        <img
          src="assets/landing/flip_transportador.png"
          alt=""
          class="img-carrier"
        />
      </div>
    </div>
  </div>
</div>

<div fxLayout="row" class="container-white justify-content-center">
  <div fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="center" class="global-title mb-4">
      <div class="mb-4">
        Sou
        <strong>transportador</strong>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center" class="global-subtitle mb-4">
      <div class="mb-4">
        Maior <strong>autonomia</strong> para o
        <strong>transportador</strong> realizar um <strong>frete</strong>
      </div>
    </div>

    <div *ngIf="!mobile; else step">
      <div fxLayout="row" fxLayoutAlign="center" class="row-step">
        <div fxLayout="column" fxFlex="31.5">
          <div class="stepper-item completed">
            <div class="products-icon text-white text-center mx-4 hexagon">
              <img
                src="assets/img/svg/encontrar_transp.svg"
                alt=""
                class="step-icon"
              />
            </div>
            <div class="products-container text-center" style="width: 15rem">
              <p class="products-title">Oferta de frete</p>
              <div class="step-description">
                <p class="products-description">
                  Encontre novos embarcadores e acesse as melhores
                  <strong>ofertas</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex="31.5">
          <div class="stepper-item completed">
            <div class="products-icon text-white text-center mx-4 hexagon">
              <img
                src="assets/img/svg/carga_transp.svg"
                alt=""
                class="step-icon"
              />
            </div>
            <div class="products-container text-center" style="width: 15rem">
              <p class="products-title">Melhores cargas</p>
              <div class="step-description">
                <p class="products-description">
                  Escolha as <strong>cargas</strong> compatíveis com a sua
                  <strong>frota</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex="31.5">
          <div class="stepper-item completed">
            <div class="products-icon text-white text-center mx-4 hexagon">
              <img
                src="assets/img/svg/lance_transp.svg"
                alt=""
                class="step-icon"
              />
            </div>
            <div class="products-container text-center" style="width: 15rem">
              <p class="products-title">Faça um lance</p>
              <div class="step-description">
                <p class="products-description">
                  De um lance nas <strong>ofertas</strong> disponíveis
                </p>
              </div>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex="31.5">
          <div class="stepper-item">
            <div class="products-icon text-white text-center mx-4 hexagon">
              <img
                src="assets/img/svg/contratacao_transp.svg"
                alt=""
                class="step-icon"
              />
            </div>
            <div class="products-container text-center" style="width: 15rem">
              <p class="products-title">Contratação</p>
              <div class="step-description">
                <p class="products-description">
                  Finalize a contratação de um frete em minutos
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-2">
  <div id="title-benefits" class="global-title mb-4">
    <div>Conheça algumas <strong> vantagens</strong></div>
  </div>

  <div id="card-benefits" *ngIf="!mobile; else benefitsMobile">
    <mat-card class="card p-2 mx-2" fxLayout="column">
      <mat-card-content>
        <div class="step text-white text-center mx-1 hexagon">
          <img src="assets/img/svg/ecossistema_transp.svg" alt="" />
        </div>
        <div class="content mt-4">
          <p class="title">Ecossistema com os embarcadores</p>
          <p class="description">
            Faça parte do maior ecossistema de embarcadores do Brasil e amplie
            sua rede de clientes. Tenha um aumento significativo na demanda
            pelos seus serviços como transportador cadastrado.
          </p>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="card p-2 mx-2" fxLayout="column">
      <mat-card-content>
        <div class="step text-white text-center mx-1 hexagon">
          <img src="assets/img/svg/credibilidade_transp.svg" alt="" />
        </div>
        <div class="content mt-4">
          <p class="title">Credibilidade para a sua empresa</p>
          <p class="description">
            Fortaleça a sua reputação e atraia mais oportunidades de negócio e
            estabelecendo relações duradouras e de confiança.
          </p>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="card p-2 mx-2" fxLayout="column">
      <mat-card-content>
        <div class="step text-white text-center mx-1 hexagon">
          <img src="assets/img/svg/custo_transp.svg" alt="" />
        </div>
        <div class="content mt-4">
          <p class="title">Digitalização simplificada de Fretes</p>
          <p class="description">
            Centralize todas as suas informações em uma única plataforma e
            consiga de forma simples ter relatórios e uma visão ampla dos fretes
            realizados
          </p>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div fxLayout="row" class="container-grey">
  <div fxLayout="column">
    <div
      fxLayout="row"
      class="global-title mb-4 text-left justify-content-center"
    >
      <div class="mb-4 functionalities">
        Principais funcionalidades para
        <strong style="color: #fa541c">Transportadoras</strong>
      </div>
    </div>
    <div
      fxLayout="row"
      class="global-subtitle mb-4 text-left justify-content-center"
    >
      <div class="mb-4">
        Amplie suas conexões, conquiste novos negócios e eleve sua empresa a
        novos horizontes!!
      </div>
    </div>

    <div *ngIf="!mobile; else functionalitiesMobile">
      <div fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column" fxFlex="31.5">
          <mat-card class="card p-2 mx-2 container-functionalities">
            <mat-card-content>
              <div class="products-icon">
                <img src="assets/img/svg/perfil_transp.svg" alt="" />
              </div>
              <div class="products-container">
                <p class="products-title">Perfil de apresentação</p>
                <p class="products-description">
                  Crie um perfil de forma simples e intuitiva para que a sua
                  transportadora seja visualizada por outros embarcadores que
                  você nem conhecia (conexão pode e deve ser simples).
                </p>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <div fxLayout="column" fxFlex="31.5">
          <mat-card class="card p-2 mx-2 container-functionalities">
            <mat-card-content>
              <div class="products-icon">
                <img src="assets/img/svg/calculator_transp.svg" alt="" />
              </div>
              <div class="products-container">
                <p class="products-title">Oferta de frete</p>
                <p class="products-description">
                  Tenha um leque de ofertas disponível a qualquer momento. Envie
                  o lance da sua oferta ser fechada em poucos minutos (processos
                  demorados é coisa do passado #nãopercamaistempo).
                </p>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <div fxLayout="column" fxFlex="31.5">
          <mat-card class="card p-2 mx-2 container-functionalities">
            <mat-card-content>
              <div class="products-icon">
                <img src="assets/img/svg/chat_transp.svg" alt="" />
              </div>
              <div class="products-container">
                <p class="products-title">Chat</p>
                <p class="products-description">
                  Simplifique a negociação e o agendamento de entregas com um
                  chat integrado, agilizando as operações e garantindo uma
                  comunicação eficiente com os embarcadores.
                </p>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>

<div fxLayout="row" class="container-grey" fxLayoutAlign="center" id="interest">
  <div fxLayout="column" fxFlex="100">
    <div fxLayout="row" class="global-title mb-4">
      <div class="mb-4 text-center">
        Entre em contato com nossos consultores e<br />
        <strong>conheça a solução</strong>
      </div>
    </div>
    <div class="container-interest">
      <!-- Start of HubSpot Embed Code -->
      <script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="https://js.hs-scripts.com/20786608.js"
      ></script>
      <!-- End of HubSpot Embed Code -->
      <iframe
        id="frm_home"
        src="https://share.hsforms.com/1BjpZoUgTTWqbYnr8hCo4CQcdj1s"
        class="frame"
        title="Form home"
      >
      </iframe>
    </div>
  </div>
</div>

<ng-template #step>
  <div fxLayout="row" class="container-step">
    <mat-card class="card">
      <mat-card-content>
        <div class="step step-1 completed">
          <div class="v-stepper">
            <div class="line"></div>
          </div>

          <div class="content">
            <span class="products-title">Oferta de frete</span>
            <p class="products-description">
              Encontre novos embarcadores e acesse as melhores
              <strong>ofertas</strong>
            </p>
          </div>
        </div>
        <div class="step step-2 completed">
          <div class="v-stepper">
            <div class="line"></div>
          </div>

          <div class="content">
            <span class="products-title">Melhores cargas</span>
            <p class="products-description">
              Escolha as <strong>cargas</strong> compatíveis com a sua
              <strong>frota</strong>
            </p>
          </div>
        </div>
        <div class="step step-3 completed">
          <div class="v-stepper">
            <div class="line"></div>
          </div>

          <div class="content">
            <span class="products-title">Faça um lance</span>
            <p class="products-description">
              De um lance nas <strong>ofertas</strong> disponíveis
            </p>
          </div>
        </div>

        <div class="step step-4 completed">
          <div class="v-stepper">
            <div class="line"></div>
          </div>

          <div class="content">
            <span class="products-title">Contratação</span>
            <p class="products-description">
              Finalize a contratação de um frete em minutos
            </p>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</ng-template>

<ng-template #benefitsMobile>
  <div class="benefits-slide">
    <div class="slideshow-container">
      <div class="mySlidesBenefits fade">
        <mat-card class="card p-2 mx-2 top-benefits">
          <mat-card-content>
            <div class="step text-white text-center mx-1 hexagon">
              <img src="assets/img/svg/ecossistema_transp.svg" alt="" />
            </div>
            <div class="content-card">
              <p class="title">Ecossistema com as transportadoras</p>
              <p class="description">
                O embarcador pode escolher de forma eficiente e confiável as
                melhores parcerias de transporte para atender às suas
                necessidades e ampliar sua rede de contatos.
              </p>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="mySlidesBenefits fade">
        <mat-card class="card p-2 mx-2 top-benefits">
          <mat-card-content>
            <div class="step text-white text-center mx-1 hexagon">
              <img src="assets/img/svg/credibilidade_transp.svg" alt="" />
            </div>
            <div class="content-card">
              <p class="title">Compliance na Gestão Logística</p>
              <p class="description">
                Com a integração de dados GKO Frete, o embarcador centraliza e
                integra todas as informações relevantes, reduzindo o tempo gasto
                na entrada de dados e obtendo visibilidade em tempo real.
              </p>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="mySlidesBenefits fade">
        <mat-card class="card p-2 mx-2 top-benefits">
          <mat-card-content>
            <div class="step text-white text-center mx-1 hexagon">
              <img src="assets/img/svg/custo_transp.svg" alt="" />
            </div>
            <div class="content-card">
              <p class="title">IA para otimização de custos</p>
              <p class="description">
                Através da nossa sugestão de frete otimizada, nossa plataforma
                ajuda a selecionar as melhores ofertas em termos de
                custo-benefício, reduzindo os gastos com transporte.
              </p>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <button
        class="prev"
        (click)="plusSlides(-1, 'mySlidesBenefits')"
        (keydown.enter)="plusSlides(-1, 'mySlidesBenefits')"
        tabindex="0"
        aria-label="Previous slide"
      >
        &#10094;
      </button>
      <button
        class="next"
        (click)="plusSlides(1, 'mySlidesBenefits')"
        (keydown.enter)="plusSlides(1, 'mySlidesBenefits')"
        tabindex="0"
        aria-label="Next slide"
      >
        &#10095;
      </button>
    </div>
    <br />

    <div style="text-align: center">
      <button
        class="dot dot-benefits"
        (click)="currentSlide(1, 'mySlidesBenefits')"
        (keydown.enter)="currentSlide(1, 'mySlidesBenefits')"
        tabindex="0"
        aria-label="Go to slide 1"
      ></button>
      <button
        class="dot dot-benefits"
        (click)="currentSlide(2, 'mySlidesBenefits')"
        (keydown.enter)="currentSlide(2, 'mySlidesBenefits')"
        tabindex="0"
        aria-label="Go to slide 2"
      ></button>
      <button
        class="dot dot-benefits"
        (click)="currentSlide(3, 'mySlidesBenefits')"
        (keydown.enter)="currentSlide(3, 'mySlidesBenefits')"
        tabindex="0"
        aria-label="Go to slide 3"
      ></button>
    </div>
  </div>
</ng-template>

<ng-template #functionalitiesMobile>
  <div class="functionalities-slide">
    <div class="slideshow-container">
      <div class="mySlidesFunctionalities fade">
        <mat-card class="card p-2 mx-2 container-functionalities">
          <mat-card-content>
            <div class="products-icon">
              <img src="assets/img/svg/perfil_transp.svg" alt="" />
            </div>
            <div class="products-container">
              <p class="products-title">Integração nativa</p>
              <p class="products-description">
                Centralize e integre as informações relevantes de forma segura,
                reduzindo o tempo gasto na entrada de dados e proporcionando uma
                visibilidade em tempo real.
              </p>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="mySlidesFunctionalities fade">
        <mat-card class="card p-2 mx-2 container-functionalities">
          <mat-card-content>
            <div class="products-icon">
              <img src="assets/img/svg/calculator_transp.svg" alt="" />
            </div>
            <div class="products-container">
              <p class="products-title">Sugestão de frete IA</p>
              <p class="products-description">
                Otimize o processo de sugestão de frete, selecionando a melhor
                data, garantindo o custo-benefício nas ofertas e reduzindo o
                lead time de aprovação
              </p>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="mySlidesFunctionalities fade">
        <mat-card class="card p-2 mx-2 container-functionalities">
          <mat-card-content>
            <div class="products-icon">
              <img src="assets/img/svg/chat_transp.svg" alt="" />
            </div>
            <div class="products-container">
              <p class="products-title">Chat</p>
              <p class="products-description">
                Simplifique a negociação e o agendamento de entregas com um chat
                integrado, agilizando as operações e garantindo uma comunicação
                eficiente com as transportadoras.
              </p>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <button
        class="prev"
        (click)="plusSlides(-1, 'mySlidesFunctionalities')"
        (keydown.enter)="plusSlides(-1, 'mySlidesFunctionalities')"
        tabindex="0"
        aria-label="Previous slide"
      >
        &#10094;
      </button>
      <button
        class="next"
        (click)="plusSlides(1, 'mySlidesFunctionalities')"
        (keydown.enter)="plusSlides(1, 'mySlidesFunctionalities')"
        tabindex="0"
        aria-label="Next slide"
      >
        &#10095;
      </button>
    </div>
    <br />

    <div style="text-align: center">
      <button
        class="dot dot-functionalities"
        (click)="currentSlide(1, 'mySlidesFunctionalities')"
        (keydown.enter)="currentSlide(1, 'mySlidesFunctionalities')"
        tabindex="0"
        aria-label="Go to slide 1"
      ></button>
      <button
        class="dot dot-functionalities"
        (click)="currentSlide(2, 'mySlidesFunctionalities')"
        (keydown.enter)="currentSlide(2, 'mySlidesFunctionalities')"
        tabindex="0"
        aria-label="Go to slide 2"
      ></button>
      <button
        class="dot dot-functionalities"
        (click)="currentSlide(3, 'mySlidesFunctionalities')"
        (keydown.enter)="currentSlide(3, 'mySlidesFunctionalities')"
        tabindex="0"
        aria-label="Go to slide 3"
      ></button>
    </div>
  </div>
</ng-template>
