export const uf_unique_options: any[] = [
  { codigo: '12', uf: 'AC', nome: 'Acre' },
  { codigo: '27', uf: 'AL', nome: 'Alagoas' },
  { codigo: '13', uf: 'AM', nome: 'Amazonas' },
  { codigo: '16', uf: 'AP', nome: 'Amapá' },
  { codigo: '29', uf: 'BA', nome: 'Bahia' },
  { codigo: '23', uf: 'CE', nome: 'Ceará' },
  { codigo: '53', uf: 'DF', nome: 'Distrito Federal' },
  { codigo: '32', uf: 'ES', nome: 'Espírito Santo' },
  { codigo: '52', uf: 'GO', nome: 'Goiás' },
  { codigo: '21', uf: 'MA', nome: 'Maranhão' },
  { codigo: '31', uf: 'MG', nome: 'Minas Gerais' },
  { codigo: '50', uf: 'MS', nome: 'Mato Grosso do Sul' },
  { codigo: '51', uf: 'MT', nome: 'Mato Grosso' },
  { codigo: '15', uf: 'PA', nome: 'Pará' },
  { codigo: '25', uf: 'PB', nome: 'Paraíba' },
  { codigo: '26', uf: 'PE', nome: 'Pernambuco' },
  { codigo: '22', uf: 'PI', nome: 'Piauí' },
  { codigo: '41', uf: 'PR', nome: 'Paraná' },
  { codigo: '33', uf: 'RJ', nome: 'Rio de Janeiro' },
  { codigo: '24', uf: 'RN', nome: 'Rio Grande do Norte' },
  { codigo: '11', uf: 'RO', nome: 'Rondônia' },
  { codigo: '14', uf: 'RR', nome: 'Roraima' },
  { codigo: '43', uf: 'RS', nome: 'Rio Grande do Sul' },
  { codigo: '42', uf: 'SC', nome: 'Santa Catarina' },
  { codigo: '28', uf: 'SE', nome: 'Sergipe' },
  { codigo: '35', uf: 'SP', nome: 'São Paulo' },
  { codigo: '17', uf: 'TO', nome: 'Tocantins' },
];

export const uf_options: any[] = [
  { label: 'AC', value: '12' },
  { label: 'AL', value: '27' },
  { label: 'AM', value: '13' },
  { label: 'AP', value: '16' },
  { label: 'BA', value: '29' },
  { label: 'CE', value: '23' },
  { label: 'DF', value: '53' },
  { label: 'ES', value: '32' },
  { label: 'GO', value: '52' },
  { label: 'MA', value: '21' },
  { label: 'MG', value: '31' },
  { label: 'MS', value: '50' },
  { label: 'MT', value: '51' },
  { label: 'PA', value: '15' },
  { label: 'PB', value: '25' },
  { label: 'PE', value: '26' },
  { label: 'PI', value: '22' },
  { label: 'PR', value: '41' },
  { label: 'RJ', value: '33' },
  { label: 'RN', value: '24' },
  { label: 'RO', value: '11' },
  { label: 'RR', value: '14' },
  { label: 'RS', value: '43' },
  { label: 'SC', value: '42' },
  { label: 'SE', value: '28' },
  { label: 'SP', value: '35' },
  { label: 'TO', value: '17' },
];

export const uf_options2: any[] = [
  { codigo: '12', uf: 'AC', value: 'Acre' },
  { codigo: '27', uf: 'AL', value: 'Alagoas' },
  { codigo: '13', uf: 'AM', nome: 'Amazonas' },
  { codigo: '16', uf: 'AP', nome: 'Amapá' },
  { codigo: '29', uf: 'BA', nome: 'Bahia' },
  { codigo: '23', uf: 'CE', nome: 'Ceará' },
  { codigo: '53', uf: 'DF', nome: 'Distrito Federal' },
  { codigo: '32', uf: 'ES', nome: 'Espírito Santo' },
  { codigo: '52', uf: 'GO', nome: 'Goiás' },
  { codigo: '21', uf: 'MA', nome: 'Maranhão' },
  { codigo: '31', uf: 'MG', nome: 'Minas Gerais' },
  { codigo: '50', uf: 'MS', nome: 'Mato Grosso do Sul' },
  { codigo: '51', uf: 'MT', nome: 'Mato Grosso' },
  { codigo: '15', uf: 'PA', nome: 'Pará' },
  { codigo: '25', uf: 'PB', nome: 'Paraíba' },
  { codigo: '26', uf: 'PE', nome: 'Pernambuco' },
  { codigo: '22', uf: 'PI', nome: 'Piauí' },
  { codigo: '41', uf: 'PR', nome: 'Paraná' },
  { codigo: '33', uf: 'RJ', nome: 'Rio de Janeiro' },
  { codigo: '24', uf: 'RN', nome: 'Rio Grande do Norte' },
  { codigo: '11', uf: 'RO', nome: 'Rondônia' },
  { codigo: '14', uf: 'RR', nome: 'Roraima' },
  { codigo: '43', uf: 'RS', nome: 'Rio Grande do Sul' },
  { codigo: '42', uf: 'SC', nome: 'Santa Catarina' },
  { codigo: '28', uf: 'SE', nome: 'Sergipe' },
  { codigo: '35', uf: 'SP', nome: 'São Paulo' },
  { codigo: '17', uf: 'TO', nome: 'Tocantins' },
];

export const uf_options_requisitos: any[] = [
  { label: 'AC', value: 'AC' },
  { label: 'AL', value: 'AL' },
  { label: 'AM', value: 'AM' },
  { label: 'AP', value: 'AP' },
  { label: 'BA', value: 'BA' },
  { label: 'CE', value: 'CE' },
  { label: 'DF', value: 'DF' },
  { label: 'ES', value: 'ES' },
  { label: 'GO', value: 'GO' },
  { label: 'MA', value: 'MA' },
  { label: 'MG', value: 'MG' },
  { label: 'MS', value: 'MS' },
  { label: 'MT', value: 'MT' },
  { label: 'PA', value: 'PA' },
  { label: 'PB', value: 'PB' },
  { label: 'PE', value: 'PE' },
  { label: 'PI', value: 'PI' },
  { label: 'PR', value: 'PR' },
  { label: 'RJ', value: 'RJ' },
  { label: 'RN', value: 'RN' },
  { label: 'RO', value: 'RO' },
  { label: 'RR', value: 'RR' },
  { label: 'RS', value: 'RS' },
  { label: 'SC', value: 'SC' },
  { label: 'SE', value: 'SE' },
  { label: 'SP', value: 'SP' },
  { label: 'TO', value: 'TO' },
];

export const UFs = [
  { label: 'Acre', value: 'AC' },
  { label: 'Alagoas', value: 'AL' },
  { label: 'Amazonas', value: 'AM' },
  { label: 'Amapá', value: 'AP' },
  { label: 'Bahia', value: 'BA' },
  { label: 'Ceará', value: 'CE' },
  { label: 'Distrito Federal', value: 'DF' },
  { label: 'Espírito Santo', value: 'ES' },
  { label: 'Goiás', value: 'GO' },
  { label: 'Maranhão', value: 'MA' },
  { label: 'Mato Grosso do Sul', value: 'MS' },
  { label: 'Mato Grosso', value: 'MT' },
  { label: 'Minas Gerais', value: 'MG' },
  { label: 'Pará', value: 'PA' },
  { label: 'Paraíba', value: 'PB' },
  { label: 'Pernambuco', value: 'PE' },
  { label: 'Piauí', value: 'PI' },
  { label: 'Paraná', value: 'PR' },
  { label: 'Rio de Janeiro', value: 'RJ' },
  { label: 'Rio Grande do Norte', value: 'RN' },
  { label: 'Rondônia', value: 'RO' },
  { label: 'Roraima', value: 'RR' },
  { label: 'Rio Grande do Sul', value: 'RS' },
  { label: 'Santa Catarina', value: 'SC' },
  { label: 'Sergipe', value: 'SE' },
  { label: 'São Paulo', value: 'SP' },
  { label: 'Tocantins', value: 'TO' },
];

export const carga_options: any[] = [
  { label: 'Cargas Secas', value: 'CARGAS_SECAS' },
  {
    label: 'Cargas Frigoríficas - Perecíveis',
    value: 'CARGAS_FRIGORIFICAS_PERECIVEIS',
  },
  {
    label: 'Cargas Frigoríficas - Congeladas',
    value: 'CARGAS_FRIGORIFICAS_CONGELADAS',
  },
  { label: 'Cargas a Granel - Líquido', value: 'CARGAS_A_GRANEL_LIQUIDO' },
  { label: 'Cargas a Granel - Sólido', value: 'CARGAS_A_GRANEL_SOLIDO' },
  { label: 'Cargas Vivas', value: 'CARGAS_VIVAS' },
  {
    label: 'Cargas Indivisíveis e excepcionais de grande porte',
    value: 'CARGAS_INDIVISIVEIS_E_EXCEPCIONAIS_DE_GRANDE_PORTE',
  },
  { label: 'Cargas Perigosas', value: 'CARGAS_PERIGOSAS' },
  { label: 'Químico', value: 'QUIMICO' },
  { label: 'Outros', value: 'OUTROS' },
];

export const veiculo_options: any[] = [
  { label: 'BETONEIRA', value: 'BETONEIRA' },
  { label: 'BITREM', value: 'BITREM' },
  { label: 'CARRETA', value: 'CARRETA' },
  { label: 'CARRETA 60 M3', value: 'CARRETA_60_M3' },
  { label: 'CARRETA 80 M3', value: 'CARRETA_80_M3' },
  { label: 'CARRETA ABERTA', value: 'CARRETA_ABERTA' },
  { label: 'CARRETA FECHADA', value: 'CARRETA_FECHADA' },
  { label: 'CARRETA REFRIGERADA', value: 'CARRETA_REFRIGERADA' },
  { label: 'CARRETA SIDER', value: 'CARRETA_SIDER' },
  { label: 'CAMINHONETE', value: 'CAMINHONETE' },
  { label: 'CAMINHAO TANQUE', value: 'CAMINHAO_TANQUE' },
  { label: "CONT. 20'' REFR", value: 'CONT_20_REFR' },
  { label: "CONTAINER 20''", value: 'CONTAINER_20' },
  { label: "CONT. 40'' HC", value: 'CONT_40_HC' },
  { label: "CONT. 40'' REFR", value: 'CONT_40_REFR' },
  { label: "CONTAINER 40''", value: 'CONTAINER_40' },
  { label: 'EXPRESS. AEREO', value: 'EXPRESS_AEREO' },
  { label: 'CORREIO EXPRESS', value: 'CORREIO_EXPRESS' },
  { label: 'EXPR. FERROV.', value: 'EXPR_FERROV' },
  { label: 'EXPRESS. ROD.', value: 'EXPRESS_ROD' },
  { label: 'FIORINO', value: 'FIORINO' },
  { label: 'FRETE AEREO', value: 'FRETE_AEREO' },
  { label: 'FURGAO', value: 'FURGAO' },
  { label: 'MENSAGEIRO AER', value: 'MENSAGEIRO_AER' },
  { label: 'NAVIO TANQUE', value: 'NAVIO_TANQUE' },
  { label: 'ENCOM. POSTAL', value: 'ENCOM_POSTAL' },
  { label: 'RODOTREM', value: 'RODOTREM' },
  { label: 'TOCO', value: 'TOCO' },
  { label: 'TOCO ABERTO', value: 'TOCO_ABERTO' },
  { label: 'TOCO BAU', value: 'TOCO_BAU' },
  { label: 'TOCO FECHADO', value: 'TOCO_FECHADO' },
  { label: 'TOCO SIDER', value: 'TOCO_SIDER' },
  { label: 'TRUCK', value: 'TRUCK' },
  { label: 'TRUCK ABERTO', value: 'TRUCK_ABERTO' },
  { label: 'TRUCK BAU', value: 'TRUCK_BAU' },
  { label: 'TRUCK FECHADO', value: 'TRUCK_FECHADO' },
  { label: 'TRUCK GRANELEIRO', value: 'TRUCK_GRANELEIRO' },
  { label: 'TRUCK REFRIG.', value: 'TRUCK_REFRIG' },
  { label: 'TRUCK SIDER', value: 'TRUCK_SIDER' },
  { label: 'VAGAO GRANELEIR', value: 'VAGAO_GRANELEIR' },
  { label: 'VAGAO TANQUE', value: 'VAGAO_TANQUE' },
  { label: 'VUC', value: 'VUC' },
];

export const certificacao_options: any[] = [
  {
    label: 'Cadastro Técnico Federal - Certificado de Registro-IBAMA',
    value: 'CADASTRO_TECNICO_FEDERAL_CERTIFICADO_DE_REGISTRO_IBAMA',
  },
  {
    label:
      'Autorização Ambiental para o Transporte Interestadual de Produtos Perigosos-IBAMA',
    value:
      'AUTORIZACAO_AMBIENTAL_PARA_O_TRANSPORTE_INTERESTADUAL_DE_PRODUTOS_PERIGOSO_IBAMA',
  },
  {
    label: 'Licença de Funcionamento-Polícia Federal',
    value: 'LICENCA_DE_FUNCIONAMENTO_POLICIA_FEDERAL',
  },
  {
    label: 'Alvará para Transporte/ Depósito-Polícia Civil',
    value: 'ALVARA_PARA_TRANSPORTE_DEPOSITO_POLICIA_CIVIL',
  },
  {
    label: 'Certificado de Registro para Transporte-Exército',
    value: 'CERTIFICADO_DE_REGISTRO_PARA_TRANSPORTE_EXERCITO',
  },
  {
    label: 'Licença p/ Transporte Internacional de Carga-Polícia Federal',
    value: 'LICENCA_PARA_TRANSPORTE_INTERNACIONAL_DE_CARGA_POLICIA_FEDERAL',
  },
  {
    label:
      'Autorização de Funcionamento e Alvará sanitário-ANVISA e Vigilância Sanitária',
    value:
      'AUTORIZACAO_DE_FUNCIONAMENTO_E_ALVARA_SANITARIO_ANVISA_E_VIGILANCIA_SANITARIA',
  },
  {
    label:
      'Licença Especial de Transito de Produtos Perigosos-Secretaria do Verde e Meio Ambiente de São Paulo',
    value:
      'LICENCA_ESPECIAL_DE_TRANSITO_DE_PRODUTOS_PERIGOSOS_SECRETARIA_DO_VERDE_E_MEIO_AMBIENTE_DE_SAO_PAULO',
  },
  {
    label: 'SEMA-Secretaria Estadual do Meio Ambiente-Amapá',
    value: 'SEMA_SECRETARIA_ESTADUAL_DO_MEIO_AMBIENTE_AMAPA',
  },
  {
    label: 'IPAAM-Instituto de Proteção Ambiental do Amazonas',
    value: 'IPAAM_INSTITUTO_DE_PROTECAO_AMBIENTAL_DO_AMAZONAS',
  },
  {
    label: 'CRA-Centro de Recursos Ambientais-Bahia',
    value: 'CRA_CENTRO_DE_RECURSOS_AMBIENTAIS_BAHIA',
  },
  {
    label: 'SEMACE-Superintendência Estadual do Meio Ambiente do Ceará',
    value: 'SEMACE_SUPERINTENDENCIA_ESTADUAL_DO_MEIO_AMBIENTE_DO_CEARA',
  },
  {
    label: 'CONSEMA / SEAMA-Conselho Estadual do Meio Ambiente-Espírito Santo',
    value: 'CONSEMA_SEAMA_CONSELHO_ESTADUAL_DO_MEIO_AMBIENTE_ESPIRITO_SANTO',
  },
  {
    label: 'GOIAS-Agência ambiental de Goiás',
    value: 'GOIAS_AGENCIA_AMBIENTAL_DE_GOIAS',
  },
  {
    label: 'FEMA-Fundação Estadual do Meio Ambiente-Mato Grosso',
    value: 'FEMA_FUNDACAO_ESTADUAL_DO_MEIO_AMBIENTE_MATO_GROSSO',
  },
  {
    label:
      'SEMACT-Secretaria Estadual do Meio Ambiente Cultura e Turismo-Mato Grosso do Sul',
    value:
      'SEMACT_SECRETARIA_ESTADUAL_DO_MEIO_AMBIENTE_CULTURA_E_TURISMO_MATO_GROSSO_DO_SUL',
  },
  {
    label: 'FEAM-Fundação Estadual do Meio Ambiente-Minas Gerais',
    value: 'FEAM_FUNDACAO_ESTADUAL_DO_MEIO_AMBIENTE_MINAS_GERAIS',
  },
  {
    label: 'SECTAM-Sec. Do Estado da ciência, Tecnologia e Meio Ambiente-Pará',
    value:
      'SECTAM_SECRETARIA_DO_ESTADO_DA_CIENCIA_TECNOLOGIA_E_MEIO_AMBIENTE_PARA',
  },
  {
    label: 'SUDEMA-Superin, Administração do Meio Ambiente-Paraíba',
    value: 'SUDEMA_SUPERIN_ADMINISTRACAO_DO_MEIO_AMBIENTE_PARAIBA',
  },
  {
    label: 'IAP-Instituto Ambiental do Paraná',
    value: 'IAP_INSTITUTO_AMBIENTAL_DO_PARANA',
  },
  {
    label:
      'CPRH-Cia Pernambucana de Controle Amb Admin Rec Hídricos-Pernambuco',
    value:
      'CPRH_CIA_PERNAMBUCANA_DE_CONTROLE_AMB_ADMIN_REC_HIDRICOS_PERNAMBUCO',
  },
  {
    label: 'INEA-Instituto Estadual Ambiental-Rio de Janeiro',
    value: 'INEA_INSTITUTO_ESTADUAL_AMBIENTAL_RIO_DE_JANEIRO',
  },
  {
    label:
      'IDEMA-Inst.Desenv.eEconomico Transportadores meio ambiente-Rio Grande do Norte',
    value:
      'IDEMA_INSTITUTO_DE_DESENVOLVIMENTO_E_ECONOMICO_TRANSPORTADORES_MEIO_AMBIENTE_RIO_GRANDE_DO_NORTE',
  },
  {
    label: 'FEPAM-Fundação Estadual de Proteção Ambiental-Rio Grande do Sul',
    value: 'FEPAM_FUNDACAO_ESTADUAL_DE_PROTECAO_AMBIENTAL_RIO_GRANDE_DO_SUL',
  },
  {
    label: 'FATMA-Fundação do Meio Ambiente-Santa Catarina',
    value: 'FATMA_FUNDACAO_DO_MEIO_AMBIENTE_SANTA_CATARINA',
  },
  {
    label: 'ADEMA-Administração Estadual do Meio Ambiente-Sergipe',
    value: 'ADEMA_ADMINISTRACAO_ESTADUAL_DO_MEIO_AMBIENTE_SERGIPE',
  },
  {
    label: 'SEPLAN-Secretaria Estado Planejamento e Meio Ambiente-Tocantins',
    value: 'SEPLAN_SECRETARIA_ESTADO_PLANEJAMENTO_E_MEIO_AMBIENTE_TOCANTINS',
  },
];

export const modalidade_frete_options: any[] = [
  { label: 'LOTACAO', value: 'LOTACAO' },
  { label: 'FRACIONADO', value: 'FRACIONADO' },
];

export const tipo_usuario_options: any[] = [
  { label: 'Administrador', value: 'ADMIN' },
  { label: 'Padrão', value: 'PADRAO' },
];

export const tipo_oferta_frete_options: any[] = [
  { label: 'Spot', value: 'SPOT' },
  //{ label: 'Contrato', value: 'CONTRATO' },
];

export const setor_atuacao_options: any[] = [
  { label: 'Plástico e Borracha', value: 'Plast_Borr' },
  { label: 'Farma/Cosmético/Médico', value: 'Far_Cos_Med' },
  { label: 'Alimentos / Agro', value: 'Ali_Agro' },
  { label: 'Bens Consumo/Eletrônicos', value: 'BensCon_Eletr' },
  { label: 'Químico/ Petróleo/Combustível', value: 'Qui_Petr_Conb' },
  { label: 'Papel', value: 'Papel' },
  { label: 'Textil / Calçados', value: 'Text_Cal' },
  { label: 'Máquinas/ Siderurgia', value: 'Maq_Side' },
  { label: 'Transportador/Operador/Multimodal', value: 'Trans_Oper_MModal' },
  { label: 'Varejista', value: 'Varej' },
  { label: 'Fabricação de Peças e Veículos', value: 'Pecas_Veic' },
  { label: 'Atacado', value: 'Atacado' },
  { label: 'Móveis/Colchões', value: 'Moveis_Colch' },
  { label: 'Materiais Elétricos/EPI', value: 'MEletri_EPI' },
  { label: 'Insumos para Construção', value: 'IConstru' },
  { label: 'Geral', value: 'Geral' },
  { label: 'Não Informado', value: 'naoInformado' },
];