<div class="mt-4" [ngClass]="{'opacity': loadingSpinner,'container-opacity': loadingSpinner}">
  <form [formGroup]="generalForm">
    <div fxLayout="row" class="mt-4 mb-4 container-logo" *ngIf="companyIdentifier">
      <div fxLayout="column">
        <div style="display: flex; position: relative;">
              
          <!-- IMAGEM -->
          <img
            [src]="logo"
            alt="Indisponível"
            id="logo"
            width="88px"
            height="88px"
            [ngClass]="'logo'"
          />
  
          <!-- BOTÃO REMOVER -->
          <button
            class="btn-remove-photo"
            [ngbTooltip]="'Remover imagem'"
            (click)="removeLogo()"
            (keydown)="removeLogo()"
            id="btn-remove-logo"
          >
            <i class="ri-close-fill"></i> <!-- ícone X -->
          </button>
  
          <!-- BOTÃO ADICIONAR -->
          <label
            id="label-file"
            for="input-file"
            class="btn-photo"
            [ngbTooltip]="'Somente imagens com tamanho de até 400 x 400 serão aceitas'"
          >
            <i class="ri-camera-fill icon-photo"></i>
          </label>
  
          <!-- INPUT FILE -->
          <input
            type="file"
            id="input-file"
            accept="image/jpeg, image/png, image/jpg"
            name="logo"
            (change)="updatePhoto($event)"
            hidden
          />
        </div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between" class="mb-3">
      <div fxLayout="column" fxFlex class="right">
        <div class="form-group">
          <label class="label-input form-label required" for="legalName" id="label-company-registration-legal-name">
            Razão social
          </label>
          <input
            #legalNameRef
            id="input-company-registration-legal-name"
            matInput
            name="legalName"
            class="form-control input-data w-100"
            formControlName="legalName"
            placeholder="Digite a razão social"
            required
            [ngClass]="{
              'is-invalid':
                generalForm.get('legalName')?.invalid &&
                generalForm.get('legalName')?.touched &&
                generalForm.get('legalName')?.value === '',
              'is-valid':
                generalForm.get('legalName')?.value?.length > 0 &&
                generalForm.get('legalName')?.touched ||
                generalForm.get('legalName')?.value !== ''
            }"
          />
          <div
          *ngIf="
              generalForm.get('legalName')?.invalid &&
              generalForm.get('legalName')?.touched &&
              generalForm.get('legalName')?.value === ''
            "
            class="invalid-feedback label-mandatory"
            id="invalid-feedback-company-registration-legal-name"
          >
            Campo obrigatório*
          </div>
        </div>
      </div>
      <div fxLayout="column" fxFlex class="left">
        <div class="form-group">
          <div class="d-flex">
            <div class="flex-grow-1">
              <label class="label-input form-label required" for="document" id="label-company-registration-cnpj">
                CNPJ
              </label>
            </div>
          </div>
          <input
            #documentRef
            id="input-company-registration-cnpj"
            matInput
            mask="00.000.000/0000-00"
            name="document"
            class="form-control input-data w-100"
            formControlName="document"
            placeholder="Digite o CNPJ"
            required
            [ngClass]="{
                  'is-invalid':
                  generalForm.get('document')?.invalid &&
                  generalForm.get('document')?.touched,
                  'is-valid':
                  (generalForm.get('document')?.valid &&
                  generalForm.get('document')?.touched) || generalForm.get('document')?.value?.length === 14,
                }"
          />
          <div
            *ngIf="
              generalForm.get('document')?.invalid &&
              generalForm.get('document')?.touched &&
              generalForm.get('document').value.length === 0
            "
            class="invalid-feedback label-mandatory"
            id="invalid-feedback-company-registration-cnpj-required"
          >
            Campo obrigatório*
          </div>
          <div
            *ngIf="
              generalForm.get('document').value.length > 0 &&
              generalForm.get('document').value.length < 14
            "
            class="invalid-feedback label-mandatory"
            id="invalid-feedback-company-registration-cnpj-invalid"
          >
            CNPJ inválido!
          </div>
        </div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between" class="mb-3">
      <div fxLayout="column" fxFlex class="right">
        <div class="form-group">
        <label class="label-input form-label required" for="activitySector" id="label-company-registration-activity-sector">
          Setor de Atuação
        </label>
        <ng-select
          id="input-company-registration-activity-sector"
          #activitySectorRef
          name="activitySector"
          formControlName="activitySector"
          [items]="activitySectorOptions"
          [closeOnSelect]="true"
          [searchable]="true"
          [clearable]="false"
          bindLabel="label"
          bindValue="value"
          required
          [ngClass]="{
            'invalid is-invalid':
            generalForm.get('activitySector')?.invalid &&
            generalForm.get('activitySector')?.touched &&
            generalForm.get('activitySector').value === '',
            'valid is-valid':
            generalForm.get('activitySector')?.value?.length > 0 &&
            generalForm.get('activitySector')?.touched || 
            generalForm.get('activitySector')?.value?.length > 0 ,
          }"
        >
          <ng-template ng-label-tmp let-item="item" let-index="index">
            {{ item.label }} 
          </ng-template>
        </ng-select>
        <div
        *ngIf="
          generalForm.get('activitySector')?.invalid &&
          generalForm.get('activitySector').value === ''
        "
        class="invalid-feedback label-mandatory"
        id="invalid-feedback-company-registration-activity-sector"
      >
        Campo obrigatório*
       </div>
      </div>
      </div>
      <div fxLayout="column" fxFlex class="left">
        <div class="form-group">
          <label class="label-input form-label required" for="zipcode" id="label-company-registration-zipcode">
            CEP
          </label>
          <input
            id="input-company-registration-zipcode"
            #zipCodeRef
            matInput
            mask="00000-000"
            name="zipcode"
            class="form-control input-data w-100"
            formControlName="zipcode"
            placeholder="Digite o CEP"
            required
            (blur)="consultaCEP()"
            [ngClass]="{
                  'is-invalid':
                  generalForm.get('zipcode')?.invalid &&
                  generalForm.get('zipcode')?.touched,
                  'is-valid':
                  generalForm.get('zipcode')?.value?.length > 0 &&
                  generalForm.get('zipcode')?.touched || generalForm.get('zipcode')?.value?.length > 0,
                }"
          />
          <div
            *ngIf="
              generalForm.get('zipcode')?.invalid &&
              generalForm.get('zipcode')?.touched &&
              generalForm.get('zipcode').value.length === 0
            "
            class="invalid-feedback label-mandatory"
            id="invalid-feedback-company-registration-zipcode-required"
          >
            Campo obrigatório*
          </div>
          <div
            *ngIf="
              generalForm.get('zipcode')?.value?.length > 0 &&
              generalForm.get('zipcode')?.value?.length < 10 &&
              generalForm.get('zipcode')?.touched &&
              generalForm.get('zipcode')?.errors
            "
            class="invalid-feedback label-mandatory"
            id="invalid-feedback-company-registration-zipcode-invalid"
          >
            CEP inválido!
          </div>
        </div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between" class="mb-2">
      <div fxLayout="column" fxFlex class="right">
        <div class="form-group">
          <label class="label-input form-label required" for="province" id="label-company-registration-uf">
            UF
          </label>
          <ng-select
            id="input-company-registration-uf"
            #provinceRef
            name="province"
            formControlName="province"
            [items]="UFs"
            [closeOnSelect]="true"
            [searchable]="true"
            [clearable]="false"
            bindLabel="label"
            bindValue="value"
            required
            [ngClass]="{
              'invalid is-invalid':
                generalForm.get('province')?.invalid &&
                generalForm.get('province')?.touched &&
                generalForm.get('province')?.value.length === 0,
              'valid is-valid':
                generalForm.get('province')?.value?.length > 0 &&
                generalForm.get('province')?.touched ||
                generalForm.get('province')?.value?.length > 0
            }"
          >
            <ng-template
              ng-label-tmp
              let-item="item"
              let-index="index"
              let-search="searchTerm"
            >
              {{ item.value }}
            </ng-template>
          </ng-select>
          <div
            *ngIf="
              generalForm.get('province')?.invalid &&
              generalForm.get('province')?.touched &&
              generalForm.get('province')?.value.length === 0
            "
            class="invalid-feedback label-mandatory"
            id="invalid-feedback-company-registration-uf"
          >
            Campo obrigatório*
          </div>
        </div>
      </div>
      <div fxLayout="column" fxFlex class="left">
        <div class="form-group">
          <label class="label-input form-label required" for="city" id="label-company-registration-city">
            Cidade
          </label>
          <input
            id="input-company-registration-city"
            #cityRef
            matInput
            name="city"
            class="form-control input-data w-100"
            formControlName="city"
            placeholder="Digite o nome da cidade"
            required
            [ngClass]="{
              'is-invalid':
                generalForm.get('city')?.invalid &&
                generalForm.get('city')?.touched &&
                generalForm.get('city')?.value === '',
              'is-valid':
                generalForm.get('city')?.value !== '' &&
                generalForm.get('city')?.touched ||
                generalForm.get('city')?.value !== ''
            }"
          />
          <div
            *ngIf="
              generalForm.get('city')?.invalid &&
              generalForm.get('city')?.touched &&
              generalForm.get('city')?.value === ''
            "
            class="invalid-feedback label-mandatory"
            id="invalid-feedback-company-registration-city"
          >
            Campo obrigatório*
          </div>
        </div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between" class="mb-3">
      <div fxLayout="column" fxFlex class="right">
        <div class="form-group">
          <label class="label-input form-label required" for="street" id="label-company-registration-street">
            Logradouro
          </label>
          <input
            id="input-company-registration-street"
            #streetRef
            matInput
            name="street"
            class="form-control input-data w-100"
            formControlName="street"
            placeholder="Digite o logradouro"
            required
            [ngClass]="{
              'is-invalid':
                generalForm.get('street')?.invalid &&
                generalForm.get('street')?.touched &&
                generalForm.get('street')?.value === '',
              'is-valid':
                generalForm.get('street')?.value !== ''&&
                generalForm.get('street')?.touched ||
                generalForm.get('street')?.value !== ''
            }"
          />
          <div
            *ngIf="
              generalForm.get('street')?.invalid &&
              generalForm.get('street')?.touched &&
              generalForm.get('street')?.value === ''
            "
            class="invalid-feedback label-mandatory"
            id="invalid-feedback-company-registration-street"
          >
            Campo obrigatório*
          </div>
        </div>
      </div>
      <div fxLayout="column" fxFlex class="left">
        <div class="form-group">
          <label class="label-input form-label required" for="number" id="label-company-registration-number">
            Número
          </label>
          <input
            id="input-company-registration-number"
            #numberRef
            matInput
            name="number"
            class="form-control input-data w-100"
            formControlName="number"
            placeholder="Digite o número"
            required
            [ngClass]="{
              'is-invalid':
                generalForm.get('number')?.invalid &&
                generalForm.get('number')?.touched &&
                generalForm.get('number')?.value === '',
              'is-valid':
                generalForm.get('number')?.value !== '' &&
                generalForm.get('number')?.touched ||
                generalForm.get('number')?.value !== ''
            }"
          />
          <div
            *ngIf="
              generalForm.get('number')?.invalid &&
              generalForm.get('number')?.touched &&
              generalForm.get('number')?.value === ''
            "
            class="invalid-feedback label-mandatory"
            id="invalid-feedback-company-registration-number"
          >
            Campo obrigatório*
          </div>
        </div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between" class="mb-3">
      <div fxLayout="column" fxFlex class="right">
        <div class="form-group">
          <label class="label-input form-label required" for="neighborhood" id="label-company-registration-neighborhood">
            Bairro
          </label>
          <input
            id="input-company-registration-neighborhood"
            #neighborhoodRef
            matInput
            name="neighborhood"
            class="form-control input-data w-100"
            formControlName="neighborhood"
            placeholder="Digite o bairro"
            required
            [ngClass]="{
              'is-invalid':
                generalForm.get('neighborhood')?.invalid &&
                generalForm.get('neighborhood')?.touched &&
                generalForm.get('neighborhood')?.value === '',
              'is-valid':
                generalForm.get('neighborhood')?.value !== '' &&
                generalForm.get('neighborhood')?.touched ||
                generalForm.get('neighborhood')?.value !== ''
            }"
          />
          <div
            *ngIf="
              generalForm.get('neighborhood')?.invalid &&
              generalForm.get('neighborhood')?.touched &&
              generalForm.get('neighborhood')?.value === ''
            "
            class="invalid-feedback label-mandatory"
            id="invalid-feedback-company-registration-neighborhood"
          >
            Campo obrigatório*
          </div>
        </div>
      </div>
      <div fxLayout="column" fxFlex class="left">
        <div class="form-group">
          <label class="label-input form-label" for="complement" id="label-company-registration-complement">
            Complemento
          </label>
          <input
            id="input-company-registration-complement"
            matInput
            name="complement"
            class="form-control input-data w-100"
            formControlName="complement"
            placeholder="Digite um complemento"
            [ngClass]="{
              'is-valid':
                generalForm.get('complement')?.value?.length > 0 &&
                generalForm.get('complement')?.touched ||
                generalForm.get('complement')?.value?.length > 0
            }"
          />
        </div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between" class="mb-3">
      <div fxLayout="column" fxFlex class="right">
        <div class="form-group">
          <label class="label-input form-label" for="insurance" id="label-company-registration-insurance">
            Número da apólice de seguro
          </label>
          <input
            id="input-company-registration-insurance"
            matInput
            name="insurance"
            class="form-control input-data w-100"
            formControlName="insurance"
            placeholder="Digite o número da apólice de seguro"
            [ngClass]="{
              'is-valid':
                generalForm.get('insurance')?.value?.length > 0 &&
                generalForm.get('insurance')?.touched ||
                generalForm.get('insurance')?.value?.length > 0
            }"
          />
        </div>
      </div>
      <div fxLayout="column" fxFlex class="left">
        <div class="form-group">
          <label for="patrimonialCost" class="label-input form-label" id="label-company-registration-patrimonial-cost">
            Valor Patrimonial
          </label>
          <div class="input-group">
            <span class="input-group-text input-data" id="patrimonialCost"
              >R$</span
            >
            <input
              type="text"
              class="form-control input-data"
              placeholder="000,00"
              aria-describedby="patrimonialCost"
              mask="separator.2"
              thousandSeparator="."
              id="patrimonialCost"
              name="patrimonialCost"
              formControlName="patrimonialCost"
              [ngClass]="{
                'is-valid':
                generalForm.get('patrimonialCost')?.value?.length > 0 &&
                  generalForm.get('patrimonialCost')?.touched || generalForm.get('patrimonialCost')?.value?.length > 0
              }"
            />
          </div>
        </div>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between" class="mb-3">
      <div fxLayout="column" fxFlex class="right">
        <div class="form-group">
          <label class="label-input form-label right" for="" id="label-company-registration-has-tracker">
            Veículos com rastreador
          </label>
          <div class="form-check form-check-inline">
            <input
              id="hasTrackerYes"
              class="form-check-input radio"
              name="hasTracker"
              formControlName="hasTracker"
              type="radio"
              value="true"
            />
            <label class="form-check-label" for="hasTrackerYes" id="label-has-tracker-yes"> Sim </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              id="hasTrackerNo"
              class="form-check-input radio"
              name="hasTracker"
              formControlName="hasTracker"
              type="radio"
              value="false"
            />
            <label class="form-check-label" for="hasTrackerNo" id="label-has-tracker-no"> Não </label>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row-reverse">
      <button
        mat-flat-button
        class="btn-green"
        type="submit"
        [disabled]="generalForm.invalid"
        (click)="submitForm()"
        id="btn-save-company"
      >
        Salvar
      </button>
    </div>
  </form>
</div>

<div class="loading-overlay" *ngIf="loadingSpinner">
  <mat-spinner class="spinner"></mat-spinner>
</div>