<mat-card class="card-top card-container" [ngClass]="{'opacity': loadingSpinner,'container-opacity': loadingSpinner}">
  <mat-card-content class="padding-card-body">
    <form action="" [formGroup]="profileForm">

      <h6 class="mt-2 title">Meu perfil</h6>

      <div fxLayout="column" class="right" class="mt-3">
        <div style="display: flex; position: relative;">
          <img
            [src]="perfilImage"
            alt="Indisponível"
            id="logo"
            width="88px"
            height="88px"
            [ngClass]="'logo'"
          />
          <label
            id="btn-update-image-shipper"
            for="fileInput"
            class="btn-photo"
            [ngbTooltip]="'Somente imagens com tamanho de até 400 x 400 serão aceitas'"
          >
            <i class="ri-camera-fill icon-photo"></i>
          </label>
  
          <input
            type="file"
            id="fileInput"
            accept="image/jpeg, image/png, image/jpg"
            name="logo"
            (change)="updateFile($event)"
            hidden
          />
        </div>
      </div>

      <div style="margin-top: 2rem; margin-bottom: -1rem;">
        <h6 class="user-data">Dados do usuário</h6>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column" fxFlex>
          <div class="form-group mt-4">
            <label for="" class="label-input form-label required" id="label-my-profile-shipper-user">
              Nome de usuário
            </label>
            <input
              type="text"
              id="input-my-profile-shipper-user"
              class="input-data form-control"
              placeholder="Usuário"
              required
              name="user"
              formControlName="user"
              [ngClass]="{
                'is-invalid':
                  profileForm.get('user')?.invalid &&
                  profileForm.get('user')?.touched,
                'is-valid':
                  (profileForm.get('user')?.valid &&
                    profileForm.get('user')?.touched) ||
                  (profileForm.get('user')?.valid &&
                    profileForm.get('user')?.value !== '')
              }"
            />
            <div
              *ngIf="
                (profileForm.get('user')?.errors &&
                  profileForm.get('user')?.touched) ||
                profileForm.get('user')?.dirty
              "
              class="invalid-feedback label-mandatory"
              id="invalid-feedback-my-profile-shipper-user"
            >
              Campo obrigatório*
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-between" class="mt-3">
        <div fxLayout="column" fxFlex class="column-email">
          <div class="form-group">
            <label for="" class="label-input form-label required" id="label-my-profile-shipper-email">
              E-mail
            </label>
            <input
              readonly
              type="email"
              id="input-my-profile-shipper-email"
              class="input-data form-control"
              placeholder="example@senior.com.br"
              required
              name="email"
              formControlName="email"
              style="background-color: #f7f4f4;"
            />
          </div>
        </div>
        <div fxLayout="column" fxFlex class="column-phone">
          <div class="form-group">
            <label for="" class="label-input form-label required" id="label-my-profile-shipper-phone">
              Telefone
            </label>
            <input
              mask="(00) 0000-0000||(00) 00000-0000"
              id="input-my-profile-shipper-phone"
              class="input-data form-control"
              placeholder="(xx) xxxx-xxxx"
              required
              name="phone"
              formControlName="phone"
              [ngClass]="{
                'is-invalid':
                profileForm.get('phone')?.invalid &&
                profileForm.get('phone')?.touched,
                'is-valid':
                profileForm.get('phone')?.valid &&
                profileForm.get('phone')?.touched || profileForm.get('phone')?.valid && profileForm.get('phone')?.value !== '',
              }"
            />
            <div
              *ngIf="
                profileForm.get('phone')?.value.length === 0 &&
                profileForm.get('phone')?.touched &&
                profileForm.get('phone')?.invalid
              "
              class="invalid-feedback label-mandatory"
              id="invalid-feedback-my-profile-shipper-phone"
            >
              Campo obrigatório*
            </div>
            <div
              *ngIf="
                profileForm.get('phone')?.errors &&
                profileForm.get('phone')?.value.length > 0 &&
                profileForm.get('phone')?.dirty
              "
              class="invalid-feedback label-mandatory"
            >
              Telefone inválido!*
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex flex-row mt-4">
        <div>
          <button
            (click)="changePassword()"
            mat-flat-button
            class="btn-grey"
            id="btn-change-password-shipper"
          >
            <i class="ri-lock-password-fill"></i>
            Alterar senha
          </button>
        </div>
      </div>

      <div class="d-flex flex-row-reverse mt-3">
        <div>
          <button
            mat-flat-button
            class="btn-green"
            (click)="submitForm()"
            id="btn-save-shipper"
          >
            Salvar
          </button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>

<div class="mt-4">
  <mat-card class="container-shipper card-container">
    <mat-card-content>
      <h6 class="mt-2">Embarcadores associados</h6>
      <div class="mat-table-container">
        <table mat-table [dataSource]="dataSource" class="mat-table mt-4">
          <caption hidden>
            Tabela convites
          </caption>
          <ng-container matColumnDef="corporateName">
            <th mat-header-cell *matHeaderCellDef class="table-head-color" id="header-table-my-profile-corporateName">
              {{ headerTable.lbCorporateName }}
            </th>
            <td mat-cell *matCellDef="let element" class="table-row">
              {{ element.corporateName }}
            </td>
          </ng-container>

          <ng-container matColumnDef="cnpj">
            <th mat-header-cell *matHeaderCellDef class="table-head-color" id="header-table-my-profile-cnpj">
              {{ headerTable.lbCnpj }}
            </th>
            <td mat-cell *matCellDef="let element" class="table-row">
              {{ element.cnpj }}
            </td>
          </ng-container>

          <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef class="table-head-color" id="header-table-my-profile-address">
              {{ headerTable.lbAddress }}
            </th>
            <td mat-cell *matCellDef="let element" class="table-row">
              {{ element.address }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index" id="row-table-my-profile-shipper-{{i}}"></tr>
        </table>
      </div>
      <mat-paginator
        [pageSizeOptions]="[5, 10, 20, 50, 100]"
        showFirstLastButtons
        aria-label="Select page of periodic elements"
        (page)="handlePageEvent($event)"
        [length]="totalElements"
        [pageIndex]="page"
        [pageSize]="size"
      >
      </mat-paginator>
    </mat-card-content>
  </mat-card>
</div>

<div class="loading-overlay" *ngIf="loadingSpinner">
  <mat-spinner class="spinner"></mat-spinner>
</div>