import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import {  first } from 'rxjs/operators';
import { SnackbarDefaultService } from 'src/app/core/services/utils/snackbar-default.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { UserService } from 'src/app/core/services/shared/user.service';
import { CompaniesService } from 'src/app/core/services/shared/companies.service';

@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.scss'],
})
export class UserRegistrationComponent implements OnInit {
  public userForm: FormGroup = this.formBuilder.group({
    id: [''],
    user: ['', Validators.required],
    email: ['', Validators.compose([Validators.required, Validators.email])],
    phone: ['', Validators.required],
    alcada: ['', Validators.required],
    userType: ['', Validators.required],
    password: ['', Validators.required],
    repitaSenha: ['', Validators.required],
    carriersByUser: ['', Validators.required],
  });

  public usuario = JSON.parse(localStorage.getItem('usuario'));
  id: any;
  options: any[] = [];
  validEmail = false;
  typePassword = 'password';
  typeRepitPassword = 'password';
  empresaId:any;
  carriersByUser: any[] = [];
  page = 0;
  size = 50;
  totalElements = 0;
  loadingSpinner = false;
  hidden = true

  emptyPassword = true;

  validLength = false;
  validNumber = false;
  validSpecialCharacter = false;
  validUpper = false;
  validLower = false;

  confirmPasswordValue = false;

  @ViewChild('userRef') userRef: ElementRef;
  @ViewChild('emailRef') emailRef: ElementRef;
  @ViewChild('phoneRef') phoneRef: ElementRef;
  @ViewChild('alcadaRef') alcadaRef: ElementRef;
  @ViewChild('userTypeRef') userTypeRef: NgSelectComponent;
  @ViewChild('passwordRef') passwordRef: ElementRef;
  @ViewChild('repitaSenhaRef') repitaSenhaRef: ElementRef;
  @ViewChild('carriersByUserRef') carriersByUserRef: NgSelectComponent;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly userService: UserService,
    private readonly companiesService: CompaniesService,
    private readonly route: ActivatedRoute,
    private readonly _snackBar: SnackbarDefaultService,
    private readonly router: Router
  ) {}

  ngOnInit() {
    this.getTipoUsuarios();
    this.id = this.route.snapshot.params['id'];
    
    if (this.id != undefined && this.id != null) {
      this.getGestaoMeuUsuario();
      this.getUsuario();
    }

    this.getCarriers();

    this.userForm
    .get('password')
    ?.valueChanges.subscribe((value) => this.validFormatPassword(value));
  }

  getGestaoMeuUsuario(){
    const size = this.size;
    const page = this.page;
    const usuario = this.route.snapshot.params['id'];
    const paramsObj = {
      page,
      size,
      usuario,
    };

    this.userService
    .getUserManagementById(paramsObj, usuario)
    .pipe(first())
    .subscribe({
      next: (result: any) => {
        const relatedCarriersIds = result.content?.map((carrier: any) => carrier.empresa.id);
        this.userForm.get('carriersByUser').setValue(relatedCarriersIds);
        this.empresaId = result.content[0].empresa.id
      },
      error: (error) => {
        console.error(error);
      },
    })
  }

  getCarriers() {
    this.companiesService
      .getCompanies()
      .pipe(first())
      .subscribe({
        next: (result: any) => {
          this.carriersByUser = result.content.map((dado: any) => ({
            label: dado.razaoSocial,
            value: dado.id,
            id: dado.id,
          }));
        },
        error: (error) => {
          console.error(error);
        },
      })
  }

  getTipoUsuarios() {
    const paginatorObj = {
      id: this.id,
      page: 0,
      size: 50,
    };

    this.userService
      .getUserType(paginatorObj)
      .pipe(first())
      .subscribe({
        next: (result: any) => {
          result.content.forEach((element: { tipo: any; id: any }) => {
            this.options.push({
              label: this.typeUser(element.tipo),
              value: element.id,
            });
          });
        },
        error: (error) => {
          console.error(error);
        },
      })
  }

  public getUsuario() {
    const paginatorObj = {
      id: this.id,
    };

    this.loadingSpinner = true;

    this.userService
      .getUser(paginatorObj)
      .pipe(first())
      .subscribe({
        next: (result: any) => {
          this.userForm.get('id').setValue(result.id);
          this.userForm.get('user').setValue(result.nome);
          this.userForm.get('email').setValue(result.email);
          this.userForm.get('userType').setValue(result.tipoUsuario.id);
          this.userForm.get('phone').setValue(result.telefone);
          this.userForm.get('alcada').setValue(result.alcada);
          this.userForm.get('repitaSenha').clearValidators();
          this.userForm.get('repitaSenha').updateValueAndValidity();
          this.userForm.get('password').clearValidators();
          this.userForm.get('password').updateValueAndValidity();
          this.loadingSpinner = false;
        },
        error: (error) => {
          console.error(error);
          this.loadingSpinner = false;
        },
      })
  }

  public checkEmail() {
    this.validEmail = false;
    const email = this.userForm.get('email')?.value;

    if (email.match(/[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$/)) {
      this.validEmail = true;
    } else {
      this.validEmail = false;
    }
    return this.validEmail;
  }

  validConfirmPassword() {
    const password = this.userForm.get('password')?.value;
    const confirmPassword = this.userForm.get('repitaSenha')?.value;

    if ((confirmPassword !== '' && password !== '') && confirmPassword !== password) {
      this.hidden = false
      this.confirmPasswordValue = false;
    } else if(password == '' || confirmPassword == '' ) {
      this.hidden = true
    }else if(confirmPassword == password) {
      this.confirmPasswordValue = true;
      this.hidden = true
    }

  }

  visiblePassword(event: any, elementId: string) {
    const { target } = event;
    const { checked } = target;
    const element = document.getElementById(elementId);
    element?.setAttribute('type', checked ? 'text' : 'password');
  }

  public validFormatPassword(password: string) {
    const confirmPassword = this.userForm.get('repitaSenha')?.value
    if (password == null || password === '') {
      this.validLength = false;
      this.validNumber = false;
      this.validSpecialCharacter = false;
      this.validUpper = false;
      this.validLower = false;
      return;
    }

    this.emptyPassword = false;

    this.validLength = password.length >= 8 && password.length <= 30;
    this.validNumber = !!RegExp(/\d/).exec(password);
    this.validSpecialCharacter = !!password.match(/[^a-zA-Z 0-9]+/g);
    this.validUpper = !!RegExp(/[A-Z]+/).exec(password);
    this.validLower = !!RegExp(/[a-z]+/).exec(password);

    if(password != null && confirmPassword != null){
      if(password == confirmPassword) this.confirmPasswordValue = true
    }
  }

  public submitForm() {
    this.userForm.markAllAsTouched();
    if (
      this.userForm.get('password')?.value !==
      this.userForm.get('repitaSenha')?.value
    ) {
      this._snackBar.openSnackBarWarning('As senhas não coincidem');
      return;
    }

    if(this.userForm.invalid){
      this._snackBar.openSnackBarWarning('Há campos que necessitam de atenção!')
      if(this.userForm.controls['user'].invalid){
        this.userRef.nativeElement.focus();
      }else if(this.userForm.controls['email'].invalid){
        this.emailRef.nativeElement.focus();
      } else if(this.userForm.controls['phone'].invalid){
        this.phoneRef.nativeElement.focus();
      } else if(this.userForm.controls['alcada'].invalid){
        this.alcadaRef.nativeElement.focus();
      } else if(this.userForm.controls['userType'].invalid){
        this.userTypeRef.focus();
      } else if(this.userForm.controls['password'].invalid){
        this.passwordRef.nativeElement.focus();
      } else if(this.userForm.controls['repitaSenha'].invalid){
        this.repitaSenhaRef.nativeElement.focus();
      } else if(this.userForm.controls['carriersByUser'].invalid){
        this.carriersByUserRef.focus();
      }
      return;
    }

    const usuario = {
      nome: this.userForm.get('user')?.value,
      email: this.userForm.get('email')?.value,
      senha: this.userForm.get('password')?.value,
      alcada: this.userForm.get('alcada')?.value,
      telefone: this.userForm.get('phone')?.value.replace(/[()-]/g, ''),
      clienteId: this.usuario.cliente.id,
      tipoUsuario: this.userForm.get('userType')?.value,
      id: this.id ? this.id : null
    };
    if(this.id){
      this.userService
      .updateUser(usuario)
      .pipe(first())
      .subscribe({
        next: () => {
          const management = {
            empresa: this.userForm.get('carriersByUser')?.value,
            usuario: this.id,
          };
          this.criaGestao(management, false)
        },
        error: (error) => {
          console.error(error);
          this._snackBar.openSnackBarError(
            'Ocorreu um erro'
          );
        }
    });
    }else{
       this.userService
      .createUser(usuario)
      .pipe(first())
      .subscribe({
        next: (result:any) => {
          const management = {
            empresa: this.userForm.get('carriersByUser')?.value,
            usuario: result.id,
          };
          this.criaGestao(management, true)
        },
        error: (error) => {
          console.error(error);
          this._snackBar.openSnackBarError(
            'Um usuário com esse email já existe'
          );
        }
    });
    }

  }

  public criaGestao(management: any, novo:boolean) {
    this.userService
      .createUserManagement(management)
      .pipe(first())
      .subscribe({
       next: () => {
          this.redirect();
          if(novo){
            this._snackBar.openSnackBarSuccess('Usuário cadastrado com sucesso');
          }
          if(!novo){
            this._snackBar.openSnackBarSuccess('Usuário alterado com sucesso');
          }
        },
        error: (error) => {
          console.error(error);
          this._snackBar.openSnackBarError(
            'Erro ao incluir gestao'
          );
        }
  });
  }

  public typeUser(typeUser: string) {
    if (typeUser == 'admin') return 'admin';
    return 'padrão';
  }

  public redirect() {
    setTimeout(() => {
      this.router
        .navigate(['configurations-carrier/users'])
        .catch((err) => err);
    }, 2000);
  }

  showPassword(event: any) {
    if (event.target.checked) {
      this.typePassword = 'text';
    } else {
      this.typePassword = 'password';
    }
  }

  showRepitPassword(event: any) {
    if (event.target.checked) {
      this.typeRepitPassword = 'text';
    } else {
      this.typeRepitPassword = 'password';
    }
  }

  selectAll() {
    const allValues = this.carriersByUser.map(carrier => carrier.value);
    this.userForm.get('carriersByUser')?.setValue(allValues);
  }

  deselectAll() {
    this.userForm.get('carriersByUser')?.setValue([]);
  }

  isSelected(item: any): boolean {
    return this.userForm.get('carriersByUser')?.value.includes(item.value);
  }
}
