import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';

import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { SnackbarDefaultService } from 'src/app/core/services/utils/snackbar-default.service';
import { RedefinePasswordDialogComponent } from 'src/app/shared/components/redefine-password-dialog/redefine-password-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { ImageService } from 'src/app/core/services/utils/image.service';
import { UserService } from 'src/app/core/services/shared/user.service';
import { first } from 'rxjs';
export interface PeriodicElement {
  id: string;
  corporateName: string;
  cnpj: string;
  address: string;
}

export interface HeaderTable {
  lbCorporateName: string;
  lbCnpj: string;
  lbAddress: string;
}

const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss'],
})
export class MyProfileComponent implements OnInit, AfterViewInit {
  public profileForm: FormGroup = this.formBuilder.group({
    user: ['', Validators.required],
    email: ['', Validators.compose([Validators.required, Validators.email])],
    phone: ['', Validators.required],
  });

  displayedColumns: string[] = ['corporateName', 'cnpj', 'city'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  imgTemp:string;
  page = 0;
  size = 50;
  totalElements = 0;
  loadingContent = false;
  filtro: any = {};
  loaded = false;
  usuario: any;
  loadingSpinner = false;

  perfilImage: string = 'assets/img/png/gray-user-profile-icon.png';
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  headerTable: HeaderTable = {
    lbCorporateName: 'Razão Social',
    lbCnpj: 'CNPJ',
    lbAddress: 'Endereço',
  };

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly userService: UserService,
    private readonly snackBar: SnackbarDefaultService,
    private readonly dialog: MatDialog,
    private readonly imageService: ImageService
  ) {}

  ngOnInit(): void {
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
    this.profileForm.get('user').setValue(this.usuario.nome);
    this.profileForm.get('email').setValue(this.usuario.email);
    this.profileForm.get('phone').setValue(this.usuario.telefone);

    this.getTransportadoras();

    if (this.usuario.imagem === undefined || this.usuario.imagem === null) {
      this.perfilImage = 'assets/img/svg/abstract-user-flat-grey.svg';
    } else {
      this.perfilImage = this.usuario.imagem?.includes(environment.AWS_S3_ASSETS) ? this.usuario.imagem : environment.AWS_S3_ASSETS + this.usuario.imagem;
    }
  }

  public submitForm() {
    const perfil = {
      id: this.usuario.id,
      nome: this.profileForm.get('user')?.value,
      telefone: this.profileForm.get('phone')?.value,
    };

    this.loadingSpinner = true;

    this.userService
      .updateUser(perfil)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          if(data){
            this.userService
            .getUser(perfil)
            .pipe(first())
            .subscribe({
              next: (data: any) => {
                this.usuario.nome = data.nome;
                this.usuario.telefone = data.telefone;
                this.usuario.imagem = this.imgTemp || null;
                localStorage.setItem('usuario', JSON.stringify(this.usuario));
                this.snackBar.openSnackBarSuccess('Dados alterados com sucesso');
                this.loadingSpinner = false;
              },
              error: (err) => {
                console.error('Erro ao atualizar os dados do usuário:', err);
                this.snackBar.openSnackBarError(
                  'Erro ao atualizar os dados do usuário. Tente novamente.'
                );
                this.loadingSpinner = false
              },
            })
          }
        },
        error: (err) => {
          console.error('Erro ao atualizar os dados do usuário:', err);
          this.snackBar.openSnackBarError(
            'Erro ao atualizar os dados do usuário. Tente novamente.'
          );
          this.loadingSpinner = false
        },
      })
  }

  public getTransportadoras() {
    const paginatorOjb = {
      page: this.page,
      size: this.size,
    };

    const id = this.usuario.id;
    this.loadingSpinner = true;

    this.userService
      .getUserManagementById(paginatorOjb, id)
      .pipe(
      first())
      .subscribe({
        next: (result: any) => {
          const transportadoras: PeriodicElement[] = [];
          result.content?.forEach((element: any) => {
            const transportadora: PeriodicElement = {
              id: element.empresa.id,
              corporateName: element.empresa.razaoSocial.toUpperCase(),
              cnpj: element.empresa.cnpj,
              address: this.getAddress(element.empresa),
            };
            transportadoras.push(transportadora);
          });
          this.totalElements = transportadoras.length;
          this.dataSource = new MatTableDataSource(transportadoras);
          this.loaded = true;
          this.loadingSpinner = false;
        },
        error: (err) => {
          console.error('Erro ao buscar as transportadoras:', err);
          this.snackBar.openSnackBarError(
            'Erro ao buscar as transportadoras. Tente novamente.'
          );
          this.loadingSpinner = false;
        },
      })
  }

  public getAddress(address: any) {
    if (address && address.cidade && address.uf) {
      return `${address.cidade} - ${address.uf}`;
    } else {
      return ' - ';
    }
  }

  public handlePageEvent(e: PageEvent) {
    if (this.size != e.pageSize) {
      this.page = 0;
    } else {
      this.page = e.pageIndex;
    }
    this.size = e.pageSize;
    this.getTransportadoras();
  }

  changePassword() {
    this.dialog.open(RedefinePasswordDialogComponent);
  }

  updateFile(event: any): void {
    const fileList: FileList = event.target.files;

    if (!fileList || fileList.length === 0) {
      console.error('Nenhum arquivo foi selecionado.');
      return;
    }

    const file: File = fileList[0];
    const fileType: string = file.type;
    const fileSize: number = file.size;
    const maxSize: number = 5 * 1024 * 1024; // 5MB

    // Validação do tamanho e tipo do arquivo
    if (fileSize > maxSize) {
      console.error(
        'O arquivo selecionado é muito grande. Por favor, selecione um arquivo menor que 5MB.'
      );
      this.snackBar.openSnackBarError(
        'O arquivo selecionado é muito grande. Por favor, selecione um arquivo menor que 5MB.'
      );
      return;
    }

    if (fileType !== 'image/png' && fileType !== 'image/jpeg') {
      console.error('Por favor, selecione uma imagem no formato PNG ou JPEG.');
      this.snackBar.openSnackBarError(
        'Por favor, selecione uma imagem no formato PNG ou JPEG.'
      );
      return;
    }

    // Leitura do arquivo e envio para o serviço
    const reader = new FileReader();
    this.loadingSpinner = true;
    reader.onload = () => {
      this.imageService
        .uploadImagemPerfil(file)
        .pipe(first())
        .subscribe({
          next: (res) => {
            this.imgTemp = res.imagem;
            this.usuario.imagem = res.imagem;
            localStorage.setItem('usuario', JSON.stringify(this.usuario));
            this.perfilImage = environment.AWS_S3_ASSETS + res.imagem;
            this.snackBar.openSnackBarSuccess('Imagem alterada com sucesso.');
            this.loadingSpinner = false;
          },
          error: (err) => {
            console.error('Erro ao fazer upload da imagem:', err);
            this.snackBar.openSnackBarError(
              'Erro ao alterar a imagem. Tente novamente.'
            );
            this.loadingSpinner = false;
          },
        });
    };

    reader.onerror = () => {
      console.error('Erro ao ler o arquivo. Por favor, tente novamente.');
      this.snackBar.openSnackBarError(
        'Erro ao processar o arquivo. Tente novamente.'
      );
      this.loadingSpinner = false;
    };

    reader.readAsDataURL(file);
  }
}
