<mat-card class="container-details card-container" *ngIf="loaded; else isLoading" >
    <mat-card-content>
      <mat-card class="card-details">
        <mat-card-content>
          <h6>Informações</h6>
          <div *ngIf="supplier">
            <div fxLayout="row" fxLayoutAlign="start" class="mt-4">
              <div fxLayout="column" fxFlex="25">
                <p class="informations-title" id="label-supplier-details-name">
                  {{ nameColumns.lbName }}
                </p>
                <p class="informations-content">
                  {{ supplier.name }}
                </p>
              </div>
              <div fxLayout="column" fxFlex="25">
                <p class="informations-title" id="label-supplier-details-document">
                  {{ nameColumns.lbDocument }}
                </p>
                <p class="informations-content">
                  {{ supplier.document | cnpj }}
                </p>
              </div>
              <div fxLayout="column" fxFlex="25">
                <p class="informations-title" id="label-supplier-details-activity-sector">
                  {{ nameColumns.lbactivitySector }}
                </p>
                <p class="informations-content">
                  {{ supplier.activitySector }}
                </p>
              </div>
            </div>
  
            <div fxLayout="row" fxLayoutAlign="start" class="mt-2">
              <div fxLayout="column" fxFlex="25">
                <p class="informations-title" id="label-supplier-details-zipcode">
                  {{ nameColumns.lbZipcode }}
                </p>
                <p class="informations-content">
                  {{ supplier.address.zipcode ? (supplier.address.zipcode | cep) : 'Não informado' }}
                </p>
              </div>
              <div fxLayout="column" fxFlex="25">
                <p class="informations-title" id="label-supplier-details-province">
                  {{ nameColumns.lbProvince }}
                </p>
                <p class="informations-content">
                  {{ supplier.address.province }}
                </p>
              </div>
              <div fxLayout="column" fxFlex="25">
                <p class="informations-title" id="label-supplier-details-city">
                  {{ nameColumns.lbCity }}
                </p>
                <p class="informations-content">
                  {{ supplier.address.city }}
                </p>
              </div>
            </div>
  
            <div fxLayout="row" fxLayoutAlign="space-between" class="mt-2">
              <div fxLayout="column" fxFlex>
                <p class="informations-title" id="label-supplier-details-street">
                  {{ nameColumns.lbStreet }}
                </p>
                <p class="informations-content">
                  {{ supplier.address.street }}
                </p>
              </div>
              <div fxLayout="column" fxFlex>
                <p class="informations-title" id="label-supplier-details-number">
                  {{ nameColumns.lbnumber }}
                </p>
                <p class="informations-content">
                  {{ supplier.address.number }}
                </p>
              </div>
              <div fxLayout="column" fxFlex>
                <p class="informations-title" id="label-supplier-details-neighborhood">
                  {{ nameColumns.lbNeighborhood }}
                </p>
                <p class="informations-content">
                  {{ supplier.address.neighborhood }}
                </p>
              </div>
              <div fxLayout="column" fxFlex>
                <p class="informations-title" id="label-supplier-details-complement">
                  {{ nameColumns.lbComplement }}
                </p>
                <p
                  *ngIf="supplier.address.complement"
                  class="informations-content"
                >
                  {{ supplier.address.complement }}
                </p>
                <p
                  *ngIf="!supplier.address.complement"
                  class="informations-content"
                >
                  -
                </p>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </mat-card-content>
  </mat-card>

  <ng-template #isLoading>
    <mat-progress-bar mode="indeterminate" color="primary" style="margin-top: 120px"></mat-progress-bar>
  </ng-template>