import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class TrackingService {
    constructor(private http: HttpClient) { }

    getTrackingOrdemDeColeta(params: any) {
        return this.http
            .get<any>(`${environment.API_FILTER}/oferta-contrato/link-external/${params.empresaId}/${params.ofertaId}/${params.hash}`)
            .pipe(
                first(),
                map((dados) => {
                    console.log(dados);
                    return dados;
                })
            );
    }
    getTrackingOrdemDeColetaSpot(params: any) {
        return this.http
            .get<any>(`${environment.API_FILTER}/oferta/link-external/${params.empresaId}/${params.ofertaId}/${params.hash}`)
            .pipe(
                first(),
                map((dados) => {
                    console.log(dados);
                    return dados;
                })
            );
    }
}