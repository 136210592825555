import { Injectable } from '@angular/core';
import { first, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class ConfigurationContractOfferService {

  constructor(private http: HttpClient) { }

  saveConfigContractOffer(obj: any) {
    const usuario = JSON.parse(localStorage.getItem('usuario')!);

    obj.idCliente = usuario.cliente.id
    return this.http.post<any>(`${environment.API_BACK}/cliente/config-oferta-contrato`, obj).pipe(
      first(),
      map((dados) => {
        return dados;
      })
    );
  }

}
