<div fxLayout="row" class="container-1" fxLayoutAlign="center">
  <div fxLayout="row" FxlayoutAlign="space-between" class="row-shipper">
    <div fxLayout="column" fxFlex class="column-title column-text">
      <div>
        <p class="typer">Para embarcadores</p>
        <h1 class="title">
          Conecte, simplifique e transporte além dos limites.
        </h1>
        <p class="description">
          A Plataforma de Fretes oferece acesso a dados essenciais para a tomada
          de decisões estratégicas, simplificando o processo de seleção de
          transportadoras confiáveis.
        </p>
        <p class="description">
          Com monitoramento avançado e relatórios detalhados, você terá controle
          total sobre suas operações, garantindo eficiência e redução de custos.
        </p>
        <a
          mat-button
          color="primary"
          class="mt-4 register"
          href="/im-a-shipper#interest"
        >
          Quero conhecer
        </a>
      </div>
    </div>
    <div fxLayout="column" fxFlex class="img-truck">
      <div fxLayout="row" fxLayoutAlign="space-between">
        <img
          src="assets/landing/flip_embarcador.png"
          alt=""
          class="img-shipper"
        />
      </div>
    </div>
  </div>
</div>

<div fxLayout="row" class="container-white justify-content-center">
  <div fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="center" class="global-title mb-4">
      <div class="mb-4">
        Sou
        <strong>embarcador</strong>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center" class="global-subtitle mb-4">
      Potencialize a forma como você busca novos fretes.
    </div>

    <div *ngIf="!mobile; else step">
      <div fxLayout="row" fxLayoutAlign="center" class="row-step">
        <div fxLayout="column" fxFlex="31.5">
          <div class="stepper-item completed">
            <div class="products-icon text-white text-center mx-4 hexagon">
              <img
                src="assets/img/svg/oferta_step_emb.svg"
                alt=""
                class="step-icon"
              />
            </div>
            <div class="products-container text-center" style="width: 15rem">
              <p class="products-title">Oferte</p>
              <p class="products-description">
                Crie uma <strong>oferta</strong><br />
                para o seu pedido
              </p>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex="31.5">
          <div class="stepper-item completed">
            <div class="products-icon text-white text-center mx-4 hexagon">
              <img
                src="assets/img/svg/proposta_step_emb.svg"
                alt=""
                class="step-icon"
              />
            </div>
            <div class="products-container text-center" style="width: 15rem">
              <p class="products-title">Receba propostas</p>
              <p class="products-description">
                Selecione a<br />melhor <strong>proposta</strong>
              </p>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex="31.5">
          <div class="stepper-item completed">
            <div class="products-icon text-white text-center mx-4 hexagon">
              <img
                src="assets/img/svg/contrate_step_emb.svg"
                alt=""
                class="step-icon"
              />
            </div>
            <div class="products-container text-center" style="width: 15rem">
              <p class="products-title">Contrate</p>
              <p class="products-description">
                Aprove o menor<br /><strong>preço</strong>
              </p>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex="31.5">
          <div class="stepper-item completed">
            <div class="products-icon text-white text-center mx-4 hexagon">
              <img
                src="assets/img/svg/envie_step_emb.svg"
                alt=""
                class="step-icon"
              />
            </div>
            <div class="products-container text-center" style="width: 15rem">
              <p class="products-title">Envie</p>
              <p class="products-description">
                Realize o envio<br />do seu <strong>frete</strong>
              </p>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex="31.5">
          <div class="stepper-item">
            <div class="products-icon text-white text-center mx-4 hexagon">
              <img
                src="assets/img/svg/audite_step_emb.svg"
                alt=""
                class="step-icon"
              />
            </div>
            <div class="products-container text-center" style="width: 15rem">
              <p class="products-title">Audite</p>
              <p class="products-description">
                Audite os envios<br />de <strong>frete</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-2">
  <div id="title-benefits" class="global-title mb-4">
    <div>Conheça algumas <strong> vantagens</strong></div>
  </div>

  <div id="card-benefits" *ngIf="!mobile; else benefitsMobile">
    <mat-card class="card p-2 mx-2" fxLayout="column">
      <mat-card-content>
        <div class="step text-white text-center mx-1 hexagon">
          <img src="assets/img/svg/ecossistema_emb.svg" alt="" />
        </div>
        <div class="content mt-4">
          <p class="title">Ecossistema com as transportadoras</p>
          <p class="description">
            O embarcador pode escolher de forma eficiente e confiável as
            melhores parcerias de transporte para atender às suas necessidades e
            ampliar sua rede de contatos.
          </p>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="card p-2 mx-2" fxLayout="column">
      <mat-card-content>
        <div class="step text-white text-center mx-1 hexagon">
          <img src="assets/img/svg/compliance_emb.svg" alt="" />
        </div>
        <div class="content mt-4">
          <p class="title">Compliance na Gestão Logística</p>
          <p class="description">
            Com a integração de dados GKO Frete, o embarcador centraliza e
            integra todas as informações relevantes, reduzindo o tempo gasto na
            entrada de dados e obtendo visibilidade em tempo real.
          </p>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="card p-2 mx-2" fxLayout="column">
      <mat-card-content>
        <div class="step text-white text-center mx-1 hexagon">
          <img src="assets/img/svg/custos_emb.svg" alt="" />
        </div>
        <div class="content mt-4">
          <p class="title">IA para otimização de custos</p>
          <p class="description">
            Através da nossa sugestão de frete otimizada, nossa plataforma ajuda
            a selecionar as melhores ofertas em termos de custo-benefício,
            reduzindo os gastos com transporte.
          </p>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div fxLayout="row" class="container-grey">
  <div fxLayout="column">
    <div
      fxLayout="row"
      class="global-title mb-4 text-left justify-content-center "
    >
      <div class="mb-4 functionalities">
        Principais funcionalidades para
        <strong style="color: #00c89b">Embarcadores</strong>
      </div>
    </div>
    <div
      fxLayout="row"
      class="global-subtitle mb-4 text-left justify-content-center"
    >
      <div class="mb-4">
        Conecte, simplifique e transporte além dos limites!
      </div>
    </div>

    <div *ngIf="!mobile; else functionalitiesMobile">
      <div fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column" fxFlex="31.5">
          <mat-card class="card p-2 mx-2 container-functionalities">
            <mat-card-content>
              <div class="products-icon">
                <img src="assets/img/svg/integracao_emb.svg" alt="" />
              </div>
              <div class="products-container">
                <p class="products-title">Integração nativa</p>
                <p class="products-description">
                  Centralize e integre as informações relevantes de forma
                  segura, reduzindo o tempo gasto na entrada de dados e
                  proporcionando uma visibilidade em tempo real.
                </p>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <div fxLayout="column" fxFlex="31.5">
          <mat-card class="card p-2 mx-2 container-functionalities">
            <mat-card-content>
              <div class="products-icon">
                <img src="assets/img/svg/calculator_emb.svg" alt="" />
              </div>
              <div class="products-container">
                <p class="products-title">Sugestão de frete IA</p>
                <p class="products-description">
                  Otimize o processo de sugestão de frete, selecionando a melhor
                  data, garantindo o custo-benefício nas ofertas e reduzindo o
                  lead time de aprovação
                </p>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <div fxLayout="column" fxFlex="31.5">
          <mat-card class="card p-2 mx-2 container-functionalities">
            <mat-card-content>
              <div class="products-icon">
                <img src="assets/img/svg/chat_emb.svg" alt="" />
              </div>
              <div class="products-container">
                <p class="products-title">Chat</p>
                <p class="products-description">
                  Simplifique a negociação e o agendamento de entregas com um
                  chat integrado, agilizando as operações e garantindo uma
                  comunicação eficiente com as transportadoras.
                </p>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>

<div fxLayout="row" class="container-grey" fxLayoutAlign="center" id="interest">
  <div fxLayout="column" fxFlex="100">
    <div fxLayout="row" class="global-title mb-4">
      <div class="mb-4 text-center">
        Entre em contato com nossos consultores e<br />
        <strong>conheça a solução</strong>
      </div>
    </div>
    <div class="container-interest">
      <!-- Start of HubSpot Embed Code -->
      <script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="https://js.hs-scripts.com/20786608.js"
      ></script>
      <!-- End of HubSpot Embed Code -->
      <iframe
        id="frm_home"
        src="https://share.hsforms.com/1BjpZoUgTTWqbYnr8hCo4CQcdj1s"
        class="frame"
        title="Form home"
      >
      </iframe>
    </div>
  </div>
</div>

<ng-template #step>
  <div fxLayout="row" class="container-step">
    <mat-card class="card">
      <mat-card-content>
        <div class="step step-1 completed">
          <div class="v-stepper">
            <div class="line"></div>
          </div>

          <div class="content">
            <span class="products-title">Oferte</span>
            <p class="products-description">
              Crie uma <strong>oferta</strong><br />
              para o seu pedido
            </p>
          </div>
        </div>
        <div class="step step-2 completed">
          <div class="v-stepper">
            <div class="line"></div>
          </div>

          <div class="content">
            <span class="products-title">Receba propostas</span>
            <p class="products-description">
              Selecione a<br />melhor <strong>proposta</strong>
            </p>
          </div>
        </div>
        <div class="step step-3 completed">
          <div class="v-stepper">
            <div class="line"></div>
          </div>

          <div class="content">
            <span class="products-title">Contrate</span>
            <p class="products-description">
              Aprove o menor<br /><strong>preço</strong>
            </p>
          </div>
        </div>

        <div class="step step-4 completed">
          <div class="v-stepper">
            <div class="line"></div>
          </div>

          <div class="content">
            <span class="products-title">Envie</span>
            <p class="products-description">
              Realize o envio<br />do seu <strong>frete</strong>
            </p>
          </div>
        </div>

        <div class="step step-5 completed">
          <div class="v-stepper">
            <div class="line"></div>
          </div>

          <div class="content">
            <span class="products-title">Audite</span>
            <p class="products-description">
              Audite os envios<br />de <strong>frete</strong>
            </p>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</ng-template>

<ng-template #benefitsMobile>
  <div class="benefits-slide">
    <div class="slideshow-container">
      <div class="mySlidesBenefits fade">
        <mat-card class="card p-2 mx-2 top-benefits">
          <mat-card-content>
            <div class="step text-white text-center mx-1 hexagon">
              <img src="assets/img/svg/ecossistema_emb.svg" alt="" />
            </div>
            <div class="content-card">
              <p class="title">Ecossistema com as transportadoras</p>
              <p class="description">
                O embarcador pode escolher de forma eficiente e confiável as
                melhores parcerias de transporte para atender às suas
                necessidades e ampliar sua rede de contatos.
              </p>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="mySlidesBenefits fade">
        <mat-card class="card p-2 mx-2 top-benefits">
          <mat-card-content>
            <div class="step text-white text-center mx-1 hexagon">
              <img src="assets/img/svg/compliance_emb.svg" alt="" />
            </div>
            <div class="content-card">
              <p class="title">Compliance na Gestão Logística</p>
              <p class="description">
                Com a integração de dados GKO Frete, o embarcador centraliza e
                integra todas as informações relevantes, reduzindo o tempo gasto
                na entrada de dados e obtendo visibilidade em tempo real.
              </p>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="mySlidesBenefits fade">
        <mat-card class="card p-2 mx-2 top-benefits">
          <mat-card-content>
            <div class="step text-white text-center mx-1 hexagon">
              <img src="assets/img/svg/custos_emb.svg" alt="" />
            </div>
            <div class="content-card">
              <p class="title">IA para otimização de custos</p>
              <p class="description">
                Através da nossa sugestão de frete otimizada, nossa plataforma
                ajuda a selecionar as melhores ofertas em termos de
                custo-benefício, reduzindo os gastos com transporte.
              </p>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <button 
        class="prev" 
        (click)="plusSlides(-1, 'mySlidesBenefits')" 
        aria-label="Previous slide"
      >
        &#10094;
      </button>
      <button 
        class="next" 
        (click)="plusSlides(1, 'mySlidesBenefits')" 
        aria-label="Next slide"
      >
        &#10095;
      </button>
    </div>
    <br />

    <div style="text-align: center">
      <button
        class="dot dot-benefits"
        (click)="currentSlide(1, 'mySlidesBenefits')"
        aria-label="Go to slide 1"
      ></button>
      <button
        class="dot dot-benefits"
        (click)="currentSlide(2, 'mySlidesBenefits')"
        aria-label="Go to slide 2"
      ></button>
      <button
        class="dot dot-benefits"
        (click)="currentSlide(3, 'mySlidesBenefits')"
        aria-label="Go to slide 3"
      ></button>
    </div>
  </div>
</ng-template>

<ng-template #functionalitiesMobile>
  <div class="functionalities-slide">
    <div class="slideshow-container">
      <div class="mySlidesFunctionalities fade">
        <mat-card class="card p-2 mx-2 container-functionalities">
          <mat-card-content>
            <div class="products-icon">
              <img src="assets/img/svg/integracao_emb.svg" alt="" />
            </div>
            <div class="products-container">
              <p class="products-title">Integração nativa</p>
              <p class="products-description">
                Centralize e integre as informações relevantes de forma segura,
                reduzindo o tempo gasto na entrada de dados e proporcionando uma
                visibilidade em tempo real.
              </p>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="mySlidesFunctionalities fade">
        <mat-card class="card p-2 mx-2 container-functionalities">
          <mat-card-content>
            <div class="products-icon">
              <img src="assets/img/svg/calculator_emb.svg" alt="" />
            </div>
            <div class="products-container">
              <p class="products-title">Sugestão de frete IA</p>
              <p class="products-description">
                Otimize o processo de sugestão de frete, selecionando a melhor
                data, garantindo o custo-benefício nas ofertas e reduzindo o
                lead time de aprovação
              </p>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="mySlidesFunctionalities fade">
        <mat-card class="card p-2 mx-2 container-functionalities">
          <mat-card-content>
            <div class="products-icon">
              <img src="assets/img/svg/chat_emb.svg" alt="" />
            </div>
            <div class="products-container">
              <p class="products-title">Chat</p>
              <p class="products-description">
                Simplifique a negociação e o agendamento de entregas com um chat
                integrado, agilizando as operações e garantindo uma comunicação
                eficiente com as transportadoras.
              </p>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <a 
        class="prev" 
        tabindex="0" 
        (click)="plusSlides(-1, 'mySlidesFunctionalities')" 
        (keydown.enter)="plusSlides(-1, 'mySlidesFunctionalities')"
        (keydown.space)="plusSlides(-1, 'mySlidesFunctionalities')"
        aria-label="Previous slide"
      >
        &#10094;
      </a>
      <a 
        class="next" 
        tabindex="0" 
        (click)="plusSlides(1, 'mySlidesFunctionalities')" 
        (keydown.enter)="plusSlides(1, 'mySlidesFunctionalities')" 
        (keydown.space)="plusSlides(1, 'mySlidesFunctionalities')" 
        aria-label="Next slide"
      >
        &#10095;
      </a>
    </div>
    <br />

    <div style="text-align: center">
      <button
        class="dot dot-functionalities"
        tabindex="0"
        (click)="currentSlide(1, 'mySlidesFunctionalities')"
        (keydown.enter)="currentSlide(1, 'mySlidesFunctionalities')"
        (keydown.space)="currentSlide(1, 'mySlidesFunctionalities')"
        aria-label="Go to slide 1"
      >
      </button>
      <button
        class="dot dot-functionalities"
        tabindex="0"
        (click)="currentSlide(2, 'mySlidesFunctionalities')"
        (keydown.enter)="currentSlide(2, 'mySlidesFunctionalities')"
        (keydown.space)="currentSlide(2, 'mySlidesFunctionalities')"
        aria-label="Go to slide 2"
      >
      </button>
      <button
        class="dot dot-functionalities"
        tabindex="0"
        (click)="currentSlide(3, 'mySlidesFunctionalities')"
        (keydown.enter)="currentSlide(3, 'mySlidesFunctionalities')"
        (keydown.space)="currentSlide(3, 'mySlidesFunctionalities')"
        aria-label="Go to slide 3"
      >
      </button>
    </div>
  </div>
</ng-template>
