import { SatPopoverModule } from '@ncstate/sat-popover';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  DEFAULT_CURRENCY_CODE,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatStepperModule } from '@angular/material/stepper';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AuthenticationModule } from './core/authentication/authentication.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FlexLayoutModule } from '@angular/flex-layout';

import { LayoutModule } from './pages/layout/layout.module';

import { ConfigurationsCarrierModule } from './pages/configurations/carrier/configurations-carrier.module';
import { ConfigurationsShipperModule } from './pages/configurations/shipper/configurations-shipper.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { BasicAuthInterceptor } from './core/interceptors/basic-auth.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import ptBr from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { getPaginatorIntl } from './core/utils/paginator-translate';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgApexchartsModule } from 'ng-apexcharts';

registerLocaleData(ptBr);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AuthenticationModule,
    ConfigurationsCarrierModule,
    ConfigurationsShipperModule,
    MatFormFieldModule,
    MatButtonModule,
    MatStepperModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatSnackBarModule,
    FlexLayoutModule,
    LayoutModule,
    MatDialogModule,
    RouterModule,
    NgbModule,
    NgApexchartsModule,
    SatPopoverModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BasicAuthInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL',
    },
    { provide: MatPaginatorIntl, useValue: getPaginatorIntl() },
    {
      provide: MatDialogRef,
      useValue: {},
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
