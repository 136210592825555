import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Usuario } from 'src/app/core/models/authentication/usuario';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { first, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly tokenCookie = 'TOKEN_LEILAO_FRETES';

  routePath = '';

  constructor(
    private readonly cookieService: CookieService,
    private readonly router: Router,
    private readonly http: HttpClient,
  ) {}

  isLoggedIn() {
    return this.getToken() !== '';
  }

  getToken() {
    return this.cookieService.get(this.tokenCookie);
  }
  putToken(token: any) {
    localStorage.setItem('usuario-leilao', token);
    const data: Date = new Date();

    const optionsCookies = {
      expires: new Date(
        data.getFullYear(),
        data.getMonth(),
        data.getDate() + 1
      ),
      domain: environment.COOKIE_DOMAIN,
    };

    this.cookieService.set(this.tokenCookie, token, optionsCookies);
  }
  removeToken() {
    localStorage.removeItem('usuario');
    localStorage.removeItem('usuario-leilao');
    this.cookieService.delete(this.tokenCookie);
  }
  putUsuario(usuario: Usuario) {
    localStorage.setItem('usuario', JSON.stringify(usuario));
  }

  logout() {
    this.putToken('');
    this.removeToken();
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  forgotMyPassword(email: string, token: string) {
    const headers = new HttpHeaders();
    const obj = {
      email: email,
      tokenRecaptcha: token,
    };
    return this.http
      .post<any>(`${environment.API_LOGIN}/esqueci-minha-senha`, obj, {
        headers,
        responseType: 'text' as 'json',
      })
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }
}
