import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { carga_options } from 'src/app/core/models/consts';

@Component({
  selector: 'app-dialog-add-default-load',
  templateUrl: './dialog-add-default-load.component.html',
  styleUrls: ['./dialog-add-default-load.component.scss'],
})
export class DialogAddDefaultLoadComponent implements AfterViewInit {
  loadsType = carga_options;
  page = 0;
  size = 50;
  totalElements: number = this.loadsType.length;
  loadType: any;

  displayedColumns = ['select', 'loadType'];

  dataSource = new MatTableDataSource(this.loadsType);

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  constructor(
    private readonly dialogRef: MatDialogRef<DialogAddDefaultLoadComponent>
  ) {}

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  selectItem(item: any) {
    this.loadsType.forEach((i) => {
      if (i !== item) {
        i.selected = false; // Desmarca os outros itens
      }
    });
    item.selected = !item.selected; // Inverte a seleção do item atual
    this.loadType = item;
  }

  public handlePageEvent(e: PageEvent) {
    if (this.size != e.pageSize) {
      this.page = 0;
    } else {
      this.page = e.pageIndex;
    }
    this.size = e.pageSize;
  }

  close() {
    this.dialogRef.close();
  }

  confirm() {
    this.dialogRef.close(this.loadType);
    this.loadType.selected = false
  }
}
