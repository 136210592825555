import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'cep' })
export class CepPipe implements PipeTransform {
  transform(value: string | number): string {
    let valorFormatado = '';

    if (value) {
      valorFormatado = value.toString().replace(/(\d{5})(\d{3})/, '$1-$2');

      return valorFormatado;
    } else {
      return '';
    }
  }
}
