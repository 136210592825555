import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DialogConfirmComponent } from 'src/app/shared/components/dialog-confirm/dialog-confirm.component';
import { SnackbarDefaultService } from 'src/app/core/services/utils/snackbar-default.service';
import { DialogAttachmentComponent } from 'src/app/shared/components/dialog-attachment/dialog-attachment.component';
import { environment } from 'src/environments/environment';
import { first } from 'rxjs';
import { SuppliersService } from 'src/app/core/services/shared/supplier.service';

interface Supplier {
  name: string;
  id: number;
  document: string;
  address: Address;
}

interface Address {
  zipcode: string;
  province: string;
  city: string;
  street: string;
  number: string;
  neighborhood: string;
  complement: string;
}

@Component({
  selector: 'app-my-suppliers',
  templateUrl: './my-suppliers.component.html',
  styleUrls: ['./my-suppliers.component.scss']
})
export class MySuppliersComponent implements OnInit, AfterViewInit {
  options: string[] = ['Detalhar', 'Editar', 'Excluir'];

  displayedColumns: string[] = ['name', 'document', 'uf', 'actions'];

  loaded = true;

  headerTable = {
    lbName: 'Nome',
    lbDocument: 'CNPJ',
    lbUf: 'UF',
    lbActions: 'Ações',
  };

  page = 0;
  size = 50;
  totalElements = 0;
  loadingContent = false;

  routePath = '';
  newSupplier = '';
  editSupplier = '';
  detailsSupplier = '';

  optionsSupplier: string[] = ['Baixar modelo CSV', 'Importar CSV'];

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  public searchForm: FormGroup = this.formBuilder.group({
    search: [null],
  });

  listSuppliers: Supplier[] = [];

  dataSource = new MatTableDataSource(this.listSuppliers);

  constructor(
    private readonly _snackBar: SnackbarDefaultService,
    private readonly dialog: MatDialog,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly suppliersService: SuppliersService
  ) {}

  ngOnInit(): void {
    this.settingsRoutes();
    this.getSuppliers();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  redirect(action: string, row: Supplier) {
    if (action === 'Detalhar') {
      this.router.navigate([this.detailsSupplier, row.id]).catch((err) => err);
    } else if (action === 'Editar') {
      this.router.navigate([this.editSupplier, row.id]).catch((err) => err);
    } else {
      const dialogRef = this.dialog.open(DialogConfirmComponent, {
        data: {
          title: 'Confirmação',
          description: 'Tem certeza que deseja excluir este fornecedor?',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.deteleteSupplier(row.id);
        }
      });
    }
  }

  public handlePageEvent(e: PageEvent) {
    if (this.size != e.pageSize) {
      this.page = 0;
    } else {
      this.page = e.pageIndex;
    }
    this.size = e.pageSize;
    this.getSuppliers();
  }

  settingsRoutes() {
    this.routePath = window.location.pathname;
    this.newSupplier = `${this.routePath}/new-supplier`;
    this.editSupplier = `${this.routePath}/edit-supplier`;
    this.detailsSupplier = `${this.routePath}/supplier-details`;
  }

  submitForm() {
    const document = this.searchForm.get('search')?.value;
    let temp;

    if(document == '' || this.isNumber(document)){
      this.getSuppliers(document);
    } else{
      temp = document?.replace(/\D/g, '')
      this.searchForm.get('search')?.setValue(temp);
    }
  }

  isNumber(value: any): boolean {
    return /^\d+$/.test(value);
  }

  deteleteSupplier(id: number) {
    this.suppliersService
      .deteleteSupplier(id)
      .pipe(first())
      .subscribe({
        next: () => {
          this._snackBar.openSnackBarSuccess(
            'Fornecedor excluído com sucesso.'
          );
          this.getSuppliers();
        },
        error: () => {
          this._snackBar.openSnackBarError('Erro ao excluir fornecedor.');
        },
      });
  }

  getSuppliers(document?: string) {
    this.loadingContent = true;
    const size = this.size;
    const page = this.page;
    const paginatorObj = { size };

    if (document) {
      Object.assign(paginatorObj, { page: 0 });
      Object.assign(paginatorObj, { document: document });
    } else {
      Object.assign(paginatorObj, { page: page });
    }

    this.suppliersService
      .getSuppliers(paginatorObj)
      .pipe(first())
      .subscribe({
        next: (suppliers: any) => {
          this.listSuppliers = suppliers.content?.map((supplier: any) => ({
            id: supplier.id,
            name: supplier.razaoSocial,
            document: supplier.cnpj,
            address: {
              zipcode: supplier.cep,
              province: supplier.uf,
              city: supplier.cidade,
              street: supplier.logradouro,
              number: supplier.numero,
              neighborhood: supplier.bairro,
              complement: supplier.complemento || '',
            },
          }));
          this.totalElements = suppliers.totalElements;
          this.dataSource = new MatTableDataSource(this.listSuppliers);
          this.loadingContent = false;
        },
        error: (error) => {
          console.error(error);
          this.loadingContent = false;
        },
      });
  }

  redirectActionsBtnImport(action: string) {
    if (action === 'Baixar modelo CSV') {
      this.csvDownload();
    } else {
      this.openDialogAttachment();
    }
  }

  public csvDownload() {
    const element = document.createElement('a');
    element.setAttribute(
      'href',
      `https://plataformadefretes-csv.s3.amazonaws.com/${environment.AMBIENTE}/csv/exemplo/fornecedor.csv`
    );
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  openDialogAttachment() {
    const dialog = this.dialog.open(DialogAttachmentComponent, {
      data: {
        title: 'Importar fornecedores',
        type: 'supplierCSV',
        description:
          'Arquivos suportados: .csv ou .xlsx. Se .csv, o separador das células deve ser vírgula(,).',
      },
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        setTimeout(() => {
          this.getSuppliers();
        }, 2000);
      }
    });
  }

  redirectDetails(element: any){
    this.router.navigate([this.detailsSupplier, element.id]);
  }

}
