import { OfferAttachmentService } from './../../../core/services/offers/offer-attachment.service';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { first, lastValueFrom, take } from 'rxjs';
import { SnackbarDefaultService } from '../../../core/services/utils/snackbar-default.service';
import { InvitationsService } from 'src/app/core/services/shared/invitations.service';
import { OrderService } from 'src/app/core/services/shared/order.service';
import { CompaniesService } from 'src/app/core/services/shared/companies.service';
import { RecipientService } from 'src/app/core/services/shared/recipient.service';

@Component({
  selector: 'app-dialog-attachment',
  templateUrl: './dialog-attachment.component.html',
  styleUrls: ['./dialog-attachment.component.scss'],
})
export class DialogAttachmentComponent {
  files: File[] = [];
  allowedExtensionImage: string[] = [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/gif',
    'image/bmp',
  ];

  isImage = false;
  title = '';
  description = '';
  type = '';
  fileType: any;
  loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly _snackBar: SnackbarDefaultService,
    private readonly orderService: OrderService,
    private readonly companiesService: CompaniesService,
    private readonly recipientService: RecipientService,
    private readonly offerAttachmentService: OfferAttachmentService,
    private readonly dialogRef: MatDialogRef<DialogAttachmentComponent>,
    private readonly invitationsService: InvitationsService
  ) {
    this.title = this.data.title;
    this.description = this.data?.description;
    this.type = this.data?.type;
  }

  onSelect(event: any) {
    if (this.type != 'spot') {
      if (this.files.length > 0) {
        this.files.splice(this.files.indexOf(event), 1);
      }
    }

    if (this.type === 'orderCSV' || this.type === 'recipientCSV') {
      this.fileType = event.addedFiles[0].type;
      if (
        this.fileType === 'text/csv' ||
        this.fileType ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        this.files.push(...event.addedFiles);
      } else {
        this.files.splice(this.files.indexOf(event), 1);
        this._snackBar.openSnackBarError('Tipo de arquivo inválido!');
      }
    } else {
      this.files.push(...event.addedFiles);
    }

    for (const el of this.files) {
      this.isImage = this.allowedExtensionImage.includes(el.type);
    }
    return this.isImage;
  }

  public importFile() {
    this.loading = true;
    if (this.files.length > 0) {
      if (this.type == 'carrierAttachment') {
        this.importCarrierAttachment();
      } else if (this.type == 'orderCSV') {
        this.importOrderCSV();
      } else if (this.type == 'recipientCSV') {
        this.importRecipientCSV();
      } else if (this.type == 'companyCSV') {
        this.importCompanyCSV();
      } else {
        this.salvaAnexos();
      }
    } else {
      this._snackBar.openSnackBarWarning(
        'Não há arquivos para serem enviados.'
      );
    }
  }

  importRecipientCSV() {
    this.recipientService
      .importRecipientCsv(this.files[0])
      .pipe(take(1))
      .subscribe({
        next: (data) => {
          if (!data.sucesso) {
            this._snackBar.openSnackBarErrorWithDurationAndLog(
              'Foram encontrados problemas no arquivo enviado. Favor clique no link de log para verificar o problema:',
              50000000,
              `https://plataformadefretes-log.s3.amazonaws.com/${data.linkArquivoLog}`
            );
            this.dialogRef.close(true);
          } else {
            this._snackBar.openSnackBarSuccess('Arquivo importado com sucesso');
            this.loading = false;
            this.dialogRef.close(true);
          }
        },
        error: (error) => {
          console.error(error);
          this._snackBar.openSnackBarError('Erro ao importar arquivo');
          this.dialogRef.close(true);
        },
      });
  }

  importCompanyCSV() {
    this.companiesService
      .importCompanyCsv(this.files[0])
      .pipe(take(1))
      .subscribe({
        next: (data) => {
          if (!data.sucesso) {
            this._snackBar.openSnackBarErrorWithDurationAndLog(
              'Foram encontrados problemas no arquivo enviado. Favor clique no link de log para verificar o problema:',
              50000000,
              `https://plataformadefretes-log.s3.amazonaws.com/${data.linkArquivoLog}`
            );
            this.dialogRef.close(true);
          } else {
            this._snackBar.openSnackBarSuccess('Arquivo importado com sucesso');
            this.loading = false;
            this.dialogRef.close(true);
          }
        },
        error: (error) => {
          console.error(error);
          this._snackBar.openSnackBarError('Erro ao importar arquivo');
          this.dialogRef.close(true);
        },
      });
  }

  importOrderCSV() {
    this.orderService
      .importOrderCsv(this.files[0])
      .pipe(take(1))
      .subscribe({
        next: (data) => {
          if (!data.sucesso) {
            this._snackBar.openSnackBarErrorWithDurationAndLog(
              'Foram localizadas linhas com problema no arquivo enviado. Favor clicar no link de log para verificar o problema:',
              500000,
              `https://plataformadefretes-log.s3.amazonaws.com/${data.linkArquivoLog}`
            );
            this.dialogRef.close(true);
          } else {
            this._snackBar.openSnackBarSuccess('Arquivo importado com sucesso');
            this.loading = false;
            this.dialogRef.close(true);
          }
        },
        error: (error) => {
          console.error(error);
          this._snackBar.openSnackBarError('Erro ao importar arquivo');
        },
      });
  }

  importCarrierAttachment() {
    this.invitationsService
      .sendCarrierInvitation(this.files[0])
      .pipe(first())
      .subscribe({
        next: (data) => {
          if (!data.sucesso) {
            this._snackBar.openSnackBarErrorWithDurationAndLog(
              'Foram localizadas linhas com problema no arquivo enviado. Favor clicar no link de log para verificar o problema:',
              300000,
              `https://plataformadefretes-log.s3.amazonaws.com/${data.linkArquivoLog}`
            );
            this.dialogRef.close(true);
          } else {
            this._snackBar.openSnackBarSuccess(
              'Arquivo importado com sucesso.'
            );
            this.loading = false;
            this.dialogRef.close(true);
          }

          this.loading = false;
          this.dialogRef.close();
        },
        error: (error) => {
          console.error(error);
          this._snackBar.openSnackBarError('Erro ao importar arquivo');
          this.loading = false;
          this.dialogRef.close();
        },
      });
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  salvaAnexos() {
    let listFiles: any[] = [];

    if (this.type == 'spot') {
      const fileUploadPromises = this.files.map((file) =>
        lastValueFrom(
          this.offerAttachmentService.saveAttachment(file).pipe(first())
        )
          .then((data) => {
            return { link: data, name: file.name };
          })
          .catch((error) => {
            this._snackBar.openSnackBarError(
              'Erro ao importar arquivo ' + file.name
            );
            throw error;
          })
      );

      Promise.all(fileUploadPromises)
        .then((results) => {
          listFiles = results;
          this.loading = false;
          this.dialogRef.close(listFiles);
        })
        .catch(() => {
          this.loading = false;
          this.dialogRef.close();
        });
    } else {
      this.offerAttachmentService
        .saveAttachment(this.files[0])
        .pipe(first())
        .subscribe({
          next: (data) => {
            this._snackBar.openSnackBarSuccess(
              'Arquivo ' + this.files[0].name + ' importado com sucesso'
            );
            this.loading = false;
            this.dialogRef.close(data);
          },
          error: (error) => {
            console.error(error);
            this._snackBar.openSnackBarError(
              'Erro ao importar arquivo ' + this.files[0].name
            );
            this.loading = false;
            this.dialogRef.close();
          },
        });
    }
  }
}
