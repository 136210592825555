<div [ngClass]="{'opacity': loadingSpinner,'container-opacity': loadingSpinner}">
  <div class="background-login mt-3"></div>
  <div id="banner-login"  class="container-title">
    <h1 class="title">Plataforma de Fretes</h1>
    <p class="description-title">
      Simplifique o processo de contratação para frete Spot
    </p>
  </div>
  <div class="align-panel">
    <mat-card id="card-login" class="card panel">
      <mat-card-content>
        <form id="form-login" action="" class="w-100" [formGroup]="form" (ngSubmit)="send()">
          <h3 id="title-login" class="label-access">Acesse sua conta</h3>
          <p id="description-title" class="description-access">
            Entre com seu e-mail e senha para acessar a plataforma
          </p>
          <div class="form-group">
            <label id="label-email-login" for="" class="label-input form-label required">E-mail </label>
            <input
              id="input-email-login"
              type="text"
              class="input-login icon form-control"
              matInput
              placeholder="E-mail"
              formControlName="email"
            />
            <i class="ri-user-3-line user-icon"></i>
          </div>
          <div class="form-group mt-3">
            <label id="label-password-login" for="password" class="label-input form-label required"
              >Senha
            </label>
            <input
              [type]="typePassword"
              id="input-password-login"
              class="input-password icon form-control"
              matInput
              placeholder="Digite sua senha"
              formControlName="password"
            />
            <i class="ri-lock-line password-icon"></i>
          </div>
          <div class="form-check mt-2">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="input-showPassword-login"
              (change)="showPassword($event)"
            />
            <label id="label-showPassword-login" class="form-check-label mt-1 view-password" for="showPassword">
              Mostrar senha
            </label>
          </div>
          <div class="mt-3">
            <button
              id="button-submit-login"
              mat-raised-button
              color="primary"
              class="enter-button w-100"
              type="submit"
            >
              Entrar
            </button>
          </div>
          <div class="w-100 text-center mt-3">
            <a id="link-forgot-password-login"
            class="forgot-your-password" (click)="forgotPassword()" (keydown.enter)="forgotPassword()" tabindex="0"
              >Esqueceu sua senha?</a
            >
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div class="loading-overlay" *ngIf="loadingSpinner">
  <mat-spinner class="spinner"></mat-spinner>
</div>