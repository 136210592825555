import { Component, DoCheck, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing-transportador',
  templateUrl: './landing-transportador.component.html',
  styleUrls: ['./landing-transportador.component.scss'],
})
export class LandingTransportadorComponent implements OnInit, DoCheck {
  mobile = false
  slideBenefitsIndex = 1;
  slideFunctionalitiesIndex = 1;
  ngOnInit(): void {
    this.showSlidesBenefits(this.slideBenefitsIndex);
    this.showSlidesFunctionalities(this.slideFunctionalitiesIndex)
  }

  ngDoCheck(): void {
    this.checkMobile();
  }

  checkMobile() {
    if (window.innerWidth <= 901) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  plusSlides(n: any, classe: string) {
    if (classe == 'mySlidesBenefits') {
      this.slideBenefitsIndex += n;
      this.showSlidesBenefits(this.slideBenefitsIndex);
    } else if (classe == 'mySlidesFunctionalities') {
      this.slideFunctionalitiesIndex += n;
      this.showSlidesFunctionalities(this.slideFunctionalitiesIndex);
    } 
    
  }

  currentSlide(n: any, classe: string) {
    if (classe == 'mySlidesBenefits') {
      this.slideBenefitsIndex = n;
      this.showSlidesBenefits(this.slideBenefitsIndex);
    } else if (classe == 'mySlidesFunctionalities') {
      this.slideFunctionalitiesIndex = n;
      this.showSlidesFunctionalities(this.slideFunctionalitiesIndex);
    }
  }

  showSlidesBenefits(n: any) {
    let i;
    setTimeout(() => {
      const slides = document.getElementsByClassName(
        'mySlidesBenefits'
      ) as HTMLCollectionOf<HTMLElement>;
      const dots = document.getElementsByClassName('dot-benefits');
      if (n > slides.length) {
        this.slideBenefitsIndex = 1;
      }
      if (n < 1) {
        this.slideBenefitsIndex = slides.length;
      }
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = 'none';
      }
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(' active', '');
      }
      slides[this.slideBenefitsIndex - 1].style.display = 'contents';
      dots[this.slideBenefitsIndex - 1].className += ' active';
    }, 300);
  }

  showSlidesFunctionalities(n: any) {
    let i;
    setTimeout(() => {
      const slides = document.getElementsByClassName(
        'mySlidesFunctionalities'
      ) as HTMLCollectionOf<HTMLElement>;
      const dots = document.getElementsByClassName('dot-functionalities');
      if (n > slides.length) {
        this.slideFunctionalitiesIndex = 1;
      }
      if (n < 1) {
        this.slideFunctionalitiesIndex = slides.length;
      }
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = 'none';
      }
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(' active', '');
      }
      slides[this.slideFunctionalitiesIndex - 1].style.display = 'contents';
      dots[this.slideFunctionalitiesIndex - 1].className += ' active';
    }, 300);
  }
}
