import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SnackbarDefaultService } from 'src/app/core/services/utils/snackbar-default.service';
import { MatStepper } from '@angular/material/stepper';
import {
  certificacao_options,
  carga_options,
  veiculo_options,
  UFs,
} from 'src/app/core/models/consts';
import { first } from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';
import { CepService } from '../../services/utils/cep.service';
import { InvitationsService } from '../../services/shared/invitations.service';


@Component({
  selector: 'app-carrier-registration',
  templateUrl: './carrier-registration.component.html',
  styleUrls: ['./carrier-registration.component.scss'],
})
export class CarrierRegistrationComponent implements OnInit {
  userForm: FormGroup;
  companyForm: FormGroup;
  formEnded = false;

  resultField = false;
  resultDocument = false;

  validEmail = false;
  confirmPasswordValue = false;
  hash = '';
  clienteId = '';
  transportadoraId = '';
  @ViewChild(MatStepper) stepper!: MatStepper;
  logo = '../../../assets/img/svg/caminhao.svg';
  invalidZipCode = false;

  freightMode = [
    { label: 'Lotação', value: 'LOTACAO' },
    { label: 'Fracionado', value: 'FRACIONADO' },
  ];
  titleConclusion = '';
  bodyConclusion = '';
  visibleStep = true;
  file: any = null;

  loadTypes = carga_options;

  certifies = certificacao_options;

  UFs = UFs;

  vehicleTypes = veiculo_options

  emptyPassword = true;

  validLength = false;
  validNumber = false;
  validSpecialCharacter = false;
  validUpper = false;
  validLower = false;

  @ViewChild('nameRef') nameRef: ElementRef;
  @ViewChild('emailRef') emailRef: ElementRef;
  @ViewChild('passwordRef') passwordRef: ElementRef;
  @ViewChild('confirmPasswordRef') confirmPasswordRef: ElementRef;

  @ViewChild('documentRef') documentRef: ElementRef;
  @ViewChild('legalNameRef') legalNameRef: ElementRef;
  @ViewChild('zipcodeRef') zipcodeRef: ElementRef;
  @ViewChild('provinceRef') provinceRef: NgSelectComponent;
  @ViewChild('cityRef') cityRef: ElementRef;
  @ViewChild('streetRef') streetRef: ElementRef;
  @ViewChild('numberRef') numberRef: ElementRef;
  @ViewChild('neighborhoodRef') neighborhoodRef: ElementRef;
  @ViewChild('freightModeRef') freightModeRef: NgSelectComponent;
  @ViewChild('statesRef') statesRef: NgSelectComponent;
  @ViewChild('vehicleTypesRef') vehicleTypesRef: NgSelectComponent;
  @ViewChild('loadTypeRef') loadTypeRef: NgSelectComponent;
  @ViewChild('hasTrackerYesRef') hasTrackerYesRef: ElementRef;
  @ViewChild('hasTrackerNoRef') hasTrackerNoRef: ElementRef;
  @ViewChild('autonomousYesRef') autonomousYesRef: ElementRef;
  @ViewChild('autonomousNoRef') autonomousNoRef: ElementRef;

  constructor(
    private readonly fb: FormBuilder,
    private readonly cepService: CepService,
    private readonly route: ActivatedRoute,
    private readonly invitationsService: InvitationsService,
    private readonly _snackBar: SnackbarDefaultService
  ) {
    this.userForm = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      phonenumber: [''],
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
    });

    this.companyForm = this.fb.group({
      document: ['', [Validators.required]],
      legalName: ['', [Validators.required]],
      zipcode: ['', [Validators.required]],
      province: ['', [Validators.required]],
      city: ['', [Validators.required]],
      street: ['', [Validators.required]],
      number: ['', [Validators.required]],
      neighborhood: ['', [Validators.required]],
      complement: [''],

      loadType: ['', [Validators.required]],
      states: ['', [Validators.required]],
      vehicleTypes: ['', [Validators.required]],
      insurance: [''],
      patrimonialCost: [''],
      freightMode: ['', [Validators.required]],
      certifies: [''],
      hasTracker: ['', [Validators.required]],
      autonomous: ['', [Validators.required]],
    });

    this.userForm
      .get('password')
      ?.valueChanges.subscribe((value) => this.validFormatPassword(value));

    this.userForm
      .get('confirmPassword')
      ?.valueChanges.subscribe((value) => this.validConfirmPassword(value));
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.hash = params['hash'];
      this.clienteId = params['c'];
      this.transportadoraId = params['t'];
    });
  }

  onZipcodeChange(value: string) {
    const el = document.getElementById('input-zipcode');
    if (value) {
      this.cepService.consultaCep(value)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          if (!data.cep) {
            this.invalidZipCode = true;
            if (el?.classList.contains('is-valid')) {
              el?.classList.remove('is-valid');
              el?.classList.add('is-invalid');
            } else {
              el?.classList.add('is-invalid');
            }
            this.companyForm.patchValue({
              province: '',
              city: '',
              street: '',
              complement: '',
              neighborhood: '',
            });
          } else {
            this.invalidZipCode = false;
            if (el?.classList.contains('is-invalid')) {
              el?.classList.remove('is-valid');
              el?.classList.add('is-valid');
            } else {
              el?.classList.add('is-valid');
            }
            this.companyForm.patchValue({
              province: data.uf,
              city: data.localidade,
              street: data.logradouro,
              complement: data.complemento,
              neighborhood: data.bairro,
            });
          }
        },
        error: (error) => {
          console.error(error);
          this.invalidZipCode = true;
          if (el?.classList.contains('is-valid')) {
            el?.classList.remove('is-valid');
            el?.classList.add('is-invalid');
          } else {
            el?.classList.add('is-invalid');
          }
          this.companyForm.patchValue({
            province: '',
            city: '',
            street: '',
            complement: '',
            neighborhood: '',
          });
        },
      })
    }
  }

  visiblePassword(event: any, elementId: string) {
    const { target } = event;
    const { checked } = target;
    const element = document.getElementById(elementId);
    element?.setAttribute('type', checked ? 'text' : 'password');
  }

  public checkEmail() {
    this.validEmail = false;
    const email = this.userForm.get('email')?.value;

    if (email.match(/[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$/)) {
      this.validEmail = true;
    } else {
      this.validEmail = false;
    }
    return this.validEmail;
  }

  validConfirmPassword(value: string) {
    const password = this.userForm.get('password')?.value;
    const confirmPassword = value;

    if (confirmPassword === password) {
      this.confirmPasswordValue = true;
      this.userForm.get('confirmPassword')?.setErrors(null);
    } else {
      this.confirmPasswordValue = false;
      this.userForm.get('confirmPassword')?.setErrors({ invalidConfirm: true });
    }
  }

  submitUserData() {
    this.userForm.markAllAsTouched();
    if (!this.userForm.valid) {
      this._snackBar.openSnackBarWarning('Há campos que necessitam de atenção');
      if(this.userForm.controls['name'].invalid){
        this.nameRef.nativeElement.focus();
      } else if(this.userForm.controls['email'].invalid){
        this.emailRef.nativeElement.focus();
      } else if(this.userForm.controls['password'].invalid){
        this.passwordRef.nativeElement.focus();
      } else if(this.userForm.controls['confirmPassword'].invalid){
        this.confirmPasswordRef.nativeElement.focus();
      }
      return;
    }
    const passValue = this.userForm.controls['password'].value;
    const cpassValue = this.userForm.controls['confirmPassword'].value;
    if (passValue !== cpassValue) {
      return;
    }
  }

  focusInvalidField() {
    this.companyForm.markAllAsTouched();
    let invalid = false;

    if (this.companyForm.invalid) {
      invalid = true;
      this._snackBar.openSnackBarWarning('Há campos que necessitam de atenção');
      this.focusFirstInvalidField();
    }
    return invalid;
  }

  private focusFirstInvalidField() {
    const controls = this.companyForm.controls;
    const focusMap: Record<string, any> = {
      document: this.documentRef,
      legalName: this.legalNameRef,
      zipcode: this.zipcodeRef,
      province: this.provinceRef,
      city: this.cityRef,
      street: this.streetRef,
      number: this.numberRef,
      neighborhood: this.neighborhoodRef,
      freightMode: this.freightModeRef,
      states: this.statesRef,
      vehicleTypes: this.vehicleTypesRef,
      loadType: this.loadTypeRef,
      hasTracker: this.hasTrackerYesRef,
      autonomous: this.autonomousYesRef,
    };

    for (const key in controls) {
      if (controls[key].invalid && focusMap[key]) {
        const ref = focusMap[key];
        if (ref.focus) {
          ref.focus();
        } else if (ref.nativeElement) {
          ref.nativeElement.focus();
        }
        break;
      }
    }
  }

  submitFormData() {
      const invalid = this.focusInvalidField()

      if(invalid) return

      const transportadora = {
        cnpj: this.companyForm.value.document,
        razaoSocial: this.companyForm.value.legalName,
        veiculosComRastreador: this.companyForm.value.hasTracker,
        autonomo: this.companyForm.value.autonomous,
        valorPatrimonial: this.companyForm.value.patrimonialCost,
        numeroSeguro: this.companyForm.value.insurance,
        carga: this.companyForm.value.loadType,
        ufAtendida: this.companyForm.value.states,
        veiculo: this.companyForm.value.vehicleTypes,
        certificacao: this.companyForm.value.certifies
          ? this.companyForm.value.certifies.map((c: any) => {
              return {
                certificacao: c,
              };
            })
          : null,
        modalidade: this.companyForm.value.freightMode,
        endereco: {
          uf: this.companyForm.value.province,
          cidade: this.companyForm.value.city,
          cep: this.companyForm.value.zipcode,
          logradouro: this.companyForm.value.street,
          numero: this.companyForm.value.number,
          complemento: this.companyForm.value.complement,
          bairro: this.companyForm.value.neighborhood,
        },
        usuario: {
          nome: this.userForm.get('name')?.value,
          email: this.userForm.get('email')?.value,
          senha: this.userForm.get('password')?.value,
          telefone: this.userForm.get('phonenumber')?.value,
          tipoUsuario: 1,
          status: 'INATIVO',
        },
      };
      this.invitationsService
        .registerCarrier(
          transportadora,
          this.hash,
          this.clienteId,
          this.transportadoraId,
          this.file
        )
        .pipe(first())
        .subscribe({
          next: () => {
            this.titleConclusion = '🎉 Cadastro concluído com sucesso!';
            this.bodyConclusion = `
Seu perfil já está ativado e pronto para uso na nossa Plataforma de Fretes! Agora é só:
1️⃣ Acessar com seu e-mail e senha cadastrados
2️⃣ Explorar todos os benefícios e funcionalidades disponíveis na Plataforma.

Qualquer dúvida, estamos à disposição para ajudar.
Bem-vindo(a) e boas entregas! 🚛💨`;
          },
          error: (error) => {
            this.visibleStep = false;
            if(error.error == 'Já existe um empresa com esse cnpj'){
              this._snackBar.openSnackBarInfo(
                `Atenção! Já existe empresa com esse CNPJ. `,

              );
            } else if(error.error == 'Já existe um usuário com esse email'){
                this._snackBar.openSnackBarInfo(
                  `Atenção! Já existe usuário com esse email. `,
                );
            } else {
              this._snackBar.openSnackBarError('Seu cadastro não foi concluído');
            }
            this.titleConclusion = 'Seu cadastro não foi concluído';
            this.bodyConclusion =
              'Ops, ocorreu algum problema na criação do seu usuário, verifique a mensagem, faça as alterações necessárias e tente novamente!';
            }
            }
        );

      this.formEnded = true;
  }

  onStepChange(event: any) {
    if (event.selectedIndex != 2) {
      this.titleConclusion = '';
      this.bodyConclusion = '';
      const stepToBlock = this.stepper._steps.toArray()[2];
      stepToBlock.completed = false;
      stepToBlock.editable = false;
      stepToBlock.interacted = false;
    }
  }

  updatePhoto(event: any) {
    const img = document.createElement('img');
    const urlImage = URL.createObjectURL(event.target.files[0]);
    this.file = event.target.files[0];
    let width = 0;
    let height = 0;
    img.src = urlImage;
    img.onload = () => {
      width = img.naturalWidth;
      height = img.naturalHeight;

      if (width > 400 || height > 400) {
        this._snackBar.openSnackBarInfo(
          'Imagem com dimensões fora dos padrões aceitos'
        );
      } else {
        this.logo = urlImage;
      }
    };
  }

  removePhoto() {
    this.logo = '../../../assets/img/svg/caminhao.svg';
    this.file = null;
  }
  

  public validFormatPassword(password: string) {
    const confirmPassword = this.userForm.get('confirmPassword')?.value
    if (password == null || password === '') {
      this.validLength = false;
      this.validNumber = false;
      this.validSpecialCharacter = false;
      this.validUpper = false;
      this.validLower = false;
      return;
    }

    this.emptyPassword = false;

    this.validLength = password.length >= 8 && password.length <= 30;
    this.validNumber = !!RegExp(/\d/).exec(password);
    this.validSpecialCharacter = !!password.match(/[^a-zA-Z 0-9]+/g);
    this.validUpper = !!RegExp(/[A-Z]+/).exec(password);
    this.validLower = !!RegExp(/[a-z]+/).exec(password);

    if(password != null && confirmPassword != null){
      if(password == confirmPassword) this.confirmPasswordValue = true
    }
  }
}
