import { environment } from '../../../../environments/environment';
import {
  Component,
  DoCheck,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, Scroll } from '@angular/router';
import { UtilsService } from 'src/app/core/services/utils/utils.service';

@Component({
  selector: 'app-private-layout',
  templateUrl: './private.component.html',
  styleUrls: ['./private.component.scss'],
})
export class PrivateLayoutComponent implements OnInit, DoCheck {
  routePath = '';

  hideNavItems = false;
  pageTitle = '';
  componentRoutes: any[] = [];
  isHome = false;
  lastPath = '';
  visibleRecipients = environment.RECIPIENTS
  isAdmin = false;
  menuRoutes: any[] = [];
  user: any

  constructor(private readonly router: Router, private readonly route: ActivatedRoute, private readonly utilsService: UtilsService,) {
    this.router.events.subscribe((value) => {
      if (!(value instanceof NavigationEnd || value instanceof Scroll)) return;

      this.configureTitle(route.snapshot.data);
    });
  }

  ngOnInit(): void {
    this.routePath = window.location.pathname;
    this.user = JSON.parse(localStorage.getItem('usuario'))
    this.isAdmin = this.user?.tipoUsuario == 1
  }

  ngDoCheck(): void {
    this.routePath = window.location.pathname;
    this.lastPath = this.routePath.split('/').pop();
    this.user = JSON.parse(localStorage.getItem('usuario'))
    this.isAdmin = this.user?.tipoUsuario == 1
  }

  public checkDigitPath(path: string) {
      if (!isNaN(Number(path))) {
        this.hideNavItems = true;
      }
  }

  configureTitle(data: any) {
    const menuitems: any[] = data?.menuitems || [];
  
    const currentPaths = this.router.url.split('/').filter((s: string) => s);
  
    this.hideNavItems = !menuitems.length || false;
    this.isHome = false;
    this.componentRoutes = [];
  
    const skipFirstSegment = currentPaths[0] !== 'portal';
  
    this.processMenuItems(menuitems);
  
    const accumulatedPath = '';
    let menuItem: any = null;
  
    menuItem = this.processPathSegments(currentPaths, menuitems, accumulatedPath, skipFirstSegment);
  
    this.checkIfIsHome(currentPaths, menuItem);
  }
  
  // --------------------------------------
  
  private processMenuItems(menuitems: any[]) {
    if (menuitems.length > 0) {
      this.menuRoutes = menuitems.map((mi: any) => ({
        ...mi,
        items: mi.items.filter((i: any) => !i?.hidden),
      }));
    }
  }
  
  // --------------------------------------
  
  private processPathSegments(currentPaths: string[], menuitems: any[], accumulatedPath: string, skipFirstSegment: boolean) {
    let menuItem: any = null;
  
    for (let i = 0; i < currentPaths.length; i++) {
      const segment = currentPaths[i];
      accumulatedPath += `/${segment}`;
  
      this.checkDigitPath(segment);
  
      if (skipFirstSegment) {
        skipFirstSegment = false;
        continue;
      }
  
      menuItem = this.findMenuItem(segment, menuitems);
  
      if (menuItem) {
        this.componentRoutes.push({
          label: menuItem.breadcrumb || menuItem.label || '',
          path: accumulatedPath,
        });
        this.defineTitle(i, menuItem, menuItem.label || '');
      } else {
        this.handleDynamicParam(segment, accumulatedPath);
      }
    }
  
    return menuItem;
  }
  
  // --------------------------------------
  
  private findMenuItem(segment: string, menuitems: any[]): any {
    for (const mi of menuitems) {
      const item = mi.items.find((item: any) => item.path === segment);
      if (item) return item;
    }
    return null;
  }
  
  // --------------------------------------
  
  private handleDynamicParam(segment: string, accumulatedPath: string) {
    const paramPattern = /^[0-9a-zA-Z-_]+$/;
    if (paramPattern.test(segment)) {
      if (this.componentRoutes.length > 0) {
        const lastRoute = this.componentRoutes[this.componentRoutes.length - 1];
        lastRoute.path += `/${segment}`;
        lastRoute.label = lastRoute.label || segment;
      } else {
        this.componentRoutes.push({
          label: segment,
          path: accumulatedPath,
        });
      }
    }
  }
  
  // --------------------------------------
  
  private checkIfIsHome(currentPaths: string[], menuItem: any) {
    if (currentPaths.length === 1 && (currentPaths[0] === 'shipper' || currentPaths[0] === 'carriers')) {
      this.hideNavItems = true;
      this.isHome = true;
    } else if (!menuItem) {
      this.hideNavItems = true;
    } else {
      this.configureNavItem(menuItem);
    }
  }
  
  // --------------------------------------
  
  private configureNavItem(menuItem: any) {
    this.hideNavItems = menuItem.hideNav || false;
    this.isHome = menuItem.home || false;
  }
  
  
  defineTitle(i: any, m: any, label: any){
    if (i >= 2) {
      if (m.breadcrumb) {
        this.pageTitle = m.breadcrumb;
      } else {
        this.pageTitle = label;
      }
    } else if (i < 2 && m.breadcrumb) {
      this.pageTitle = m.breadcrumb;
    }
  }

  removeAccentuationForID(text: string){
    return this.utilsService.removeAccentuationForID(text)
  }

}
