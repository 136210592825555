import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { RedefinePasswordDialogService } from '../../../core/services/auth/redefine-password-dialog.service';
import { SnackbarDefaultService } from '../../../core/services/utils/snackbar-default.service';
import { first } from 'rxjs';

@Component({
  selector: 'app-redefine-password-dialog',
  templateUrl: './redefine-password-dialog.component.html',
  styleUrls: ['./redefine-password-dialog.component.scss'],
})
export class RedefinePasswordDialogComponent {
  redefinePasswordForm: FormGroup
  validLength = false;
  validNumber = false;
  validSpecialCharacter = false;
  validUpper = false;
  validLower = false;

  currentPassword = '';
  newPassword = '';
  confirmNewPassword = '';
  confirmPasswordValue = false;
  invalidNewPassword = false

  emptyNewPassword = true;

  visibleCurrentPassword = false;
  visibleNewPassword = false;
  visibleConfirmPassword = false;

  userId = '';
  confirm = false;

  @ViewChild('currentPasswordRef') currentPasswordRef: ElementRef;
  @ViewChild('newPasswordRef') newPasswordRef: ElementRef;  
  @ViewChild('confirmNewPasswordRef') confirmNewPasswordRef: ElementRef;

  constructor(
    private readonly formBuilder: FormBuilder,
    public readonly dialogRef: MatDialogRef<RedefinePasswordDialogComponent>,
    private readonly redefinePasswordDialogService: RedefinePasswordDialogService,
    private readonly _snackBar: SnackbarDefaultService
  ) {
    this.redefinePasswordForm= this.formBuilder.group({
      currentPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required]],
      confirmNewPassword: ['', [Validators.required]],
    });

    this.redefinePasswordForm
      .get('newPassword')
      ?.valueChanges.subscribe((value) => this.validFormatPassword(value));
    this.redefinePasswordForm
      .get('confirmNewPassword')
      ?.valueChanges.subscribe((value) => this.validConfirmPassword(value));

    const { id } = JSON.parse(localStorage.getItem('usuario'));
    this.userId = id;
  }

  visiblePassword(elementId: string) {
    const element = document.getElementById(elementId);
    if (elementId === 'currentPassword') {
      this.visibleCurrentPassword = !this.visibleCurrentPassword;
      element?.setAttribute(
        'type',
        this.visibleCurrentPassword ? 'text' : 'password'
      );
    } else if (elementId === 'newPassword') {
      this.visibleNewPassword = !this.visibleNewPassword;
      element?.setAttribute(
        'type',
        this.visibleNewPassword ? 'text' : 'password'
      );
    } else {
      this.visibleConfirmPassword = !this.visibleConfirmPassword;
      element?.setAttribute(
        'type',
        this.visibleConfirmPassword ? 'text' : 'password'
      );
    }
  }

  public validConfirmPassword(value?: string) {
    const newPassword = this.redefinePasswordForm.value.newPassword;
    const confirmPassword = value || this.redefinePasswordForm.value.confirmNewPassword

    if (confirmPassword === newPassword) {
      this.confirmPasswordValue = true;
    } else {
      this.confirmPasswordValue = false;
    }
  }

  public validFormatPassword(password: string) {
    if (password == null || password === '') {
      this.validLength = false;
      this.validNumber = false;
      this.validSpecialCharacter = false;
      this.validUpper = false;
      this.validLower = false;
      return;
    }

    this.emptyNewPassword = false;

    this.validLength = password.length >= 8 && password.length <= 30;
    this.validNumber = !!RegExp(/\d/).exec(password);
    this.validSpecialCharacter = !!password.match(/[^a-zA-Z 0-9]+/g);
    this.validUpper = !!RegExp(/[A-Z]+/).exec(password);
    this.validLower = !!RegExp(/[a-z]+/).exec(password);

    this.checkNewPassword(password)

  }

  checkNewPassword(newPassword: string) {
    const currentPassword =
      this.redefinePasswordForm.get('currentPassword')?.value;

      if(currentPassword != '') {
        if(newPassword == currentPassword) {
          this.invalidNewPassword = false
          this.redefinePasswordForm.controls['newPassword'].setErrors({ 'incorrect': true });
          this._snackBar.openSnackBarWarning('A nova senha não pode ser igual à senha atual!');
        }else {
          this.invalidNewPassword = true
        }
      }
  }

  public close() {
    this.dialogRef.close(false);
  }

  public updatePassword() {
    this.redefinePasswordForm.markAllAsTouched();
    const result = this.returnOK(this.redefinePasswordForm.value.currentPassword, this.redefinePasswordForm.value.newPassword);

    if(this.redefinePasswordForm.invalid || !result) {
      this._snackBar.openSnackBarWarning('Há campos que necessitam de atenção');
      if(this.redefinePasswordForm.controls['currentPassword'].invalid) {
        this.currentPasswordRef.nativeElement.focus();
      } else if(this.redefinePasswordForm.controls['newPassword'].invalid) {
        this.newPasswordRef.nativeElement.focus();
      } else if(this.redefinePasswordForm.controls['confirmNewPassword'].invalid) {
        this.confirmNewPasswordRef.nativeElement.focus();
      }
      return; 
    }

    if (result) {
      const authentication = {
        senhaAtual: this.redefinePasswordForm.value.currentPassword,
        senhaNova: this.redefinePasswordForm.value.newPassword,
        idUsuario: this.userId,
      };

      this.redefinePasswordDialogService
        .redefinePassword(authentication)
        .pipe(first())
        .subscribe({
          next: (value) => {
            this.dialogRef.close(true);
            this._snackBar.openSnackBarSuccess(value);
          },
          error: (error) => {
            this._snackBar.openSnackBarError(error.error);
          },
        });
    }
  }

  returnOK(currentPassword: string, newPassword: string) {
    let result = true
    if (
      !this.validLength ||
      !this.validNumber ||
      !this.validSpecialCharacter ||
      !this.validUpper ||
      !this.validLower
    ) {
      this._snackBar.openSnackBarWarning(
        'Senha não atende ao padrão solicitado'
      );
      result = false
    } else if(currentPassword ==  newPassword){
      this._snackBar.openSnackBarWarning('A nova senha não pode ser igual à senha atual!');
      this.redefinePasswordForm.controls['newPassword'].setErrors({ 'incorrect': true });
      result = false
    }else {
      this.validConfirmPassword()
      if (!this.confirmPasswordValue) {
        this._snackBar.openSnackBarWarning('Confirmação de senha inválida');
        this.redefinePasswordForm.controls['confirmNewPassword'].setErrors({ 'incorrect': true });
        result = false
      }
    } 
    return result
  }
}
