import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, first, map, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  constructor(private readonly http: HttpClient) {}

  uploadImagemPerfil(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);

    return this.http
      .post<any>(`${environment.API_BACK}/usuario/upload-imagem`, formData)
      .pipe(
        map((dados) => {
          return dados;
        }),
        catchError((error) => {
          return of(error);
        })
      );
  }

    salvaImagem(file: any, nomeArquivo: string) {
      const form: FormData = new FormData();
      form.append('file', file);
      const headers = new HttpHeaders();
      headers.append('responseType', 'text');
      headers.append('Content-Type', 'multipart/form-data');
  
      return this.http
        .post<any>(
          `${environment.API_BACK}/empresa/get-link/${nomeArquivo}`,
          form,
          {
            headers,
            responseType: 'text' as 'json',
          }
        )
        .pipe(
          first(),
          map((dados) => {
            return dados;
          }),
          catchError((error) => {
            return of(error);
          })
        );
    }

    removeLogo(idEmpresa: number) {
      let params = new HttpParams();
      params = params.append('idEmpresa', idEmpresa);
  
      return this.http
        .put<any>(`${environment.API_BACK}/empresa/remove-logo`, params)
        .pipe(
          first(),
          map((dados) => {
            return dados;
          }),
          catchError((err) => {
            return of(err);
          })
        );
    }
}
