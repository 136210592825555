import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class IntegrationsService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  createIntegration(obj: any) {
    const usuario = JSON.parse(localStorage.getItem('usuario')!);

    obj.idCliente = usuario.cliente.id;
    return this.http
      .post<any>(`${environment.API_BACK}/integracao/gko`, obj)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  getIntegration(id: any) {
    return this.http.get<any>(`${environment.API_FILTER}/integracao/all`).pipe(
      first(),
      map((dados) => {
        return dados;
      })
    );
  }
}
