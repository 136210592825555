import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs';
import { RecipientService } from 'src/app/core/services/shared/recipient.service';

interface Recipient {
  name: string;
  id: number;
  document: string;
  address: Address;
}

interface Address {
  zipcode: string;
  province: string;
  city: string;
  street: string;
  number: string;
  neighborhood: string;
  complement: string;
}

@Component({
  selector: 'app-recipient-details',
  templateUrl: './recipient-details.component.html',
  styleUrls: ['./recipient-details.component.scss'],
})
export class RecipientDetailsComponent implements OnInit {
  nameColumns: any = {
    lbName: 'Nome',
    lbDocument: 'Documento',
    lbZipcode: 'CEP',
    lbProvince: 'Estado',
    lbCity: 'Cidade',
    lbStreet: 'Logradouro',
    lbnumber: 'Número',
    lbNeighborhood: 'Bairro',
    lbComplement: 'Complemento',
  };

  id: any;
  recipient: Recipient;
  loaded = true;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly recipientService: RecipientService
  ) {}

  ngOnInit(): void {
    this.id = this.getIdFromUrl();
    this.getRecipients();
  }

  getIdFromUrl() {
    return this.route.snapshot.paramMap.get('id');
  }

  getRecipients() {
    this.loaded = false;
    this.recipientService
      .getRecipientById(this.id)
      .pipe(first())
      .subscribe({
        next: (recipient) => {
          this.recipient = {
            id: recipient.id,
            name: recipient.nome,
            document: recipient.documento,
            address: {
              zipcode: recipient.endereco.cep,
              province: recipient.endereco.uf,
              city: recipient.endereco.cidade,
              street: recipient.endereco.logradouro,
              number: recipient.endereco.numero,
              neighborhood: recipient.endereco.bairro,
              complement: recipient.endereco.complemento || '',
            },
          };
          this.loaded = true;
        },
        error: (error) => {
          console.error(error);
          this.loaded = true;
        },
      })
  }
}
