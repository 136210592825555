import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs';
import { CompaniesService } from 'src/app/core/services/shared/companies.service';

@Component({
  selector: 'app-requirements-register',
  templateUrl: './requirements-register.component.html',
  styleUrls: ['./requirements-register.component.scss'],
})
export class RequirementsRegisterComponent implements OnInit {
  ngOnInit(): void {
    this.getCompany();
  }

  defaultColumns = ['title', 'actions'];

  listTitle = ['Cargas', 'Veículos', "UF's atendidas", 'Modalidade de frete'];

  displayedColumns = [this.defaultColumns, this.defaultColumns, this.defaultColumns, this.defaultColumns ];

  loadType: any;
  ufs: any;
  vehicle: any;
  freightMode: any;
  listTables: any[] = [];

  listHeader = [
    {
      title: 'Cargas',
      description: 'Descrição',
      actions: 'Ação',
    },
    {
      title: 'Veículos',
      description: 'Descrição',
      actions: 'Ação',
    },
    {
      title: "UF's atendidas",
      description: 'Descrição',
      actions: 'Ação',
    },
    {
      title: 'Modalidade de frete',
      description: 'Descrição',
      actions: 'Ação',
    },
  ];

  constructor(
    private readonly companiesService: CompaniesService,
    private readonly route: ActivatedRoute
  ) {}

  getIdFromUrl() {
    return this.route.snapshot.paramMap.get('id');
  }

  getCompany() {
    this.companiesService
      .getCompany(this.getIdFromUrl())
      .pipe(first())
      .subscribe({
        next: (data) => {
          this.populaTabelaRequisitos(data);
          this.listTables = [];
  
          this.listTables.push(this.loadType);
          this.listTables.push(this.vehicle);
          this.listTables.push(this.ufs);
          this.listTables.push(this.freightMode);
        },
        error: (error) => {
          console.error(error);
        },
      })
  }

  populaTabelaRequisitos(data: any) {
    this.loadType = data?.carga?.map((element: any) => ({
      labelWithUndescore: element,
      label: element ? element.replaceAll('_', ' ') : '',
    })) || [];
    this.vehicle = data.veiculoList?.map((element: any) => ({
      labelWithUndescore: element.veiculo,
      label: element.veiculo ? element.veiculo.replaceAll('_', ' ') : '',
      possuiVeiculosComRastreador: data.possuiVeiculosComRastreador,
      quantidade: element?.quantidade ? element?.quantidade : '-'
    }));
    this.ufs = data.ufAtendida?.map((element: any) => ({
      labelWithUndescore: element,
      label: element ? element.replaceAll('_', ' ') : '',
    }));
    this.freightMode = data?.modalidade?.map((element: any) => ({
      labelWithUndescore: element,
      label: element,
    })) || [];
  }
}
