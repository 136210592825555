import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs';
import moment from 'moment';
import { ClientService } from 'src/app/core/services/shared/client.service';

interface NameColumns {
  lbPlan: string;
  lbTotalOffers: string;
  lbActivationDate: string;
  lbExpirationDate: string;
  lbTotalOffersMonth: string;
  lbSpotOffer: string;
  lbContractOffer: string
}

interface PaymentsInformation {
  plan: string;
  totalOffers: number | string;
  activationDate: string;
  expirationDate: string;
  totalOffersMonth: number;
  spotOffers: number;
  contractOffers: number;
}

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
})
export class MyAccountComponent implements OnInit {
  loaded = false;
  data: PaymentsInformation;
  id: string|number;
  user: any;

  nameColumns: NameColumns = {
    lbPlan: 'Plano',
    lbTotalOffers: 'Quantidade total de ofertas',
    lbActivationDate: 'Data de ativação',
    lbExpirationDate: 'Data de expiração do plano',
    lbTotalOffersMonth: 'Total de ofertas criadas',
    lbSpotOffer: 'Ofertas SPOT',
    lbContractOffer: 'Ofertas contrato'
  };

  constructor(private readonly clientService: ClientService) {}

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('usuario'));
    this.id = this.user.cliente.id;
    this.getCompany();
  }

  getCompany() {
    this.loaded = true;
    this.clientService
      .getTotalOffersByClient()
      .pipe(first())
      .subscribe({
        next: (client) => {
          if (client != null) {
            this.data = {
              plan: client.plano || 'Sem informação',
              totalOffers: client.quantidadeOfertasPlano || 'Sem informação',
              activationDate: client.dataAtivacaoPlano ? this.formatDate(client.dataAtivacaoPlano) : null,
              expirationDate: client.dataEncerramentoPlano ? this.formatDate(client.dataEncerramentoPlano) : null,
              totalOffersMonth: client.ofertasMes || 0,
              spotOffers: client.ofertasSpotMes || 0,
              contractOffers: client.ofertasContratoMes || 0
            };
          }
          this.loaded = false;
        },
        error: (error) => {
          console.error(error);
          this.loaded = false;
        }
      })
  }

  formatDate(date: Date) {
    return moment(date).format('DD/MM/yyyy');
  }
}
