import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CertificationService {
  constructor(private readonly http: HttpClient) {}

  saveCertification(obj: any) {
    return this.http
      .post<any>(`${environment.API_BACK}/certificacao-empresa`, obj)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  salvaAnexo(file: any) {
    const form: FormData = new FormData();
    form.append('file', file);
    const headers = new HttpHeaders();
    headers.append('responseType', 'text');
    headers.append('Content-Type', 'multipart/form-data');

    return this.http
      .post<any>(`${environment.API_BACK}/anexo/get-link/certificacao`, form, {
        headers,
        responseType: 'text' as 'json',
      })
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  deleteCertifies(idEmpresa: any, certificacaoLookup: any) {
    let params = new HttpParams();
    params = params.append('idEmpresa', idEmpresa);
    params = params.append('certificacaoLookup', String(certificacaoLookup));

    const httpOptions = {
      params: params,
    };

    return this.http.delete(
      `${environment.API_BACK}/certificacao-empresa/remove-certificacao`,
      httpOptions
    );
  }

  getSector() {
    return this.http.get<any>(`${environment.API_FILTER}/setor/all`).pipe(
      first(),
      map((dados) => {
        return dados;
      })
    );
  }
}
