import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarDefaultService } from 'src/app/core/services/utils/snackbar-default.service';
import { first } from 'rxjs';
import { UFs } from 'src/app/core/models/consts';
import { NgSelectComponent } from '@ng-select/ng-select';
import { RecipientService } from 'src/app/core/services/shared/recipient.service';
import { CepService } from 'src/app/core/services/utils/cep.service';

@Component({
  selector: 'app-recipient',
  templateUrl: './recipient.component.html',
  styleUrls: ['./recipient.component.scss'],
})
export class RecipientComponent implements OnInit {
  id: any;
  invalidZipCode = false;
  resultField = false;
  emptyField = true;
  loadingSpinner = false;
  UFs = UFs;

  @ViewChild('nameRef') nameRef: ElementRef;
  @ViewChild('documentRef') documentRef: ElementRef;
  @ViewChild('zipcodeRef') zipcodeRef: ElementRef;
  @ViewChild('provinceRef') provinceRef: NgSelectComponent;
  @ViewChild('cityRef') cityRef: ElementRef;
  @ViewChild('streetRef') streetRef: ElementRef;
  @ViewChild('numberRef') numberRef: ElementRef;
  @ViewChild('neighborhoodRef') neighborhoodRef: ElementRef;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly _snackBar: SnackbarDefaultService,
    private readonly formBuilder: FormBuilder,
    private readonly cepService: CepService,
    private readonly recipientService: RecipientService
  ) {}

  public recipientForm: FormGroup = this.formBuilder.group({
    name: ['', [Validators.required]],
    document: ['', [Validators.required]],
    zipcode: ['', [Validators.required]],
    province: ['', [Validators.required]],
    city: ['', [Validators.required]],
    street: ['', [Validators.required]],
    number: [
      '',
      Validators.compose([
        Validators.required,
        Validators.pattern(/^-?(0|[1-9]\d*)?$/),
      ]),
    ],
    neighborhood: ['', [Validators.required]],
    complement: [''],
  });

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');

    if (this.id) this.getRecipient();
  }

  onZipcodeChange(value: string) {
    const el = document.getElementById('zipcode');
    if (value) {
      this.cepService
        .consultaCep(value)
        .pipe(first())
        .subscribe({
          next: (zipData: any) => {
            if (!zipData.cep) {
              this.invalidZipCode = true;
              if (el.classList.contains('is-valid')) {
                el.classList.remove('is-valid');
                el.classList.add('is-invalid');
              } else {
                el.classList.add('is-invalid');
              }
              this.recipientForm.patchValue({
                province: '',
                city: '',
                street: '',
                complement: '',
                neighborhood: '',
              });
            } else {
              this.invalidZipCode = false;
              if (el.classList.contains('is-invalid')) {
                el.classList.remove('is-valid');
                el.classList.add('is-valid');
              } else {
                el.classList.add('is-valid');
              }
              this.recipientForm.patchValue({
                province: zipData.uf,
                city: zipData.localidade,
                street: zipData.logradouro,
                complement: zipData.complemento,
                neighborhood: zipData.bairro,
              });
            }
          },
          error: (error) => {
            console.error(error);
          },
        });
    }
  }

  validFieldsForm(field: string) {
    this.resultField = false;
    this.emptyField = true;

    if (
      (this.recipientForm.get(field)?.value?.length === 0 ||
        this.recipientForm.get(field)?.value === '') &&
      this.recipientForm.get(field)?.touched &&
      this.recipientForm.get(field)?.invalid
    ) {
      this.resultField = true;
      this.emptyField = true;
    } else if (
      (this.recipientForm.get(field)?.value?.length > 0 ||
        this.recipientForm.get(field)?.value !== '') &&
      this.recipientForm.get(field)?.valid &&
      this.recipientForm.get(field)?.touched
    ) {
      this.resultField = true;
      this.emptyField = false;
    }

    return this.resultField;
  }

  private buildRecipient() {
    return {
      nome: this.recipientForm.value.name,
      documento: this.recipientForm.value.document,
      endereco: {
        cep: this.recipientForm.value.zipcode,
        uf: this.recipientForm.value.province,
        cidade: this.recipientForm.value.city,
        logradouro: this.recipientForm.value.street,
        numero: this.recipientForm.value.number,
        bairro: this.recipientForm.value.neighborhood,
        complemento: this.recipientForm.value.complement || '',
      },
    };
  }

  private fillinFields(data: any) {
    this.recipientForm.get('name').setValue(data.nome);
    this.recipientForm.get('document').setValue(data.documento);
    this.recipientForm.get('zipcode').setValue(data.endereco.cep);
    this.recipientForm.get('province').setValue(data.endereco.uf);
    this.recipientForm.get('city').setValue(data.endereco.cidade);
    this.recipientForm.get('street').setValue(data.endereco.logradouro);
    this.recipientForm.get('number').setValue(data.endereco.numero);
    this.recipientForm.get('neighborhood').setValue(data.endereco.bairro);
    this.recipientForm.get('complement')?.setValue(data.endereco.complemento);
  }

  getRecipient() {
    this.loadingSpinner = true;
    this.recipientService
      .getRecipientById(this.id)
      .pipe(first())
      .subscribe({
        next: (data) => {
          this.fillinFields(data);
          this.loadingSpinner = false;
        },
        error: (error) => {
          console.error(error);
          this.loadingSpinner = false;
        },
      });
  }

  editRecipient(recipient: object) {
    this.recipientForm.markAllAsTouched();
    if (this.recipientForm.invalid) {
      this._snackBar.openSnackBarWarning(
        'Há campos que necessitam de atenção.'
      );
      if (this.recipientForm.controls['name'].invalid) {
        this.nameRef.nativeElement.focus();
      } else if (this.recipientForm.controls['document'].invalid) {
        this.documentRef.nativeElement.focus();
      } else if (this.recipientForm.controls['zipcode'].invalid) {
        this.zipcodeRef.nativeElement.focus();
      } else if (this.recipientForm.controls['province'].invalid) {
        this.provinceRef.focus();
      } else if (this.recipientForm.controls['city'].invalid) {
        this.cityRef.nativeElement.focus();
      } else if (this.recipientForm.controls['street'].invalid) {
        this.streetRef.nativeElement.focus();
      } else if (this.recipientForm.controls['number'].invalid) {
        this.numberRef.nativeElement.focus();
      } else if (this.recipientForm.controls['neighborhood'].invalid) {
        this.neighborhoodRef.nativeElement.focus();
      }
      return;
    }
    this.recipientService
      .createRecipient(Object.assign(recipient, { id: this.id }))
      .pipe(first())
      .subscribe({
        next: () => {
          this._snackBar.openSnackBarSuccess(
            'Destinatário atualizado com sucesso.'
          );
          setTimeout(() => {
            this.redirect();
          }, 1000);
        },
        error: () => {
          this._snackBar.openSnackBarError('Erro ao atualizar destinatário.');
        },
      });
  }

  redirect() {
    this.router
      .navigate([`configurations-shipper/recipients`])
      .catch((err) => err);
  }

  submitForm() {
    this.recipientForm.markAllAsTouched();
    if (this.recipientForm.invalid) {
      this._snackBar.openSnackBarWarning(
        'Há campos que necessitam de atenção.'
      );
      if (this.recipientForm.controls['name'].invalid) {
        this.nameRef.nativeElement.focus();
      } else if (this.recipientForm.controls['document'].invalid) {
        this.documentRef.nativeElement.focus();
      } else if (this.recipientForm.controls['zipcode'].invalid) {
        this.zipcodeRef.nativeElement.focus();
      } else if (this.recipientForm.controls['province'].invalid) {
        this.provinceRef.focus();
      } else if (this.recipientForm.controls['city'].invalid) {
        this.cityRef.nativeElement.focus();
      } else if (this.recipientForm.controls['street'].invalid) {
        this.streetRef.nativeElement.focus();
      } else if (this.recipientForm.controls['number'].invalid) {
        this.numberRef.nativeElement.focus();
      } else if (this.recipientForm.controls['neighborhood'].invalid) {
        this.neighborhoodRef.nativeElement.focus();
      }
      return;
    }

    const recipient = this.buildRecipient();
    if (this.id) {
      this.editRecipient(recipient);
    } else {
      this.recipientService
        .createRecipient(recipient)
        .pipe(first())
        .subscribe({
          next: () => {
            this._snackBar.openSnackBarSuccess(
              'Destinatário cadastrado com sucesso.'
            );
            setTimeout(() => {
              this.redirect();
            }, 1000);
          },
          error: () => {
            this._snackBar.openSnackBarError('Erro ao cadastrar destinatário.');
          },
        });
    }
  }
}
