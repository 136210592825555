import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, first, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  constructor(private http: HttpClient) {}

  enviarMensagem(mensagem: any): Observable<any> {
    return this.http
      .post<any>(`${environment.API_BACK}/chat/enviar`, mensagem)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  buscarMensagem(id: any, offerTyepe: any): Observable<any> {
    let params = new HttpParams();

    if (offerTyepe == 'contrato') {
      params = params.append('coleta', id);
    } else {
      params = params.append('lance', id);
    }

    const httpOptions = {
      params: params,
    };
    return this.http
      .get<any>(`${environment.API_BACK}/chat/mensagens`, httpOptions)
      .pipe(
        first(),
        map((data) => {
          return data;
        })
      );
  }

  getNotifications() {
    return this.http
      .get<any>(`${environment.API_BACK}/chat/notificacoes`)
      .pipe(
        first(),
        map((data) => {
          return data;
        })
      );
  }
}
