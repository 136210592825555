<div class="sidebar">
  <div id="logo">
    <div *ngIf="isCarrier">
      <a [routerLink]="[homeCarrier]">
        <img src="assets/img/svg/Box.svg" alt="logo" />
      </a>
    </div>
    <div *ngIf="isShipper">
      <a [routerLink]="[homeShipper]">
        <img src="assets/img/svg/Box.svg" alt="logo" />
      </a>
    </div>
  </div>
  <div id="primary-list">
    <mat-nav-list class="primary">
      <div *ngIf="isCarrier">
        <a
          id="shortcut-carrier-tutorials"
          mat-list-item
          [routerLink]="[carrierTutorials]"
          class="icons"
          placement="right"
          ngbTooltip="Tutoriais"
          ><img
            src="assets/img/svg/icons/graduation-cap.svg"
            alt=""
            width="25px"
        /></a>
        <a
        id="shortcut-dashboards"
        mat-list-item
        [routerLink]="[dashboardCarrier]"
        class="icons"
        placement="right"
        ngbTooltip="Dashboard"
        ><i class="ri-line-chart-line"></i
        ></a>
        <a
          id="shortcut-offers-carrier"
          mat-list-item
          [routerLink]="[offersCarrier]"
          class="icons"
          placement="right"
          ngbTooltip="Explorar ofertas"
          ><img src="assets/img/svg/icons/file-lines.svg" alt="" width="25px"
        /></a>
        <a
          id="shortcut-my-shippers"
          mat-list-item
          [routerLink]="[myShippers]"
          class="icons"
          placement="right"
          ngbTooltip="Meus embarcadores"
          ><img src="assets/img/svg/icons/building.svg" alt="" width="25px"
        /></a>
        <a
          id="shortcut-news-carrier"
          mat-list-item
          [routerLink]="[newsCarrier]"
          class="icons link-disabled"
          placement="right"
          ngbTooltip="Novidades"
          ><img src="assets/img/svg/icons/bullhorn.svg" alt="" width="25px" /></a>
          <a
            id="shortcut-portal"
            [hidden]="!viewPortal"
            mat-list-item
            (click)="loginPortal()"
            (keydown.enter)="loginPortal()"
            tabindex="0"
            class="icons"
            placement="right"
            ngbTooltip="Portal transportador"
            ><img src="assets/img/svg/icons/truck.svg" alt="" width="25px" /></a>
      </div>
      <div *ngIf="isShipper">
        <a
          id="shortcut-shipper-tutorials"
          mat-list-item
          [routerLink]="[shipperTutorials]"
          class="icons"
          placement="right"
          ngbTooltip="Tutoriais"
          [ngClass]="{ 'item-active': routePath === shipperTutorials}"
          ><img
            src="assets/img/svg/icons/graduation-cap.svg"
            alt=""
            width="25px"
        /></a>
        <a
        id="shortcut-dashboards"
        mat-list-item
        [routerLink]="[dashboardShipper]"
        class="icons"
        placement="right"
        ngbTooltip="Dashboard"
        ><i class="ri-line-chart-line"></i
        ></a>
        <a
          id="shortcut-new-offer"  
          mat-list-item
          [routerLink]="[offersShipper]"
          class="icons"
          placement="right"
          ngbTooltip="Oferta SPOT"
          [ngClass]="{ 'item-active': routePath === offersShipper}"
          ><i class="ri-newspaper-line"></i
        ></a>
        <a
          id="shortcut-new-contract-offer" 
          mat-list-item
          class="icons"
          placement="right"
          (click)="checkIntegrations()"
          ngbTooltip="Oferta contrato"
          [ngClass]="{ 'item-active': routePath === contractOffer}"
          tabindex="0"
          (keydown.enter)="checkIntegrations()"
          ><i class="ri-draft-line"></i
        ></a>
        <a
          id="shortcut-my-carriers"
          mat-list-item
          [routerLink]="[carriers]"
          class="icons"
          placement="right"
          ngbTooltip="Minhas transportadoras"
          [ngClass]="{ 'item-active': routePath === carriers}"
          ><img src="assets/img/svg/icons/truck.svg" alt="" width="25px"
        /></a>
        <a
          id="shortcut-my-offers"
          mat-list-item
          (click)="setTab()"
          [routerLink]="[allOffersShipper]"
          class="icons"
          placement="right"
          ngbTooltip="Minhas ofertas"
          [ngClass]="{ 'item-active': routePath === allOffersShipper}"
          ><i class="ri-shake-hands-line"></i
        ></a>
      </div>
      <a
        id="shortcut-payments"
        [hidden]="!viewPayments"
        mat-list-item
        [routerLink]="[payments]"
        class="icons"
        placement="right"
        ngbTooltip="Pagamentos"
        [ngClass]="{ 'item-active': routePath === payments}"
        ><img src="assets/img/svg/icons/credit-card.svg" alt="" width="25px"
      /></a>
    </mat-nav-list>
  </div>
  <div id="secondary-list">
    <mat-nav-list class="secondary">
      <div *ngIf="isCarrier">
        <a
         id="shortcut-configurations-carrier"
          mat-list-item
          [routerLink]="[configurationsCarrier]"
          class="icons"
          placement="right"
          ngbTooltip="Configurações"
          [ngClass]="{ 'item-active': routePath === configurationsCarrier}"
          ><i class="ri-settings-4-line"></i
        ></a>
      </div>
      <div *ngIf="isShipper">
        <a
          id="shortcut-configurations-shipper"
          mat-list-item
          [routerLink]="[configurationsShipper]"
          class="icons"
          placement="right"
          ngbTooltip="Configurações"
          [ngClass]="{ 'item-active': routePath === configurationsShipper}"
          ><i class="ri-settings-4-line"></i
        ></a>
      </div>
      <a
        id="shortcut-logout"
        mat-list-item
        href="#"
        (click)="logout()"
        class="icons"
        placement="right"
        ngbTooltip="Logout"
        ><img
          src="assets/img/svg/icons/arrow-right-from-bracket.svg"
          alt=""
          width="25px"
      /></a>
    </mat-nav-list>
  </div>
</div>
