import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ConfigurationContractOfferService } from '../../../../core/services/configurations/configurations-contract-offer.service';
import { first } from 'rxjs';
import { SnackbarDefaultService } from 'src/app/core/services/utils/snackbar-default.service';
import { ClientService } from 'src/app/core/services/shared/client.service';

enum ProcessType {
  'Automatico' = 'AUTOMATICO',
  'Manual' = 'MANUAL',
}

@Component({
  selector: 'app-offer-contract',
  templateUrl: './offer-contract.component.html',
  styleUrls: ['./offer-contract.component.scss'],
})
export class OfferContractComponent implements OnInit {

  ngOnInit(): void {
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
    this.getCliente();
  }

  edit = false;
  processType='';
  attempts=1;
  usuario: any;
  contractOfferForm: FormGroup;
  loadingSpinner = false;
  loaded = true;

  processTypeOptions = [
    { label: 'AUTOMÁTICO', value: ProcessType.Automatico },
    { label: 'MANUAL', value: ProcessType.Manual },
  ];

  constructor(
    private readonly fb: FormBuilder,
    private readonly configContractOfferService: ConfigurationContractOfferService,
    private readonly clientService: ClientService,
    private readonly _snackBar: SnackbarDefaultService,

  ) {
    this.contractOfferForm = this.fb.group({
      processType: [ProcessType.Manual, [Validators.required]],
      attempts: [1,[Validators.required,Validators.min(1)]]
    }, {
      validators: [this.attemptsRequiredValidator()]
    });
  }

  attemptsRequiredValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const processType = control.get('processType').value;
      const attempts = control.get('attempts').value;
  
      if (processType === ProcessType.Manual) {
        return null;
      }
      if (processType === ProcessType.Automatico && (!attempts || attempts < 1)) {
        return { attemptsRequired: true };
      }
  
      return null;
    };
  }

  checkAttempts(value: string) {
    console.log(value)
    this.contractOfferForm.get('attempts').setValue(1);
    }

    showAttempts(){
      if(this.edit){
        return this.contractOfferForm.get('processType')?.value == ProcessType.Automatico
      }
      return this.processType == ProcessType.Automatico
    }


  submitForm() {
    const configContractoOffer: any = {
      tipoProcesso: this.contractOfferForm.get('processType')?.value,
      qtdTentativas: this.contractOfferForm.get('attempts')?.value,
    };

    this.loadingSpinner = true;

    this.configContractOfferService
      .saveConfigContractOffer(configContractoOffer)
      .pipe(first())
      .subscribe({
        next: () => {
          this._snackBar.openSnackBarSuccess(
            'Configuração de Oferta Contrato Salva com Sucesso!'
          );
          this.loadingSpinner = false;
          this.getCliente();
        },
        error: () => {
          this._snackBar.openSnackBarErrorWithDuration(
            `Erro ao salvar Configuração de Cadastro`,
            10000
          );
          this.loadingSpinner = false;
        },
      });
  }

  getCliente() {
    this.loaded = false;
    this.clientService
      .getClient(this.usuario.cliente.id)
      .pipe(first())
      .subscribe({
        next: (data) => {
          this.fillinFields(data);
          this.loaded = true;
        },
        error: (error) => {
          console.error(error);
          this.loaded = true
        },
      });
  }
  private fillinFields(data: any) {
    this.contractOfferForm.get('processType').setValue(data.tipoProcesso);
    this.processType = data.tipoProcesso
    this.contractOfferForm.get('attempts').setValue(data.qtdTentativas);
    this.attempts = data.qtdTentativas
    this.edit = false;
  }
}
