import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-public-layout',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss'],
})
export class PublicLayoutComponent implements OnInit {
  routePath = ''

  ngOnInit(): void {
    this.routePath = window.location.pathname
  }
}
