import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CepPipe } from './cep.pipe';
import { CnpjPipe } from './cnpj.pipe';
import { FormatStringPipe } from './formatString.pipe';
import { PhonePipe } from './phone.pipe';
import { ChatFormmatDatePipe } from './chat.formmatDate.pipe';



@NgModule({
  declarations: [CepPipe, CnpjPipe, FormatStringPipe, PhonePipe, ChatFormmatDatePipe],
  exports: [CepPipe, CnpjPipe, FormatStringPipe, PhonePipe, ChatFormmatDatePipe],
  imports: [
    CommonModule
  ]
})
export class PipesModule { }