import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { first } from 'rxjs';
import { MaskService } from 'src/app/core/services/utils/mask.service';
import { SnackbarDefaultService } from 'src/app/core/services/utils/snackbar-default.service';
import { RedefinePasswordDialogComponent } from 'src/app/shared/components/redefine-password-dialog/redefine-password-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { ImageService } from 'src/app/core/services/utils/image.service';
import { UserService } from 'src/app/core/services/shared/user.service';

export interface HeaderTable {
  lbCorporateName: string;
  lbCnpj: string;
  lbAddress: string;
}

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss'],
})
export class MyProfileComponent implements OnInit, AfterViewInit {
  public profileForm: FormGroup = this.formBuilder.group({
    user: ['', Validators.required],
    email: ['', Validators.compose([Validators.required, Validators.email])],
    phone: ['', Validators.required],
  });

  displayedColumns: string[] = ['corporateName', 'cnpj', 'address'];
  dataSource = new MatTableDataSource();

  options: string[] = ['Editar'];
  usuario: any;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  page = 0;
  size = 50;
  totalElements = 0;
  gridData: any = [];
  loadingSpinner = false;
  perfilImage: string;
  imgTemp:string;

  @ViewChild('userRef') userRef: ElementRef;
  @ViewChild('phoneRef') phoneRef: ElementRef;

  usuarioAtual = JSON.parse(localStorage.getItem('usuario'));

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  headerTable: HeaderTable = {
    lbCorporateName: 'Razão Social',
    lbCnpj: 'CNPJ',
    lbAddress: '	Endereço',
  };

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly userService: UserService,
    private readonly snackBar: SnackbarDefaultService,
    private readonly maskService: MaskService,
    private readonly dialog: MatDialog,
    private readonly imageService: ImageService
  ) {}

  ngOnInit(): void {
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
    this.profileForm.get('user').setValue(this.usuario.nome);
    this.profileForm.get('email').setValue(this.usuario.email);
    this.profileForm.get('phone').setValue(this.usuario.telefone);

    this.getEmbarcadoresAssociados();

    if (this.usuario.imagem === undefined || this.usuario.imagem === null) {
      this.perfilImage = 'assets/img/svg/abstract-user-flat-grey.svg';
    } else {
      this.perfilImage = this.usuario.imagem?.includes(environment.AWS_S3_ASSETS) ? this.usuario.imagem : environment.AWS_S3_ASSETS+ this.usuario.imagem;
    }
  }

  public submitForm() {
    this.profileForm.markAllAsTouched();

    if (this.profileForm.invalid) {
      this.snackBar.openSnackBarWarning('Há campos que necessitam de atenção');
      if (this.profileForm.controls['user'].invalid) {
        this.userRef.nativeElement.focus();
      } else if (this.profileForm.controls['phone'].invalid) {
        this.phoneRef.nativeElement.focus();
      }
      return;
    }

    const perfil = {
      id: this.usuario.id,
      nome: this.profileForm.get('user').value,
      telefone: this.profileForm.get('phone').value,
    };

    this.loadingSpinner = true;

    this.userService
      .updateUser(perfil)
      .pipe(first())
      .subscribe({
        next: () => {
          this.userService
            .getUser(perfil)
            .pipe(first())
            .subscribe({
              next: (data: any) => {
                this.usuario.nome = data.nome;
                this.usuario.telefone = data.telefone;
                this.usuario.imagem = this.imgTemp || null;
                localStorage.setItem('usuario', JSON.stringify(this.usuario));
                this.snackBar.openSnackBarSuccess(
                  'Dados alterados com sucesso'
                );
              },
              error: (error) => {
                console.error(error);
              },
            });
          this.loadingSpinner = false;
        },
        error: (error) => {
          console.error(error);
          this.snackBar.openSnackBarError('Erro ao alterar dados' + error);
          this.loadingSpinner = false;
        },
      });
  }

  changePassword() {
    this.dialog.open(RedefinePasswordDialogComponent, { disableClose: true });
  }

  public getEmbarcadoresAssociados() {
    const size = this.size;
    const page = this.page;

    const paramsObj = {
      page,
      size,
    };

    this.loadingSpinner = true;

    this.userService
      .getShipperByUser(paramsObj)
      .pipe(first())
      .subscribe({
        next: (dados) => {
          this.gridData = dados.content?.map((shipper: any) => ({
            corporateName: shipper.empresa.razaoSocial.toUpperCase(),
            cnpj: this.maskService.formatCNPJ(shipper.empresa.cnpj),
            address: this.getAddress(shipper.empresa),
          }));
          this.totalElements = dados.totalElements;
          this.dataSource = new MatTableDataSource(this.gridData);
          this.loadingSpinner = false;
        },
        error: (error) => {
          console.error(error);
          this.loadingSpinner = false;
        },
      });
  }

  public getAddress(address: any) {
    if (address && address.cidade && address.uf) {
      return `${address.cidade} - ${address.uf}`;
    } else {
      return ' - ';
    }
  }

  public handlePageEvent(e: PageEvent) {
    if (this.size != e.pageSize) {
      this.page = 0;
    } else {
      this.page = e.pageIndex;
    }
    this.size = e.pageSize;
    this.getEmbarcadoresAssociados();
  }

  updateFile(event: any): void {
    const fileList: FileList = event.target.files;

    if (!fileList || fileList.length === 0) {
      console.error('Nenhum arquivo foi selecionado.');
      return;
    }

    const file: File = fileList[0];
    const fileType: string = file.type;
    const fileSize: number = file.size;
    const maxSize: number = 5 * 1024 * 1024; // 5MB

    // Validação do tamanho e tipo do arquivo
    if (fileSize > maxSize) {
      console.error(
        'O arquivo selecionado é muito grande. Por favor, selecione um arquivo menor que 5MB.'
      );
      this.snackBar.openSnackBarError(
        'O arquivo selecionado é muito grande. Por favor, selecione um arquivo menor que 5MB.'
      );
      return;
    }

    if (fileType !== 'image/png' && fileType !== 'image/jpeg') {
      console.error('Por favor, selecione uma imagem no formato PNG ou JPEG.');
      this.snackBar.openSnackBarError(
        'Por favor, selecione uma imagem no formato PNG ou JPEG.'
      );
      return;
    }

    // Leitura do arquivo e envio para o serviço
    const reader = new FileReader();
    this.loadingSpinner = true;
    reader.onload = () => {
      this.imageService
        .uploadImagemPerfil(file)
        .pipe(first())
        .subscribe({
          next: (res) => {
            this.imgTemp = res.imagem;
            this.usuarioAtual.imagem = res.imagem;
            localStorage.setItem('usuario', JSON.stringify(this.usuarioAtual));
            this.snackBar.openSnackBarSuccess('Imagem alterada com sucesso.');
            this.perfilImage = environment.AWS_S3_ASSETS + res.imagem;
            this.loadingSpinner = false;
          },
          error: (err) => {
            console.error('Erro ao fazer upload da imagem:', err);
            this.snackBar.openSnackBarError(
              'Erro ao alterar a imagem. Tente novamente.'
            );
            this.loadingSpinner = false
          },
        });
    };

    reader.onerror = () => {
      console.error('Erro ao ler o arquivo. Por favor, tente novamente.');
      this.snackBar.openSnackBarError(
        'Erro ao processar o arquivo. Tente novamente.'
      );
    };

    reader.readAsDataURL(file);
  }
}
