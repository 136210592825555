<div class="container-requirements">
  <mat-dialog-title> <h6 class="mt-2">Escolha a carga</h6></mat-dialog-title>
  <mat-dialog-content class="mt-3">
    <div class="mat-table-container">
    <table mat-table [dataSource]="dataSource" class="mat-table">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef class="table-head-color"></th>
        <td mat-cell *matCellDef="let row; let i = index" class="table-row">
          <mat-checkbox
            (change)="selectItem(row)"
            [checked]="row.selected"
            id="checkbox-default-loadType-{{ i }}"
          ></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="loadType">
        <th mat-header-cell *matHeaderCellDef class="table-head-color" id="header-table-default-loadType">
          Cargas
        </th>
        <td mat-cell *matCellDef="let element" class="table-row">
          {{ element.label }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index" id="row-default-loadType-{{i}}"></tr>
    </table>
    </div>
    <mat-paginator
      [pageSizeOptions]="[5, 10, 20, 50, 100]"
      showFirstLastButtons
      aria-label="Select page of periodic elements"
      (page)="handlePageEvent($event)"
      [length]="totalElements"
      [pageIndex]="page"
      [pageSize]="size"
    >
    </mat-paginator>
  </mat-dialog-content>
  <div class="d-flex flex-row-reverse mt-3">
    <div mat-dialog-actions>
      <button
        mat-flat-button
        mat-dialog-close
        class="btn-grey"
        (click)="close()"
        id="btn-close-default-loadType"
      >
        Cancelar
      </button>
      <button
        mat-flat-button
        cdkFocusInitial
        class="btn-green"
        (click)="confirm()"
        id="btn-confirm-default-loadType"
      >
        Confirmar
      </button>
    </div>
  </div>
</div>
