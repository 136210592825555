import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, first, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RecipientService {
  constructor(private readonly http: HttpClient) {}

  createRecipient(obj: any) {
    return this.http
      .post<any>(`${environment.API_BACK}/destinatario`, obj)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  getRecipients(obj: any) {
    let params = new HttpParams();

    if (obj.document) {
      params = params.append('documento', String(obj.document));
    }

    if(obj.name){
      params = params.append('nome', String(obj.name));
    }

    params = params.append('size', String(obj.size));
    params = params.append('page', String(obj.page));

    return this.http
      .get<any>(`${environment.API_FILTER}/destinatario`, { params: params })
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  getRecipientById(id: any) {
    return this.http
      .get<any>(`${environment.API_FILTER}/destinatario?id=${id}`)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  deteleteRecipient(id: number) {
    let params = new HttpParams();
    params = params.append('id', id);

    const httpOptions = {
      params: params,
    };

    return this.http
      .delete(`${environment.API_BACK}/destinatario`, httpOptions)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        }),
        catchError((error) => {
          throw new Error(error);
        })
      );
  }

  importRecipientCsv(file: any) {
    const form: FormData = new FormData();
    form.append('file', file);
    const headers = new HttpHeaders();
    return this.http
      .post<any>(`${environment.API_BACK}/destinatario/import`, form, {
        headers,
      })
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }
}
