import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { first } from 'rxjs';
import { ClientService } from 'src/app/core/services/shared/client.service';

@Component({
  selector: 'app-environment-rules',
  templateUrl: './environment-rules.component.html',
  styleUrls: ['./environment-rules.component.scss'],
})
export class EnvironmentRulesComponent implements OnInit {
  public rulesForm: FormGroup;
  public user = JSON.parse(localStorage.getItem('usuario'));
  offerApproval = false;

  loaded = true;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly clientService: ClientService,
    private readonly cdr: ChangeDetectorRef
  ) {
    this.rulesForm = this.formBuilder.group({
      offerApproval: [''],
    });
  }

  ngOnInit(): void {
    this.getClient();
    this.cdr.detectChanges();
  }

  public getClient() {
    this.loaded = false;
    this.clientService
      .getClient(this.user.cliente.id)
      .pipe(first())
      .subscribe({
        next: (client) => {
          this.loaded = true;
          this.offerApproval = client.aprovacaoSemRegras;
          setTimeout(() => {
            this.setOfferApproval();
          }, 300);
          this.cdr.detectChanges();
        },
        error: (error) => {
          console.error(error);
          this.loaded = true;
        },
      })
  }

  setOfferApproval() {
    const offerApprovalYesElement = document.getElementById(
      'offerApprovalYes'
    ) as HTMLInputElement;
    const offerApprovalNoElement = document.getElementById(
      'offerApprovalNo'
    ) as HTMLInputElement;

    if (this.offerApproval) {
      offerApprovalYesElement.checked = true;
    } else {
      offerApprovalNoElement.checked = true;
    }

    offerApprovalYesElement.disabled = true;
    offerApprovalNoElement.disabled = true;
  }
}
