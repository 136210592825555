import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from './core/services/auth/auth.service';
import { PermissaoService } from './core/services/auth/permissao.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isAdmin = false;

  constructor(
    private readonly router: Router,
    private readonly permissaoService: PermissaoService,
    private readonly authService: AuthService,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (!RegExp(/\/freight-offer/).exec(this.router.url)) {
          localStorage.removeItem('filter_spot');
          localStorage.removeItem('filter_order');
          localStorage.removeItem('filter_contract');
        }

        if (!/explore-carriers|my-carriers/.test(this.router.url)) {
          let data = localStorage.getItem('myCarriersAdvancedFilterData');
          if (data) {
            localStorage.removeItem('myCarriersAdvancedFilterData');
          }
          data = localStorage.getItem('explorerCarriersAdvancedFilterData');
          if (data) {
            localStorage.removeItem('explorerCarriersAdvancedFilterData');
          }
        }

        if (!RegExp(/\/portal/).exec(this.router.url)) {
          localStorage.removeItem('filtroCTE');
        }

        if(!RegExp(/(?:^|\/)invoice-extract(?:\/|$)/).exec(this.router.url)) {
          localStorage.removeItem('filter-invoice');
        }
      }
    });
  }

  ngOnInit(): void {
    const route = window.location.pathname;
    sessionStorage.setItem('rota', route);
    const authToken = this.authService.getToken();

    if (
      (RegExp(/\/shipper/).exec(route) ||
        RegExp(/\/carrier/).exec(route) ||
        RegExp(/\/configurations-shipper/).exec(route) ||
        RegExp(/\/configurations-carrier/).exec(route)) &&
      !authToken
    ) {
      this.router.navigate(['/login']);
    }

    if (authToken) {
      this.isAdmin = this.permissaoService.isAdmin();
      if (!this.isAdmin && RegExp(/\/integrations/).exec(route)) {
        this.router.navigate(['/shipper']);
      }
    }
  }
}
