<div class="container-company card-container" id="dialof-company-created">
    <mat-dialog-title>
      <div class="d-flex">
        <div class="flex-grow-1">
          <h6 class="mt-2" id="company-created-title">Empresa criada com sucesso</h6>
        </div>
        <div style="margin-top: 3px;">
          <button
            id="close-dialog-company-created"
            class="ri-close-line icon-close"
            (click)="redirect('close')"
            aria-label="Close dialog"
            style="background: none; border: none; cursor: pointer;"
          ></button>
        </div>
      </div>
    </mat-dialog-title>
    <mat-dialog-content>
      <div fxLayout="row" fxLayoutAlign="center">
        <mat-card class="container-information">
          <mat-card-content style="text-align: center">
            <div fxLayoutAlign="center" style="margin-top: 10px;">
                <div fxLayout="column"><i class="ri-building-4-line icon" style="margin-right: 10px; margin-top: -1px;"></i></div>
                <div fxLayout="column"x>
                    <p class="informations-content">{{ companyName }}</p>
                </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </mat-dialog-content>
    <div style="display: flex; justify-content: center" class="mt-3">
      <p class="description">O que gostaria de fazer agora?</p>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between" style="padding: 0.8rem">
      <div fxLayout="column" fxFlex class="margin-right">
        <button
          id="btn-home"
          mat-flat-button
          class="btn-green"
          (click)="redirect('home')"
        >
          Tela inicial
        </button>
      </div>
      <div fxLayout="column" fxFlex class="margin-left margin-right">
        <button
          id="btn-new-company"
          mat-flat-button
          class="btn-green"
          (click)="redirect('new company')"
        >
          Nova empresa
        </button>
      </div>
      <div fxLayout="column" fxFlex class="margin-left">
        <button
          id="btn-company-edit"
          mat-flat-button
          class="btn-green"
          (click)="redirect('edit company')"
        >
          Completar cadastro
        </button>
      </div>
    </div>
  </div>
  