<div class="container-page-title">
  <div class="pageTitleBox" *ngIf="!isHome; else isHomeTemplate">
    <ol class="breadcrumb">
      <li class="description">
        <a *ngIf="isCarrier; else shipperLink" routerLink="/carriers" id="link-home">Home</a>
        <ng-template #shipperLink>
          <a routerLink="/shipper" id="link-home">Home</a>
        </ng-template>
      </li>
      <li
        class="description"
        *ngFor="let item of paths; let i = index"
        [ngClass]="{ item: item.label }"
      >
        <a
          [routerLink]="[item.path]"
          [ngClass]="paths.length - 1 === i ? 'breadcrumb-senior' : ''"
          id="breadcrumb-{{item.path}}"
        >
          {{ item.label }}
        </a>
      </li>
    </ol>
    <div class="mt-4">
      <div fxLayout="row" fxLayoutAlign="start">
        <div fxLayout="column" class="div-icon" *ngIf="btnGoBack">
          <button
            class="ri-arrow-left-line icon-arrow"
            (click)="back()"
            id="btn-back"
            aria-label="Go back"
          ></button>
        </div>
        <div fxLayout="column">
          <h4 class="title">
            {{ title }}
          </h4>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #isHomeTemplate>
  <div class="pageTitleBox">
    <div>
      <div class="row justify-content-start">
        <div class="col col-md-1 div-icon" *ngIf="btnGoBack">
          <button class="ri-arrow-left-line icon-arrow" (click)="back()" id="btn-back" aria-label="Go back" style="background: transparent;"></button>
        </div>
        <div class="col">
          <h4 class="title top">Olá, {{ userName }}</h4>
          <h6 class="subtitle">Acompanhe as novidades na plataforma!</h6>
        </div>
      </div>
    </div>
  </div>
</ng-template>
