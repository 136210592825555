import { ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { Location } from '@angular/common';

interface BreadCrumbItem {
  label: string;
  path: string;
}

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss'],
})
export class PageTitleComponent implements OnChanges {
  @Input()
  paths: BreadCrumbItem[] = [];
  @Input()
  title = '';
  @Input()
  isHome = false;
  btnGoBack = false;
  routePath = '';
  userName = JSON.parse(localStorage.getItem("usuario") ?? "" ).nome;
  user = JSON.parse(localStorage.getItem("usuario") ?? "" );
  isCarrier = this.user.cliente?.ofertaLance;

  constructor(private readonly location: Location, private readonly cdr: ChangeDetectorRef) {}

  ngOnChanges(): void {
    this.routePath = window.location.pathname;
    const currentPaths = String(this.routePath)
      .split('/')
      .filter((s: string) => s);

    if (currentPaths.length > 1) {
      this.btnGoBack = true;
    } else {
      this.btnGoBack = false;
    }
    this.cdr.detectChanges();
  }

  back(): void {
    this.location.back();
  }
}
