import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, first, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Sort } from '@angular/material/sort';
import { SortUtil } from 'src/app/core/utils/sortUtil';
import { of } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class InvitationsService {
  constructor(private readonly http: HttpClient, private readonly sortUtil: SortUtil) {}

  ordenarSort: Sort = { active: 'dataUltimoEnvio', direction: 'desc' };
  ordenarParam = this.ordenarSort.active + ',' + this.ordenarSort.direction;

  enviaConvite(obj: any) {
    return this.http
      .post<any>(`${environment.API_BACK}/convite/novo-parceria`, obj)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  declineInvitation(row: any) {
    const conviteId = row.id;
    return this.http
      .post<any>(`${environment.API_BACK}/convite/recusa`, conviteId)
      .pipe(
        first(),
        map((data) => {
          return data;
        })
      );
  }

  acceptInvitation(row: any) {
    const conviteId = row.id;

    return this.http
      .post<any>(`${environment.API_BACK}/convite/aceita`, conviteId)
      .pipe(
        first(),
        map((data) => {
          return data;
        })
      );
  }

  getConvites(obj: any) {
    let params = new HttpParams();
    if (obj.id != undefined) {
      params = params.append('id', obj.id);
    } else {
      params = params.append('size', String(obj.size));
      params = params.append('page', String(obj.page));
    }
    if (obj.fantasyNameCarrier != null) {
      params = params.append('nomeFantasia', obj.fantasyNameCarrier);
    }
    if (obj.statusCarrier != null) {
      params = params.append('statusTransportadora', obj.statusCarrier);
    }

    if (obj.ordenar === true) {
      params = params.append(
        'sort',
        this.sortUtil.order('dataUltimoEnvio', 'desc')
      );
    }

    const httpOptions = {
      params: params,
    };
    return this.http
      .get<any>(`${environment.API_FILTER}/convite`, httpOptions)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  reenvioConvite(idConvite: any, tokenRecaptcha: string) {
    const headers = new HttpHeaders();
    const formData = new FormData();
    formData.append('conviteId', idConvite);
    formData.append('tokenRecaptcha', tokenRecaptcha);

    return this.http
      .post<any>(`${environment.API_BACK}/convite/reenviar`, formData, {
        headers,
        responseType: 'text' as 'json',
      })
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  getLinkConvite(idConvite: any) {
    const httpOptions = {
      responseType: 'text' as 'json',
    };
    return this.http
      .get<any>(
        `${environment.API_BACK}/convite/get-link/${idConvite}`,
        httpOptions
      )
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  criaConvite(obj: any) {
    return this.http
      .post<any>(`${environment.API_BACK}/convite/novo-usuario`, obj)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  sendCarrierInvitation(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return this.http
      .post<any>(
        `${environment.API_BACK}/convite/novo-usuario/arquivo`,
        formData
      )
      .pipe(
        first(),
        map((dados) => {
          return dados;
        }),
        catchError((error) => {
          throw new Error(error);
        })
      );
  }

  removeInvitation(listInvites: any) {
    const payload = { idsConvite: listInvites };

    return this.http
      .delete<any>(`${environment.API_BACK}/convite/remove`, {
        body: payload,
      })
      .pipe(
        first(),
        map((dados) => dados),
        catchError((error) => {
          return of(error);
        })
      );
  }

  registerCarrier(
    carrier: any,
    hash: string,
    clientId: string,
    carrierId: string,
    file: any
  ) {
    const form: FormData = new FormData();
    form.append('file', file);

    const headers = new HttpHeaders();
    headers.append('responseType', 'text');
    headers.append('Content-Type', 'multipart/form-data');
    form.append('entityDTO', JSON.stringify(carrier));

    return this.http
      .post<any>(
        `${environment.API_BACK}/convite/convite-aceito/${hash}/${clientId}/${carrierId}`,
        form
      )
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }
}
