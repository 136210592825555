import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, first, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SuppliersService {
  constructor(private readonly http: HttpClient) {}

  createSupplier(obj: any) {
    return this.http
      .post<any>(`${environment.API_BACK}/cdfornecedor`, obj)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  getSuppliers(obj: any) {
    let params = new HttpParams();

    if (obj.document) {
      params = params.append('cnpj', String(obj.document));
    }

    if(obj.name){
      params = params.append('razaoSocial', String(obj.name));
    }

    params = params.append('size', String(obj.size));
    params = params.append('page', String(obj.page));

    return this.http
      .get<any>(`${environment.API_FILTER}/cdfornecedor`, { params: params })
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  getSupplierById(id: any) {
    return this.http
      .get<any>(`${environment.API_FILTER}/cdfornecedor?id=${id}`)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  deteleteSupplier(id: number) {
    let params = new HttpParams();
    params = params.append('id', id);

    const httpOptions = {
      params: params,
    };

    return this.http
      .delete(`${environment.API_BACK}/cdfornecedor`, httpOptions)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        }),
        catchError((error) => {
          throw new Error(error);
        })
      );
  }
}
