import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UFs, setor_atuacao_options } from 'src/app/core/models/consts';
import { SnackbarDefaultService } from 'src/app/core/services/utils/snackbar-default.service';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs';
import { NgSelectComponent } from '@ng-select/ng-select';
import { SuppliersService } from 'src/app/core/services/shared/supplier.service';
import { CepService } from 'src/app/core/services/utils/cep.service';

@Component({
  selector: 'app-my-supplier-form',
  templateUrl: './my-supplier-form.component.html',
  styleUrls: ['./my-supplier-form.component.scss'],
})
export class MySupplierFormComponent implements OnInit {
  supplierForm: FormGroup;
  editSupplier = false;
  id: any;
  activitySectorOptions = setor_atuacao_options;
  loadingSpinner = false;
  UFs = UFs;

  @ViewChild('documentRef') documentRef: ElementRef;
  @ViewChild('legalNameRef') legalNameRef: ElementRef;
  @ViewChild('zipocodeRef') zipocodeRef: ElementRef;
  @ViewChild('provinceRef') provinceRef: NgSelectComponent;
  @ViewChild('cityRef') cityRef: ElementRef;
  @ViewChild('streetRef') streetRef: ElementRef;
  @ViewChild('numberRef') numberRef: ElementRef;
  @ViewChild('neighborhoodRef') neighborhoodRef: ElementRef;
  @ViewChild('activitySectorRef') activitySectorRef: NgSelectComponent;

  constructor(
    private readonly fb: FormBuilder,
    private readonly cepService: CepService,
    private readonly suppliersService: SuppliersService,
    private readonly _snackBar: SnackbarDefaultService,
    private readonly route: ActivatedRoute,
    private readonly router: Router
  ) {
    this.supplierForm = this.fb.group({
      document: ['', [Validators.required]],
      legalName: ['', [Validators.required]],
      zipcode: ['', [Validators.required]],
      province: ['', [Validators.required]],
      city: ['', [Validators.required]],
      street: ['', [Validators.required]],
      number: ['', [Validators.required]],
      neighborhood: ['', [Validators.required]],
      complement: [''],
      activitySector: ['', [Validators.required]],
    });

    this.supplierForm.controls['zipcode'].valueChanges.subscribe(
      (value: string) => this.onZipcodeChange(value)
    );
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];

    if (this.id != undefined && this.id != null) {
      this.getSupplier();
    }
  }

  onZipcodeChange(value: string) {
    if (value?.length < 8) return;
    this.cepService.consultaCep(value)
    .pipe(first())
    .subscribe({
      next: (data: any) => {
        const addressObj = {
          province: data.uf,
          city: data.localidade,
          street: data.logradouro,
          complement: data.complemento,
          neighborhood: data.bairro,
        };
        this.supplierForm.patchValue(addressObj);
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  private buildSupplier() {
    const listaVazia: any[] = [];
    return {
      contatoReferencia: listaVazia,
      matriz: false,
      cnpj: this.supplierForm.value.document,
      razaoSocial: this.supplierForm.value.legalName,
      bairro: this.supplierForm.value.neighborhood,
      uf: this.supplierForm.value.province,
      cidade: this.supplierForm.value.city,
      cep: this.supplierForm.value.zipcode,
      logradouro: this.supplierForm.value.street,
      numero: this.supplierForm.value.number,
      complemento: this.supplierForm.value.complement,
      setorAtuacao: this.supplierForm.value.activitySector,
    };
  }

  private fillinFields(data: any) {
    this.supplierForm.get('document').setValue(data.cnpj);
    this.supplierForm.get('legalName').setValue(data.razaoSocial);
    this.supplierForm.get('zipcode').setValue(data.cep);
    this.supplierForm.get('province').setValue(data.uf);
    this.supplierForm.get('city').setValue(data.cidade);
    this.supplierForm.get('street').setValue(data.logradouro);
    this.supplierForm.get('number').setValue(data.numero);
    this.supplierForm.get('neighborhood').setValue(data.bairro);
    this.supplierForm.get('complement')?.setValue(data.complemento);
    this.supplierForm.get('activitySector')?.setValue(data.setorAtuacao);

    let setor = this.activitySectorOptions.find(
      (option) => option.value === data.setorAtuacao
    );
    if (!setor) {
      setor = this.activitySectorOptions.find(
        (option) => option.value === 'naoInformado'
      );
    }
    this.supplierForm.get('activitySector')?.setValue(setor.value);
  }

  getSupplier() {
    this.loadingSpinner = true;
    this.suppliersService
      .getSupplierById(this.id)
      .pipe(first())
      .subscribe({
        next: (data) => {
          this.fillinFields(data);
          this.loadingSpinner = false;
        },
        error: (error) => {
          console.error(error);
          this.loadingSpinner = false
        },
      })
  }

  updateSupplier(supplier: object) {
    this.supplierForm.markAllAsTouched();
    if (this.supplierForm.invalid) {
      this._snackBar.openSnackBarWarning('Há campos que necessitam de atenção.');
      if(this.supplierForm.controls['document'].invalid) {
        this.documentRef.nativeElement.focus();
      } else if(this.supplierForm.controls['legalName'].invalid) {
        this.legalNameRef.nativeElement.focus();
      } else if(this.supplierForm.controls['zipcode'].invalid) {
        this.zipocodeRef.nativeElement.focus();
      } else if(this.supplierForm.controls['province'].invalid) {
        this.provinceRef.focus();
      } else if(this.supplierForm.controls['city'].invalid) {
        this.cityRef.nativeElement.focus();
      } else if(this.supplierForm.controls['street'].invalid) {
        this.streetRef.nativeElement.focus();
      } else if(this.supplierForm.controls['number'].invalid) {
        this.numberRef.nativeElement.focus();
      } else if(this.supplierForm.controls['neighborhood'].invalid) {
        this.neighborhoodRef.nativeElement.focus();
      } else if(this.supplierForm.controls['activitySector'].invalid) {
        this.activitySectorRef.focus();
      }
      return;
    }
    this.suppliersService
      .createSupplier(Object.assign(supplier, { id: this.id }))
      .pipe(first())
      .subscribe({
        next: () => {
          this._snackBar.openSnackBarSuccess(
            'fornecedor atualizado com sucesso.'
          );
          setTimeout(() => {
            this.redirect();
          }, 1000);
        },
        error: (e) => {
          this._snackBar.openSnackBarError(
            'Erro ao atualizar fornecedor - ' + e.error
          );
        },
      });
  }

  submitSupplierData() {
    this.supplierForm.markAllAsTouched();
    if (this.supplierForm.invalid) {
      this._snackBar.openSnackBarWarning('Há campos que necessitam de atenção.');
      if(this.supplierForm.controls['document'].invalid) {
        this.documentRef.nativeElement.focus();
      } else if(this.supplierForm.controls['legalName'].invalid) {
        this.legalNameRef.nativeElement.focus();
      } else if(this.supplierForm.controls['zipcode'].invalid) {
        this.zipocodeRef.nativeElement.focus();
      } else if(this.supplierForm.controls['province'].invalid) {
        this.provinceRef.focus();
      } else if(this.supplierForm.controls['city'].invalid) {
        this.cityRef.nativeElement.focus();
      } else if(this.supplierForm.controls['street'].invalid) {
        this.streetRef.nativeElement.focus();
      } else if(this.supplierForm.controls['number'].invalid) {
        this.numberRef.nativeElement.focus();
      } else if(this.supplierForm.controls['neighborhood'].invalid) {
        this.neighborhoodRef.nativeElement.focus();
      } else if(this.supplierForm.controls['activitySector'].invalid) {
        this.activitySectorRef.focus();
      }
      return;
    }

    const supplier = this.buildSupplier();

    if (this.id) {
      this.updateSupplier(supplier);
    } else {
      this.suppliersService
        .createSupplier(supplier)
        .pipe(first())
        .subscribe({
          next: () => {
            this._snackBar.openSnackBarSuccess(
              'Fornecedor cadastrado com sucesso.'
            );
            setTimeout(() => {
              this.redirect();
            }, 1000);
          },
          error: (error) => {
            console.log(error)
            this._snackBar.openSnackBarError(`Erro ao cadastrar fornecedor - ${error.error}.`);
          },
        });
    }
  }

  public redirect() {
    this.router
      .navigate([`configurations-shipper/suppliers`])
      .catch((err) => err);
  }
}
