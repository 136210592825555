import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, first, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  constructor(private readonly http: HttpClient) {}

  getClient(id: string) {
    return this.http
      .get<any>(`${environment.API_FILTER}/cliente?id=${id}`)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  getTotalOffersByClient(){
    return this.http
    .get<any>(`${environment.API_FILTER}/cliente/total-ofertas`)
    .pipe(
      first(),
      map((dados) => {
        return dados;
      }),
      catchError((error) => {
        throw new Error(error);
      })
    );
  }

}