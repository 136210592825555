import { Component, Inject, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import {
  modalidade_frete_options,
  veiculo_options,
  carga_options,
  uf_options_requisitos,
} from 'src/app/core/models/consts';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, first } from 'rxjs';
import { SnackbarDefaultService } from '../../../core/services/utils/snackbar-default.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CompaniesService } from 'src/app/core/services/shared/companies.service';

export interface HeaderTable {
  columnA: string;
  columnB: string;
}

@Component({
  selector: 'app-dialog-requirements-register',
  templateUrl: './dialog-requirements-register.component.html',
  styleUrls: ['./dialog-requirements-register.component.scss'],
})
export class DialogRequirementsRegisterComponent implements OnInit, AfterViewInit {
  type = '';
  title = '';
  columnA = '';
  id: any;
  vehicles: any;
  visibleTable = false;
  visibleQuantity = true;

  page = 0;
  size = 50;
  totalElements = 0;
  dados: any[];

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  displayedColumns: string[] = ['select', 'description'];

  selection = new SelectionModel<any>(true, []);

  public searchForm: FormGroup = this.formBuilder.group({
    search: [null],
  });

  public quantityForm: FormGroup = this.formBuilder.group({
    quantity: [''],
    veiculos: this.formBuilder.array([])
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly formBuilder: FormBuilder,
    private readonly companiesService: CompaniesService,
    private readonly _snackBar: SnackbarDefaultService,
    public dialogRef: MatDialogRef<DialogRequirementsRegisterComponent>
  ) {
    this.type = this.data?.type;
  }

  dataSource = new MatTableDataSource();

  ngOnInit(): void {
    this.typeTable();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  typeTable() {
    if (this.type === 'Cargas') {
      this.title = 'Selecione o(s) tipo(s) de carga';
      this.displayedColumns.splice(1, 0, 'cargas');
      this.columnA = 'cargas';
      this.dataSource.data = carga_options;
      this.visibleQuantity = !this.visibleQuantity
    } else if (this.type === 'Veículos') {
      this.title = 'Selecione o(s) tipo(s) de veículo';
      this.displayedColumns.splice(1, 0, 'veiculos');
      this.columnA = 'veiculos';
      this.dataSource.data = veiculo_options;
    } else if (this.type === "UF's atendidas") {
      this.title = 'Selecione a(s) UF(s)';
      this.displayedColumns.splice(1, 0, 'ufs');
      this.columnA = 'ufs';
      this.dataSource.data = uf_options_requisitos;
      this.visibleQuantity = !this.visibleQuantity
    } else {
      this.title = 'Selecione a(s) modalidade(s) de frete';
      this.displayedColumns.splice(1, 0, 'modalidade');
      this.columnA = 'modalidade';
      this.dataSource.data = modalidade_frete_options;
      this.visibleQuantity = !this.visibleQuantity
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  addRegister() {
    if (this.type === 'Cargas') {
      this.saveLoads();
    } else if (this.type === 'Veículos') {
      this.saveVehicle();
    } else if (this.type === "UF's atendidas") {
      this.saveUF();
    } else {
      this.saveFreightMode();
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  saveData(
    serviceFunction: (obj: any) => Observable<any>,
    successMessage: string,
    errorMessage: string
  ) {

    let obj;

    if(this.type === 'Veículos'){
      obj = this.buildVehicle();
    } else {
      obj = {
        empresa: {
          id: this.data?.id,
          possuiVeiculosComRastreador: this.data?.possuiVeiculosComRastreador
        },
        lookup: this.selection.selected.map((item) => item.value)
      };
    }

    serviceFunction(Object.assign(obj))
      .pipe(first())
      .subscribe({
        next: () => {
          this._snackBar.openSnackBarSuccess(successMessage);
          this.dialogRef.close(true);
        },
        error: () => {
          this._snackBar.openSnackBarError(errorMessage);
        }
  });
  }

  saveLoads() {
    this.saveData(
      this.companiesService.saveLoad.bind(this.companiesService),
      'Carga adicionada',
      'Erro ao Adicionar Carga'
    );
  }
  saveUF() {
    this.saveData(
      this.companiesService.saveUF.bind(this.companiesService),
      'UF Adicionada',
      'Erro ao Adicionar UF'
    );
  }
  saveVehicle() {
    this.saveData(
      this.companiesService.saveVeiculo.bind(this.companiesService),
      'Veiculo Adicionado',
      'Erro ao Adicionar Veiculo'
    );
  }

  saveFreightMode() {
    this.saveData(
      this.companiesService.saveFreightMode.bind(this.companiesService),
      'Modalidade de frete Adicionada',
      'Erro ao Adicionar Modalidade de frete'
    );
  }

  public handlePageEvent(e: PageEvent) {
    if (this.size != e.pageSize) {
      this.page = 0;
    } else {
      this.page = e.pageIndex;
    }
    this.size = e.pageSize;
  }

  buildVehicle() {
    this.vehicles = this.selection.selected;

    for(const v of this.vehicles){
      (this.quantityForm.controls["veiculos"] as FormArray).push(this.formBuilder.group({
        veiculo: [v.value, Validators.required],
        quantidade:[0]
      }))
    }

    const obj = {
      empresa: {
        id: this.data?.id,
        possuiVeiculosComRastreador: this.data?.possuiVeiculosComRastreador
      },
      lookup: this.selection.selected.map((item) => item.value),
      veiculoList: this.quantityForm.value["veiculos"]
    };

    return obj;
  }
}
